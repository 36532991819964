import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    Frame, language, TableConfig, GenericFilterComponent, color,
    template, modal, DataTableComponent, notification, ConfirmDialogComponent, GenericFilter } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { Templates } from '@task-utils/utils';
import { Sectors } from './sectors';
import { CreateEditSectorDialog } from './create-edit-sector.dialog';
import { sectors_translations } from './sectors.trans';
import { User } from '@task-modules/users';

@Component({
    selector: 'task-sectors',
    template: `
        <div class="temp-add-button" *ngIf="can_create">
            <button (click)="add_sector()"
                class="filled pill"
                color="primary"
            >
                <i icon="add_circle"></i>
                <span>{{ 'SECTOR.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #sectorstable [config]="tableConfig"></knw-data-table>
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./sectors.component.scss']
})

export class SectorsComponent {
    @ViewChild('sectorstable') table: DataTableComponent<Task.Sector, DisplaySector, 'sectors', GenericFilterComponent>;

    can_create = false;

    constructor() {
        Frame.set({
            title: "NAVROUTE.SECTORS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(sectors_translations);

        User.currentTypeIs(
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SUPER_USER
        ).then(res=>{
            this.can_create = res;
        })
    }

    add_sector() {
        modal.open(CreateEditSectorDialog, (res) => {
            if (res) {
                this.table.getData();
            }
        })
    }

   async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }



    tableConfig: TableConfig<Task.Sector, DisplaySector, 'sectors', GenericFilterComponent> = {
        tableName: 'sectors',
        getData: async (filter, paginator, sort_header) => {

            let parsed_filter = { search: [], attributes: []};

            for (let key in filter) {
               if(key === 'company' && filter['company'] !== null) {
                  parsed_filter.attributes.push('#=:company_id')
                  parsed_filter.search.push(filter[key].company_id)
               } else {
                  parsed_filter.attributes.push(key);
                  parsed_filter.search.push(filter[key])
               }
            }


            let allSectors = await Sectors.getAll({page_no: 1, page_size: 10000});


            let data = allSectors.data;
            if ("company" in filter) {
                data = data.filter(sector => sector.company_id == filter.company.company_id);
            }

            return  {
                ...allSectors,
                data
            };
        },
        getHeader: (header) => {
            switch (header) {
                case 'name':
                    return { icon: "people", label: "SECTOR", sortable: false };

            }
        },
        showHeaderWhenEmpty: true,
        unwrapData: (sector) => {
            return {
                name: template.transform(`
                    <div class="flex-column">
                        <b>{{name}}</b>
                    </div>
                    `, { name: sector.name })
            }
        },

        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: ( sector ) => {
                modal.open(CreateEditSectorDialog, (res) => {
                    if (res) {
                        this.table.getData();
                    }
                }, sector)
            },
            priority: 1,
            isVisible: ()=>true
        }, {
            label: language.translate('DELETE'),
            icon: "delete",
            color: "warn",
            onClick: async ( sector ) => {
                modal.open(ConfirmDialogComponent, async (succ)=>{
                    if(succ) {
                        try {
                            await TaskRoutes.sectors.api_sector_delete(sector.sector_id);
                            this.table.getData();
                        } catch (err) {
                            if (err && err.internal_error_code === 4107) {
                                notification.show({
                                    title: language.translate("ERROR"),
                                    message: language.translate("SECTOR.ERROR.DELETE.EXISTING_USERS"),
                                    color: color.Variable.warn,
                                    icon: "error"
                                });
                            }
                        }
                    }
                },
                {
                    title: language.translate("SECTOR.DELETE.TITLE"),
                    message: language.translate("SECTOR.DELETE.MSG", sector.name),
                    icon: "people",
                    iconColor: color.variable(color.Variable.warn)
                })
            },
            priority: 2,
            isVisible: ()=>true
        }],
        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
            ]

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
               filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }

            return filters
            // @todo: Sectors are not currently searchable.
        }

    }
}

type DisplaySector = {
    name: template.EscapedHTML
}
