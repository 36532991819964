import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { popover, language, AutoSearchComponent, notification } from 'curvy';
import { components_trans } from './components.trans';


@Component({
    selector: 'task-search-locations',
    encapsulation: ViewEncapsulation.None,
    template: `
        <ng-container *ngIf="showLocationGroups">
            <knw-auto-search
                #locgrouppicker
                *ngIf="!loc_disable"
                [function]="search_location_groups"
                [to-label]="format_location_group_name"
                label="{{ 'NAVROUTE.LOCATION_GROUPS' | trans }}"
                [(ngModel)]="picked_location_group"
                [classes]="classes_first ||  'outlined'"
                [popover-width]="'auto'">
            </knw-auto-search>
        </ng-container>

        <knw-auto-search
            #locpicker
            [required]="required"
            [disabled]="loc_disable"
            [function]="search_locations"
            [to-label]="format_location_name"
            label="{{ 'LOCATION' | trans }}"
            [multi]="multi"
            [ngModel]="picked_locations"
            (ngModelChange)="locations.emit($event)"
            [classes]="classes_second || 'outlined'"
            [popover-width]="'auto'"
            (paste)="on_paste($event)">
        </knw-auto-search>
    `,
    styles: [`
        task-search-locations {
            display: contents;
        }
    `]
})
export class SearchLocationsByGroup implements OnInit {
    @ViewChild('locpicker', {read: ElementRef})
    locpicker: ElementRef<HTMLElement>;

    @ViewChild('locpicker')
    locpicker_search: AutoSearchComponent;

	picked_location_group: Task.LocationGroup = null;
	picked_locations: Task.Location | Task.Location[] = null;

    @Input('loc-group-disable')
    loc_group_disable = false;

    @Input('loc-disable')
    loc_disable = false;

    @Input()
    required = true;

    @Input()
    multi = false;

    @Input('classes-loc-group')
    classes_first:string;

    @Input('classes-loc')
    classes_second:string;

    @Input('locations')
    set in_locations(loc: Task.Location | Task.Location[]) {
        this.picked_locations = loc;
    }

    @Output('locationsChange')
	locations: EventEmitter<Task.Location | Task.Location[]> = new EventEmitter();

    @Input() showLocationGroups: boolean = true

    constructor() {
        language.load_translations(components_trans);
    }

    async load_cins(cins: string[]) {
        if (this.multi == false) return;
        if (!Array.isArray(this.picked_locations)) return;

        let res = await TaskRoutes.locations.api_location_search_responsible({
            search: [[...cins] as any],
            attributes: ["U:cin"]
        }, { page_no: 1, page_size: 10000 });

        let not_found = cins.filter(cin => {
            for (let loc of res.data) {
                if (loc.cin == cin) return false;
            }
            return true;
        });

        if (not_found.length > 0) {
            notification.show({
                title: language.translate('ADD_LOCATIONS.TITLE'),
                message: not_found.length < 5 ?
                    language.translate('ADD_LOCATIONS.MESSAGE_FEW', not_found) :
                    language.translate('ADD_LOCATIONS.MESSAGE_PLENTY', not_found),
                timeout: 0
            });
        }

        for (let loc of res.data) {
            let found = false;
            for (let picked of this.picked_locations) {
                if (picked.cin == loc.cin) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.picked_locations.push(loc);
            }
        }

        if (!popover.host) return;
        if (!popover.host.popoverOpen) return;
        if (!popover.host.currentPopover) return;
        if (!(popover.host.popoverPosition instanceof HTMLElement)) return;
        if (!this.locpicker.nativeElement.contains(popover.host.popoverPosition)) return;
        this.locpicker_search.open_menu();
    }

    on_paste = (e: ClipboardEvent) => {
        if (!this.multi) return;
        if (!e.clipboardData.types.includes("text/html")) return;
        if (!(e.currentTarget instanceof Element)) return;

        if (!this.locpicker.nativeElement.contains(e.currentTarget)) {
            if (!popover.host) return;
            if (!popover.host.popoverOpen) return;
            if (!popover.host.currentPopover) return;
            if (!(popover.host.popoverPosition instanceof HTMLElement)) return;
            if (!this.locpicker.nativeElement.contains(popover.host.popoverPosition)) return;
        }

        let html = e.clipboardData.getData("text/html");
        if (!html) return;

        let el = document.createElement("div");
        el.innerHTML = html;

        let rows = Array.from(el.querySelectorAll("tr"));
        if (!rows || rows.length == 0) return;

        let cins = new Set<string>();
        for (let row of rows) {
            let tds = row.querySelectorAll("td");
            if (tds == null) continue;
            if (tds.length != 1) continue;
            let text = tds[0].textContent.trim();
            if (text.length == 0) continue;
            cins.add(text);
        }
        if (cins.size == 0) return;

        this.load_cins([...cins]);
        e.stopPropagation();
        e.preventDefault();
        return false;
    };

    ngOnInit() {
        document.body.addEventListener("paste", this.on_paste);
    }

    ngOnDestroy() {
        document.body.removeEventListener("paste", this.on_paste);
    }

    search_locations = async(search: string) => {
        let res: TaskRoutes.Payload<Task.Location[]>;

		if (this.picked_location_group != null || this.picked_location_group != undefined) {
			let loc_group_id = this.picked_location_group.location_group_id;

			res = await TaskRoutes.locations.api_location_search_responsible(
				{ search: [search, `${loc_group_id}`], attributes: ["cin+ +name", "#=:loc_groups.location_group_id"] },
                { page_no: 1, page_size: 100 }
			);

		} else {
			res = await TaskRoutes.locations.api_location_search_responsible(
				{ search: [search], attributes: [`cin+ +name+`] },
                { page_no: 1, page_size: 100 }
			);
		}

        return res.data;
	}

    format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

    async search_location_groups(search: string) {
		let res = await TaskRoutes.location_groups.api_location_groups_search_responsible(
			{ search: [search], attributes: ['name'] },
			{ page_no: 1, page_size: 50 },
			true
		)

		return res.data;
	}

	format_location_group_name(loc: Task.LocationGroup) {
		if (!loc) { return ""; }
		return `${loc.name}`
	}


}





