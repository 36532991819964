<div class="flex-column fullheight-noscroll">
	<h2 class="modal-title">
		{{ 'ORDER.SCHEDULED.EDIT' | trans }}
	</h2>

	<p style="font-size: 95%; text-align: end; color: rgb(var(--warn));"><span style="animation: blink 1s infinite forwards;">*</span>&nbsp; {{"REQUIRED_FILEDS" | trans }}</p>

	<div style="overflow: auto">
		<div class="field-grid" >
			<label class="input outlined" title="{{ 'ORDER.SCHEDULED_TITLE' | trans }}">
				<i icon="ballot"></i>
				<input [(ngModel)]="order.title" placeholder="{{ 'ORDER.SCHEDULED_TITLE' | trans }}"
				conditions="nonempty" />
			</label>

			<label class="textarea grid-el-two-thirds" title="{{ 'DESCRIPTION' | trans }}" *ngIf="descriptions.length">
				<textarea rows="2"
					placeholder="{{ 'DESCRIPTION' | trans }}"
					[(ngModel)]="descriptions[0].description"
					(ngModelChange)="update_details()">
				</textarea>
			</label>

			<task-search-locations
				[classes-loc-group]="'outlined special-box hidden'"
				[classes-loc]="'outlined special-box grid-el-two-thirds'"
				[showLocationGroups]="false"
				[(locations)]="order.location"
				(locationsChange)="handleLocations($event)"
			></task-search-locations>

			<div class="special-box">
				<task-inventory-picker
					[disabled]="!order.location"
					[(ngModel)]="inventory"
					[location_cin]="order.location.cin"
					(ngModelChange)="update_details()"
				></task-inventory-picker>
			</div>

			<knw-date-picker-input
				[(ngModel)]="order.planned_start_date"
				[required]="true"
				[label]="'ORDER.SCHEDULED.PLANNED_DATE_START' | trans"
				[classes]="'flex-dynamic outlined'">
			</knw-date-picker-input>

			<knw-date-picker-input
				[(ngModel)]="order.planned_end_date"
				[required]="true"
				[label]="'ORDER.SCHEDULED.PLANNED_DATE_END' | trans"
				[classes]="'flex-dynamic outlined'"
				[min-date]="order.planned_end_date"
				[max-date]="max_date_allowed">
			</knw-date-picker-input>
		</div>
	</div>

</div>
