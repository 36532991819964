import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { language, notification, color, date } from 'curvy';
import { reports_translations } from './reports.trans';
import { User } from '@task-modules/users';

@Component({
	selector: 'task-report-financial',
	template: `
		<h2 class="">{{ 'REPORTS.FINANCIAL_TITLE' | trans }}</h2>
		<div class="flex-row wrappable">
			<knw-date-picker-input
				[(ngModel)]="report.dateFrom"
				[label]="'STARTDATE' | trans"
				[classes]="'filled flex-dynamic'">
			</knw-date-picker-input>

			<knw-date-picker-input
				[(ngModel)]="report.dateTo"
				[label]="'ENDDATE' | trans"
				[classes]="'filled flex-dynamic'">
			</knw-date-picker-input>

			<knw-auto-search
					[function]="search_locations"
					[to-label]="format_location_name"
					[(ngModel)]="report.picked_locations"
					[multi]="true"
					[popover-width]="'auto'"
					label="{{ 'LOCATION' | trans }}"
					[allow-null]="true"
					classes="filled flex-dynamic">
			</knw-auto-search>
			<knw-auto-search
					[function]="search_suppliers"
					[to-label]="format_supplier_name"
					[(ngModel)]="report.picked_supplier"
					label="{{ 'REPORTS.SUPPLIER' | trans }}"
					[allow-null]="true"
					classes="filled flex-dynamic">
			</knw-auto-search>
			<knw-auto-search
					[function]="search_users"
					[to-label]="format_user_name"
					[(ngModel)]="report.picked_user"
					label="{{ 'REPORTS.RESPONSIBLE_USER' | trans }}"
					[allow-null]="true"
					classes="filled flex-dynamic">
			</knw-auto-search>

			<label *ngIf="is_admin" class="input filled" [title]="'COMPANY' | trans" style="min-width: 7em; max-width: 7em">
				<select [(ngModel)]="report.picked_company">
					<option value="" selected>Svejedno</option>
					<option *ngFor="let company of companys; let i = index" [ngValue]="company.value">
						{{ company.label }}
					</option>
				</select>
			</label>
		</div>
		<div class="report-btn-wrapper">
			<button class="filled pill report-btn"
				(click)="get_financial_report()"
				*ngIf="!loading; else isLoading">
				<i icon="download"></i>
				<span>{{ 'REPORTS.DOWNLOAD' | trans }}</span>
			</button>

			<ng-template #isLoading>
				<knw-spinner color="primary" size="50"></knw-spinner>
			</ng-template>

		</div>
		`,
	styleUrls: ['./reports.component.scss'],
	encapsulation: ViewEncapsulation.None,

})

export class ReportFinancialComponent implements OnInit {
	report = {
		dateFrom: null as Date,
		dateTo: null as Date,
		picked_locations: null as Task.Location[],
		picked_supplier: null as Task.Supplier,
		picked_user: null as Task.User,
		picked_company: "" as string
	};
	companys: {label: string; value: number}[] = [
		{ label: "HR", value: 2 },
		{ label: "BIH", value: 141 }
	];
	is_admin: boolean = false;
	loading = false;

	ngOnInit() {
		language.load_translations(reports_translations);
		this.update_values()
	}

	update_values = async () => {
		this.is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN);
	}

	async search_locations(search: string) {
		let res = await TaskRoutes.locations.api_location_search_visible({ search: [search], attributes: ["cin+ +name"] });
		return res.data;
	}

	async search_suppliers(search: string) {
		let res = await TaskRoutes.suppliers.api_supplier_search({ search: [search], attributes: ["company.name"] }, {page_no: 1, page_size: 15});
		return res.data;
	}

	async search_users(search: string) {
		let res = await TaskRoutes.users.api_user_search({ search: [search], attributes: ["first_name+ +last_name+ +email"] });
		return res.data;
	}

	format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

	format_supplier_name(sup: Task.Supplier) {
		if (!sup) { return ""; }
		return `${sup.name}`;
	}

	format_user_name(usr: Task.User) {
		if (!usr) { return ""; }
		return `${usr.first_name} ${usr.last_name}` ;
	}

	getData() {
		let parsed_filter = { search: [], attributes: []};

		if (this.report.picked_locations) {
			parsed_filter.search.push(this.report.picked_locations.map(loc=>loc.location_id))
			parsed_filter.attributes.push("U:location_id")

		}

		if (this.report.picked_supplier) {
			parsed_filter.search.push(this.report.picked_supplier.supplier_id)
			parsed_filter.attributes.push("#=:supplier_id")
		}

		if (this.report.picked_user) {
			parsed_filter.search.push(this.report.picked_user.user_id)
			parsed_filter.attributes.push("#=:created_by_id")
		}

		if (this.report.dateFrom) {
			this.report.dateFrom = date.start_of_day(this.report.dateFrom);
			parsed_filter.search.push(this.report.dateFrom.toISOString())
			parsed_filter.attributes.push("-d:created_date");
		}

		if (this.report.dateTo) {
			this.report.dateTo = date.end_of_day(this.report.dateTo);
			parsed_filter.search.push(this.report.dateTo.toISOString())
			parsed_filter.attributes.push("+d:created_date");
		}

		if(this.report.picked_company) {
			parsed_filter.search.push(this.report.picked_company)
			parsed_filter.attributes.push('#=:company_id')
		}

		return parsed_filter;
	}

	async get_financial_report() {
		try {
			this.loading = true;
			await TaskRoutes.orders.api_order_report_get(this.getData(), true);
		} catch {
			notification.show({
				icon: "report_problem",
				color: color.Variable.warn,
				title: language.translate("REPORTS.NO_DATA_TITLE"),
				message: language.translate("REPORTS.NO_DATA_DESCR")
			});
		}
		this.loading = false;
	}

}
