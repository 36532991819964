import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { language, popover, color, ModalBase } from 'curvy';
import { date_time_translations } from './date-time-picker.trans';


@Component({
    selector: 'task-date-picker-dailog',
    template: `
		<div class="flex-column popover-date-time"
			[knwStyle]="{
				'--highlight': hl.color,
				'--highlight-text': hl.color_text,
				'--highlight-dark': hl.color_dark,
				'--highlight-dark-text': hl.color_dark_text,
				'--highlight-light': hl.color_light,
				'--highlight-light-text': hl.color_light_text
			}">
			<h3 class="date-time-header">{{ (injectedData.title || 'ENTER_DATE') | trans }}</h3>
			<task-date-picker #picker [(in_date)]="date" (submit_date)="onsubmit()" [min]="injectedData.min" [max]="injectedData.max" ></task-date-picker>
		</div>

		<div class="date-time-footer flex-row justify-space-between">
			<button (click)="clear_date()" style="margin-left: auto; font-size: 90%;">
				<i icon="clear"></i>
				{{'CLEAR' | trans}}
			</button>
		</div>
		`,
    styleUrls: ['./date-time-picker.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class DatePickerDialog extends ModalBase<{
	date: Date,
	highlight?: string,
	title?: string,
	min?: Date|string,
	max?: Date|string
}, Date> implements OnInit {

	date:Date;
	save: boolean = false;

	onsubmit() {
		let dateToSubmit = this.date ?? null; // NOT SURE IF THIS SHOULD HAPPEN HERE
		this.submit(dateToSubmit);
	}

	show_save(val: boolean) {
		this.save = val;
	}

	clear_date() {
		this.submit(null);
	}

	closePopover() {
		popover.host.close();
	}

	hl = color.make_color_variants("primary");

	ngOnInit() {
		language.load_translations(date_time_translations);
		this.date = this.injectedData.date;
		if (this.injectedData.highlight !== undefined)
			this.hl = color.make_color_variants(this.injectedData.highlight);
	}
}


