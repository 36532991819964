import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    Frame, language, TableConfig, GenericFilterComponent, network,
    template, GenericFilter, noOp, modal, DataTableComponent } from 'curvy';
import { Task } from '@task-utils/types';
import { Templates } from '@task-utils/utils';
import { CreateEditWorkDescriptionDialog } from './create-edit-work-description.dialog';
import { work_groups_translations } from './work-descriptions.trans';
import { TaskRoutes } from '@task-utils/routes';
import { User } from '@task-modules/users';

@Component({
    selector: 'task-work-descriptions',
    template: `
        <div class="temp-add-button" *ngIf="can_add">
            <button (click)="add_work_desc()"
                class="filled pill"
                color="primary"
            >
                <i icon="add_circle"></i>
                <span>{{ 'WORKGROUP.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #workgroupstable [config]="tableConfig"></knw-data-table>

    `,
    styleUrls: ['./work-descriptions.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class WorkDescriptionsComponent implements OnInit {
    @ViewChild('workgroupstable')
    table: DataTableComponent<Task.WorkDescription, DisplayWorkDescription, 'work-groups', GenericFilterComponent>;

    constructor() {
        Frame.set({
            title: "NAVROUTE.WORKGROUPS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(work_groups_translations);
    }

    can_add = false;

    add_work_desc() {
        modal.open(CreateEditWorkDescriptionDialog, (res) => {
            if (res) {
                this.table.getData();
            }
        })
    }

    ngOnInit(): void {
        this.init();
    }

    async init() {
        this.can_add = await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)
    }

    tableConfig: TableConfig<Task.WorkDescription, DisplayWorkDescription, 'work-groups', GenericFilterComponent> = {
        tableName: 'workgroups',
        defaultPageSize: 100,
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = {search: [], attributes: [], sort: null};
            for (let key in filter) {
                parsed_filter.attributes.push(key);
                parsed_filter.search.push(filter[key])
            }
            if (sort_header) {
                sort_header.label = "work_description"
            }

            parsed_filter.sort = sort_header;

            let res = TaskRoutes.work_descriptions.api_work_descriptions_search(parsed_filter, paginator);
            return res;
        },
        getHeader: (header) => {
            switch (header) {
                case 'workgroup':
                    return { icon: "group_work", label: "WORKGROUP.WORKGROUP", sortable: true };
            }
        },
        showHeaderWhenEmpty: true,
        unwrapData: (wd) => {
            return {
                workgroup: template.transform(`
                    <div class="flex-column" style="padding-bottom: .5em; padding-top: .5em;">
                        <b>{{name}}</b>

                    </div>
                    `, { name: wd.work_description }),
            }
        },

        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (wd) => {
                modal.open(CreateEditWorkDescriptionDialog, (res) => {
                    if (res) {
                        this.table.getData();
                    }
                }, wd)
            },
            priority: 1,
            isVisible: async ()=> await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)
        }],
        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("WORKGROUP.WORKGROUP", "work_description"),
            ]

            return filters
        }
    }
}

type DisplayWorkDescription = {
    workgroup: template.EscapedHTML,
}
