import { language } from 'curvy';

export const english: language.TranslationDefinition = {
	locale: 'en-us',
	translations: {
		"DAY": "Day",
		"MONTH": "Month",
		"YEAR": "Year",
		"HOUR": "Hour",
		"MINUTE": "Minute",
		"ENTER_TIME": "Enter time",
		"ENTER_DATE": "Enter date",
		"EDIT_DATE": "Edit date"
	}
};

export const croatian: language.TranslationDefinition = {
	locale: 'hr-hr',
	translations: {

		"DAY": "Dan",
		"MONTH": "Mjesec",
		"YEAR": "Godina",
		"HOUR": "Sati",
		"MINUTE": "Minute",
		"ENTER_TIME": "Unesite vrijeme",
		"ENTER_DATE": "Unesite datum",
		"EDIT_DATE": "Izmjna datuma",
	}
};

export const date_time_translations = [english, croatian];