import { Component, Input } from '@angular/core';

import { Task } from '@task-utils/types';

import { User} from '@task-modules/users';

@Component({
	selector: 'task-whier-node',
	styles: [`
		task-whier-node {
			display: flex;
			position: relative;
			flex-flow: column nowrap;
			justify-content: flex-start;
			width: 100%;
			box-sizing: border-box;
			overflow: hidden;
			padding: 0 0 0 16px;
			cursor: pointer;
			transition: color 0.1s, background 0.1s;
		}

		task-whier-node > div > div > button {
			opacity: 0;
		}

		task-whier-node:hover > div > div > button {
			opacity: 1;
		}

		task-whier-node::after {
			content: "";
			position: absolute;
			left: 8px;
			height: 100%;
			width: 2px;
			background: currentColor;
			transition: background 0.1s;
		}

		task-whier-node:hover {
			background: rgb(var(--background-light), 0.66);
			color: rgb(var(--background-light-text));
		}

		task-whier-node button {
			align-self: center;
		}
	`],
	template: `
		<ng-container *ngIf="node.data">
			<div class="flex-column"
					color="secondary">
				<div class="flex-row"
						style="
							margin: 0;
							padding: 8px 4px;
							width: 100%"
						(click)="
							expanded=!expanded;
							$event.stopPropagation()">
					<i *ngIf="node.children.length > 0"
							[attr.icon]="expanded ? 'arrow_drop_down' : 'arrow_right'"></i>
					<i *ngIf="node.children.length == 0"
							[attr.icon]="'stop'"></i>
					<b>{{ node.data.name }}</b>&nbsp;{{ node.data.description }}
					<div class="flex-dynamic"></div>
					<button class="filled round hover-visible"
							color="secondary"
							style="margin-right: 1em"
							[goto]="'/work-hierarchy/' + node.data.whier_id + '/edit-questionnaires'">
						<i icon="quiz"></i>
					</button>
				</div>
				<ng-container *ngIf="expanded">
					<task-whier-node
							*ngFor="let child of node.children"
							[node]="child"></task-whier-node>
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="!node.data">
			<div class="flex-row justify-center" style="padding: 1.7em 0.5em 1em;" >
				<button class="filled pill" color="primary" [goto]="'/work-hierarchy/0/edit-questionnaires'" *ngIf="can_add">
					<i icon="quiz"></i> &nbsp;
					<span>{{ 'WHIER.EDIT_BASE_QUESTIONNAIRE' | trans }}</span>
				</button>
			</div>

			<div class="flex-column" style="overflow: auto">
				<ng-container *ngFor="let child of node.children">
					<task-whier-node [node]="child"></task-whier-node>
				</ng-container>
			</div>
		</ng-container>
	`
})
export class WhierNodeComponent {
	@Input()
	node: Node<Task.WorkHierarchy>;
	expanded: boolean = false;

	can_add = false;

	constructor() {
		User.currentTypeIs(
			Task.USER_TYPE.ADMIN,
			Task.USER_TYPE.SYSTEM_ADMIN
		).then(res=>{
			this.can_add = res;
		})
	}
}

interface Node<T> {
	data: T;
	children: Node<T>[];
}
