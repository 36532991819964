import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { User } from '@task-modules/users';
import { TaskRoutes } from '@task-utils/routes';
import { Task } from '@task-utils/types';
import { Frame, language, color, modal, DataTableComponent, GenericFilterComponent, GenericFilter, template, notification, ConfirmDialogComponent } from 'curvy';
import { CreateEditLocationGroupDialog } from './create-edit-location-group.dialog';
import { location_groups_translations } from './location-groups.trans';



type TableType = DataTableComponent<Task.LocationGroup, DisplayLocGroups,  'location_groups', GenericFilterComponent>;


@Component({
    selector: 'task-location-groups',
    template: `
        <div class="temp-add-button" *ngIf="can_add_new">
            <button (click)="add_group()"
                class="filled pill"
                color="primary"
            >
                <i icon="add_circle"></i>
                <span>{{ 'LOCGROUP.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #locGroupsTable [config]="tableConfig"></knw-data-table>
    `,
    styleUrls: ['./location-groups.component.scss'],
    encapsulation: ViewEncapsulation.None,

})

export class LocationGroupsComponent implements OnInit {
    @ViewChild('locGroupsTable')
    table: TableType;

    can_add_new = false;

    constructor() {
        Frame.set({
            title: "NAVROUTE.LOCATION_GROUPS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(location_groups_translations);
    }

    add_group() {
        modal.open(CreateEditLocationGroupDialog, (res)=> {
            if (res) {
                this.table.getData();
            }
        }, {
            location_group_id: null,
            locations: [],
            location_group_users: [],
            name: '',
        } as Task.LocationGroup)
    }

    ngOnInit(): void {
        this.init()
    }

    async init() {
        this.can_add_new = await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.SUPPLIER_ADMIN,
            Task.USER_TYPE.SUPER_USER
        )
    }

    async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

    tableConfig:TableType["tableConfig"] = {
        tableName: 'location_groups',
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = { search: [], attributes: [], sort: null};

            for (let key in filter) {
                if(key === 'company' && filter['company'] !== null) {
                    parsed_filter.attributes.push('#=:company_id')
                    parsed_filter.search.push(filter[key].company_id)
                }
                else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key]);
                }
            }

            if(sort_header) {
                switch (sort_header.label) {
                    case 'title':
                    sort_header.label = 'name';

                    case 'location':
                    sort_header.label = '#:locations.count'
                }
                parsed_filter.sort = sort_header;
            }


            return TaskRoutes.location_groups.api_location_groups_search_visible(parsed_filter, paginator, false)
        },

        getHeader: (header)=> {
            switch (header) {
                case 'title':
                return {icon: null, label: 'LOCGROUP.TITLE', sortable: true}

                case 'location':
                return {icon: 'place', label: 'LOCGROUP.NO_OF_LOCATIONS', sortable: true}

                case 'nfc':
                return {icon : 'contactless', label: 'LOCGROUP.GROUP_NFC', sortable: false}

            }
        },

        unwrapData: (group)=>{
            let nfc;
            if (group.nfc) {
                nfc = template.prebuilt.icon("check")
            } else {
                nfc = template.prebuilt.icon("");
            }

            return {
                title: template.transform(`
                    <div class="flex-row align-center justify-center" style="width: 100%; padding-top: 0.5em;padding-bottom: .5em;">
                        <strong>${group.name}</strong>
                    </div>
                `),
                location: template.transform(`
                    <div class="flex-row align-center">
                        <span>{{locations_count}}</span>
                    </div>
                `, {
                    locations_count: group.locations_count || '-'
                }),

                nfc: nfc
            }
        },

        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
            label: language.translate('EDIT'),
            icon: 'edit',
            onClick: async (group)=>{
                let single_group = await TaskRoutes.location_groups.api_location_group_get_single(group.location_group_id);

                modal.open(CreateEditLocationGroupDialog, ()=>{
                    this.table.getData();
                }, single_group.data)
            },
            priority: 1
        }, {
            label: language.translate('LOCGROUPS.NFC_ON'),
            icon: 'contactless',
            onClick: async (group)=>{
                try {
                    await TaskRoutes.location_groups.api_location_group_has_nfc(group.location_group_id, {nfc: true})
                    notification.show({
                        icon: "check",
                        color: color.Variable.secondary,
                        title: language.translate('LOCGROUPS.NFC_ON_TITLE'),
                        message: ''
                    })

                } catch(error) {
                    console.error(error)
                }
            },
            priority: 2
        }, {
            label: language.translate('LOCGROUPS.NFC_OFF'),
            icon: 'clear',
            onClick: async (group)=>{
                try {
                    await TaskRoutes.location_groups.api_location_group_has_nfc(group.location_group_id, {nfc: false})
                    notification.show({
                        icon: "check",
                        color: color.Variable.secondary,
                        title: language.translate('LOCGROUPS.NFC_OFF_TITLE'),
                        message: ''
                    })
                } catch(error) {
                    console.error(error);
                }
            },
            priority: 3
        },{
            label: language.translate('DELETE'),
            icon: 'delete',
            color: 'warn',
            onClick: async (group)=>{
                modal.open(ConfirmDialogComponent, async(succ)=> {
                    if (succ) {
                        await TaskRoutes.location_groups.api_location_group_delete(group.location_group_id);
                        this.table.getData();
                    }
                }, {
                    title: language.translate("LOCGROUP.DELETE.TITLE"),
                    message: language.translate("LOCGROUP.DELETE.MSG", group.name),
                    icon: "holiday_village",
                    iconColor: color.variable(color.Variable.warn)
                })
            },
            priority: 4
        }],

        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text('LOCGROUP.TITLE', "name"),
                GenericFilter.toggle("LOCGROUP.GROUP_NFC", "nfc"),
            ]

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
                filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

            }

            return filters
        }
    }
}

interface DisplayLocGroups {
    title: template.EscapedHTML,
    location: template.EscapedHTML,
    nfc: template.EscapedHTML
}
