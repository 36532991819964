import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation, OnChanges } from '@angular/core';
import { ConfirmDialogComponent, Frame, PopoverMenuComponent, color, language, modal, noOp, notification, popover, progress, utils } from 'curvy';
import { Task } from '@task-utils/types';
import { ActivatedRoute } from '@angular/router';
import { Ticket, tickets_translations } from '@task-modules/tickets';
import { CreateOrderDialog, Order, orders_translations } from '@task-modules/orders';
import { Structs, files } from '@task-utils/utils';
import { User } from '@task-modules/users/users';
import { TaskRoutes } from '@task-utils/routes';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';
import { WhierPickerDialog } from '@task-components/whier-picker-dialog.component';

import UT = Task.USER_TYPE;
import STATUS = Task.STATUS;


function is_responsible(user: Task.User, ticket: Task.Ticket) {
    return ticket.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

type FileType = (files.IFile | Task.DMSFile);

function is_dms_file(file: FileType): file is Task.DMSFile {
    return file.hasOwnProperty('dms_document_id');
}

@Component({
    selector: 'task-ticket-single',
    templateUrl: './ticket-single.component.html',
    styleUrls: ['./ticket-single.component.scss'],
    encapsulation: ViewEncapsulation.None,
})


export class TicketSingleComponent implements OnInit, OnChanges {
   @Input()
    ticket: Task.Ticket;

    icon: string;
    color: string;
    status_name: string;

    priority_icon: string;
    priority_color: string;
    priority_text: string;

    edited = false;
    data_before_edit: Task.Ticket = null;

    orders: Task.Order[] = [];

    entity = Task.ENTITY.TICKET;
    needs_confirmation = false;

    can_take = false;
    can_delete = false;
    can_approve = false;
    can_create_order = false;
    can_pause = false;
    can_reject = false;
    can_edit = false;
    can_request_refinement = false;
    can_see_ticket_history = false;
    can_revert_executed = false;
    can_edit_admin = false;
    can_change_whier = false;


    is_paused = false;
    is_rejected = false;
    needs_refinement = false;

    ICON_TICKET_HISTORY = Structs.icon("ballot", Task.STATUS_COLORS.GREEN);
    ICON_EXECUTED = Task.get_status_icon(Task.STATUS.EXECUTED);
    ICON_CHANGE_WHIER = Structs.icon("account_tree", Task.STATUS_COLORS.YELLOW);

    can_see_admin_actions() {

        return (
            this.can_revert_executed ||
            this.can_see_ticket_history ||
            this.can_edit_admin
        );

    }

    open_order = Order.open_order;

    image_questions: Record<string, files.IImage[]> = {};
    file_questions: Record<string, (files.IFile | Task.DMSFile)[]> = {};
    additional_inventory: Task.KAM_Inventory[] = [];

    get_ticket_status = Structs.get_ticket_status;

    constructor(private ar: ActivatedRoute) {
		language.load_translations(tickets_translations);
        language.load_translations(orders_translations);
	}

    ngOnInit(): void {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        let ticket_changed = !!changes["ticket"];

        if (ticket_changed) {
            this.update_values(true);
        }
    }


    ticket_icon(ticket: Task.Ticket) {
        if (ticket.on_refinement) {
            return Structs.icon("restore_page", "#f55");
        }

        return Task.StatusIcons[ticket.status_id];
    }

    async initialize() {
       if (this.ticket == null) {
            const ticket_id = this.ar.snapshot.paramMap.get("id");

            Frame.set({
                title: language.translate("NAVROUTE.TICKET", ticket_id),
                visible: true,
                layout: "top-middle",
                size: "scroll"
            });

            try{
                const res = await Ticket.get(+ticket_id)
                this.ticket = res
                this.update_values(true);
            } catch(error) {
                console.error(error.message)
                utils.router.navigateByUrl('/tickets');
            }
        } else {
            this.update_values(true);
        }
	}

    async update_values(get_orders = false) {
        this.data_before_edit = JSON.parse(JSON.stringify(this.ticket));

        this.additional_inventory = [];

        let is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN);
        let user_id = (await User.currentUserPromise).user_id;
        let user = await User.currentUserPromise;

        let icon = this.ticket_icon(this.ticket);
        this.icon = icon.icon;
        this.color = icon.color;
        this.status_name = language.translate('STATUS.'+ Task.Status[this.ticket.status_id])

        icon = Task.get_priority_icon(this.ticket.priority_id);
        this.priority_icon = icon.icon;
        this.priority_color = icon.color;
        this.priority_text = language.translate( 'PRIORITY.' + Task.Priority[this.ticket.priority_id] );

        if (is_admin ) {
            this.can_edit_admin = true;
        }

        this.can_revert_executed = this.ticket.status_id === STATUS.APPROVED && is_admin;

        this.needs_confirmation = this.ticket.status_id === STATUS.CREATED;

        this.can_approve = this.needs_confirmation && await User.currentTypeIs(
            UT.REGIONAL_RETAIL_MANAGER);

        this.can_edit = this.ticket.on_refinement && this.ticket.created_by_id === user_id;

        let are_responsible_managers = await User.currentTypeIs(UT.REGIONAL_MAINTENANCE_MANAGER, UT.SAFETY_MANAGER) && is_responsible(user, this.ticket);

        let reject_status_created = this.ticket.status_id === STATUS.CREATED && (await User.currentTypeIs(UT.REGIONAL_RETAIL_MANAGER) || are_responsible_managers)

        let reject_status_approved = this.ticket.status_id === STATUS.APPROVED && are_responsible_managers;

        this.can_reject = ( reject_status_created || reject_status_approved );

        this.can_take = (this.ticket.status_id == STATUS.APPROVED || this.ticket.status_id == STATUS.CREATED) &&
            this.ticket.assigned_user_id != user_id &&
            await User.currentTypeIs(UT.REGIONAL_MAINTENANCE_MANAGER, UT.SAFETY_MANAGER);

        this.can_create_order = are_responsible_managers;

        this.can_delete = (this.ticket.created_by_id === user_id || await User.currentTypeIs(UT.ADMIN, UT.SYSTEM_ADMIN)) &&
            this.ticket.active_orders_count === 0;

        this.is_paused = this.ticket.status_id === STATUS.ON_HOLD;
        this.is_rejected = this.ticket.status_id === STATUS.REJECTED;
        this.needs_refinement = this.ticket.on_refinement;

        this.can_pause = (
            this.ticket.status_id === STATUS.APPROVED ||
            this.ticket.status_id === STATUS.ON_HOLD
        ) && are_responsible_managers

        this.can_request_refinement = (
            this.ticket.status_id === STATUS.CREATED || this.ticket.status_id === STATUS.APPROVED
        ) && are_responsible_managers

        if (get_orders) {
            this.orders = await Ticket.get_orders(this.ticket.ticket_id);
            if (this.orders.length > 0) {
                this.can_take = false;
            }
        }


        this.can_see_ticket_history = await User.currentTypeIs(
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER
        )

        this.can_change_whier = (
            are_responsible_managers ||
            await User.currentTypeIs(UT.ADMIN)
        ) && (
            this.ticket.status_id === STATUS.CREATED ||
            this.ticket.status_id === STATUS.APPROVED
        );

        if (await User.currentTypeIs(UT.ADMIN, UT.SYSTEM_ADMIN)) {
            this.can_approve = this.needs_confirmation;
            this.can_reject = this.needs_confirmation;
            this.can_take = this.ticket.status_id === STATUS.APPROVED;
            this.can_create_order = true;
            this.can_edit = true;
            this.can_pause = this.ticket.status_id === STATUS.APPROVED || this.ticket.status_id === STATUS.ON_HOLD;
            this.can_request_refinement = this.ticket.status_id === STATUS.CREATED || this.ticket.status_id === STATUS.APPROVED;

            this.can_change_whier = (
                this.ticket.status_id === STATUS.CREATED ||
                this.ticket.status_id === STATUS.APPROVED
            );
        }

        let image_requests: Promise<files.IImage>[] = [];
        this.file_questions = {};
        let count = 0;

        for (let detail of this.ticket.ticket_details) {
            if (detail?.inventory_data?.udas) {
                detail.inventory_data.udas.sort((u1, u2) => {
                    return u1.uda_name.localeCompare(u2.uda_name);
                });
            }

            if (detail.component_type_id === Task.COMPONENT_TYPE.IMAGE) {
                let req = files.url_to_iimage(detail.answer).then(res => {
                    detail["_iimage"] = res;
                    return res;
                });
                image_requests.push(req);
            }

            if (detail.component_type_id === Task.COMPONENT_TYPE.FINANCIAL_DOCUMENT || detail.component_type_id === Task.COMPONENT_TYPE.SERVICE_AND_SUPPORT_DOCUMENT) {
                if (!this.file_questions[detail.question]) {
                    this.file_questions[detail.question] = [];
                }

                this.file_questions[detail.question].push(detail);
                count++;

            }
        }

         if (count == 0) {
            this.file_questions["-"] = [];
        }


        Promise.all(image_requests).then(() => {
            this.image_questions = {};
            let count = 0;
            for (let detail of this.ticket.ticket_details) {
                if (detail.component_type_id === Task.COMPONENT_TYPE.IMAGE) {
                    if (!this.image_questions[detail.question]) {
                        this.image_questions[detail.question] = [];
                    }
                    this.image_questions[detail.question].push(detail["_iimage"]);
                    count++;
                }
            }

            if (count == 0) {
                this.image_questions["-"] = [];
            }
        });

    }

    async refetch_tickets(prom?: Promise<TaskRoutes.Payload<Task.Ticket>>) {
        if (!prom) {
            prom = TaskRoutes.tickets.api_ticket_get_single(this.ticket.ticket_id, true);
        }
        this.ticket = (await prom).data;
        this.update_values();
    }

    async upload_file(ticket_id: number, question:string|number, file: FileType) {
        if (!is_dms_file(file)) {
            try {
                let uploaded = await TaskRoutes.tickets.api_upload_document(ticket_id, question, file.file, true)
                notification.show({
                    icon: "check",
                    color: color.Variable.secondary,
                    title: language.translate('SUCCESS'),
                    message: language.translate('DOCUMENT.UPLOADED_TEXT')
                });
                return uploaded.data;
            } catch(error) {
                let msg;
                if (error.internal_error_code == 4107) {
                    msg = language.translate('DOCUMENT.UNSAFE_FAIL_TEXT');
                } else {
                    msg = language.translate('DOCUMENT.UPLOADED_FAIL_TEXT');
                }

                notification.show({
                    icon: 'error',
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: msg
                });

                return new Error('Failed to upload file.');
            }
        }
        return null;
    }

    async approve_ticket() {
        let updated_ticket = await TaskRoutes.tickets.api_ticket_approve(this.ticket.ticket_id, true);
        for (let key in updated_ticket.data) {
            this.ticket[key] = updated_ticket.data[key];
        }
        this.update_values();
    }

    async reject_ticket() {
        modal.open(TextBoxDialog, async (text) => {
            if (text) {
                let updated_ticket = await TaskRoutes.tickets.api_ticket_reject(
                    this.ticket.ticket_id, {comment: text}, true);
                for (let key in updated_ticket.data) {
                    this.ticket[key] = updated_ticket.data[key];
                }
                this.update_values();
            }
        }, {
            title: "TICKET.REJECT.TITLE",
            label: "TICKET.REJECT.REASON"
        });
    }

    async request_refinement() {
        modal.open(TextBoxDialog, async (text) => {
            if (text) {
                let updated_ticket = await TaskRoutes.tickets.api_ticket_set_on_refinement(
                    this.ticket.ticket_id, {comment: text}, true);
                for (let key in updated_ticket.data) {
                    this.ticket[key] = updated_ticket.data[key];
                }
                this.update_values();
            }
        }, {
            title: "TICKET.REFINE.TITLE",
            label: "TICKET.REFINE.REASON"
        });
    }

    async delete_ticket() {
        modal.open(ConfirmDialogComponent, async ( is_confirm: boolean ) => {
            if ( is_confirm ) {
                let delete_ticket = await TaskRoutes.tickets.api_ticket_delete(this.ticket.ticket_id);
                this.update_values();
                utils.router.navigateByUrl('tickets');
            }
        }, {
            title: language.translate("TICKET.DELETE.TITLE"),
            icon: "receipt",
            iconColor: color.variable(color.Variable.warn),
            message: language.translate( "TICKET.DELETE.CONTENT", this.ticket.ticket_id, this.ticket.title )
        });
    }

    async download_file(file: FileType) {
        if (is_dms_file(file)) {
            await TaskRoutes.tickets.api_get_document_by_ticket_detail_id(file.ticket_detail_id, true);
        }
    }

    async upload_image(img: files.IImage) {
        if (!img.is_uploaded) {
            let res = await TaskRoutes.upload.api_upload_file(img.file);
            img.src = TaskRoutes.upload.uploaded_file_get(res.data);
            img.is_uploaded = true;
        }
        return img;
    }

    create_image_detail(question: string, answer: files.IImage) {
        let detail: Partial<Task.TicketDetail> = {
            component_type_id: Task.COMPONENT_TYPE.IMAGE,
            question: question,
            answer: answer.src,
        };

        return detail as Task.TicketDetail;
    }

    create_inventory_detail(answer: Task.KAM_Inventory) {
        let empty = true;

        for (let key in answer) {
            let val = answer[key]
            if (val !== undefined && val !== null && val !== "") {
                empty = false;
                break;
            }
        }

        if (empty) {
            return null;
        }

        let detail: Partial<Task.TicketDetail> = {
            component_type_id: Task.COMPONENT_TYPE.INVENTORY,
            question: "-",
            answer: "Inventory",
            inventory_id: answer.inventory_id,
            inventory_no: answer.inventory_no,
            inventory_serial_no: answer.inventory_serial_no,
            inventory_name: answer.inventory_name,
            inventory_data: answer
        };

        return detail as Task.TicketDetail;
    }

    async save() {
        if (!this.edited) {
            return
        }
        this.edited = false;
        this.can_edit = false;


        let image_details = this.ticket.ticket_details.filter(
            d => d.component_type_id === Task.COMPONENT_TYPE.IMAGE
        );

        let untouched_details: Task.TicketDetail[] = [];
        let new_detail_requests: Promise<Task.TicketDetail | Error>[] = [];

        for (let question in this.image_questions) {
            for (let image of this.image_questions[question]) {
                let detail = image_details.find(d => d.answer === image.src);

                if (detail) {
                    // If an image detail with this src exists from before,
                    // that means that we haven't deleted it, so it remains
                    // the same.
                    // We also have to delete the _iimage property because
                    // we can't send it to the backend.
                    delete detail["_iimage"];
                    untouched_details.push(detail);

                } else {
                    // If an image detail with this src does not exist,
                    // that means that we haven't uploaded the image yet,
                    // so we do that, and then we create a new image detail
                    // to attach to the ticket.
                    new_detail_requests.push(
                        this.upload_image(image)
                            .then(res => this.create_image_detail(question, res))
                    );
                }
            }
        }

        for (let question in this.file_questions) {
            for (let detail of this.file_questions[question]) {
                if (is_dms_file(detail)) {
                    // DMS files are already uploaded, so we don't have
                    // to upload them again.
                    untouched_details.push(detail as Task.TicketDetail);
                } else {
                    // IFiles are not uploaded yet, so we have to add them
                    // to the ticket via the upload_document route.
                    // This route creates the ticket detail for us, so we
                    // don't have to do it ourselves.
                     new_detail_requests.push(
                        this.upload_file(
                            this.ticket.ticket_id,
                            question,
                            detail
                        )
                    );
                }
            }
        }

        // First, we remove all of the image and document details.
        this.ticket.ticket_details = this.ticket.ticket_details.filter(
            d =>
            d.component_type_id !== Task.COMPONENT_TYPE.IMAGE &&
            d.component_type_id !== Task.COMPONENT_TYPE.FINANCIAL_DOCUMENT &&
            d.component_type_id !== Task.COMPONENT_TYPE.SERVICE_AND_SUPPORT_DOCUMENT
        );


        let resolved_ticket_details = await Promise.all(new_detail_requests);

        if (resolved_ticket_details.some(d=> d instanceof Error)) {
            this.refetch_tickets();
            return;
        }
        // Then, we add back only the ones we didn't touch and the
        // ones we just uploaded. This gets rid of the deleted details.
        this.ticket.ticket_details.push(
            ...untouched_details,
            ... resolved_ticket_details as Task.TicketDetail[]
        );

        // Finally, we also add inventory details.
        for (let i of this.additional_inventory) {
            let detail = this.create_inventory_detail(i);
            if (detail) {
                this.ticket.ticket_details.push(detail);
            }
        }

        let res = TaskRoutes.tickets.api_ticket_modify(this.ticket.ticket_id, this.ticket, true);
        // this.ticket = res.data;
        // this.update_values();
        this.refetch_tickets(res);
    }

    cancel_edit() {
        if (this.edited && this.data_before_edit) {
            this.ticket = this.data_before_edit;
            this.edited = false;
            this.update_values();
        }
    }


    async toggle_pause_ticket() {
        if (this.ticket.status_id !== STATUS.ON_HOLD) {
            modal.open(TextBoxDialog, async (text) => {
                if (text) {
                    let updated_ticket = await TaskRoutes.tickets.api_ticket_set_on_hold(this.ticket.ticket_id, {comment: text}, true);
                    for (let key in updated_ticket.data) {
                        this.ticket[key] = updated_ticket.data[key];
                    }
                    this.update_values();
                }
            }, {
                title: "TICKET.ON_HOLD.TITLE",
                label: "TICKET.ON_HOLD.REASON"
            });
        } else {
            let updated_ticket = await TaskRoutes.tickets.api_ticket_proceed(this.ticket.ticket_id, true);
            for (let key in updated_ticket.data) {
                this.ticket[key] = updated_ticket.data[key];
            }
            this.update_values();
        }
    }

    async take_ticket() {
        let updated_ticket = await TaskRoutes.tickets.api_ticket_take_over(this.ticket.ticket_id, true);
        for (let key in updated_ticket.data) {
            this.ticket[key] = updated_ticket.data[key];
        }
        this.update_values();
    }

    async toggle_priority() {
        let target_priority = this.ticket.priority_id;

        if (this.ticket.priority_id == Task.PRIORITY.HIGH) {
            target_priority = Task.PRIORITY.MEDIUM;
        } else {
            target_priority = Task.PRIORITY.HIGH;
        }

        await Ticket.modify({ ticket_id: this.ticket.ticket_id, priority_id: target_priority } as Task.Ticket);
        this.ticket.priority_id = target_priority;
        this.update_values();
    }

    createOrder() {
        modal.open(CreateOrderDialog, (order) => {
            if (order) {
                utils.router.navigateByUrl("/orders/" + order.order_id);
            }
        }, [this.ticket]);
    }


    get_ticket_title = () => {
        return `#${this.ticket.ticket_id} ${this.ticket.title}`
    }

    get_location = () => {
        return `#${this.ticket.location_cin} ${this.ticket.location_name}`
    }

    assigned_to = () => {
        const authorized = this.ticket.authorized_maintenance_managers.length > 0
        if(authorized) {
            let authorized_person = ''
            for (let i = 0; i < this.ticket.authorized_maintenance_managers.length; i++) {
                let index = this.ticket.authorized_maintenance_managers[i];

                authorized_person += `${index.first_name} ${index.last_name}`
                if (i !== this.ticket.authorized_maintenance_managers.length -1) {
                    authorized_person += ', '
                }
            }
            return authorized_person;
        } else {
            return undefined
        }
    }

    ticket_paused_reason = () => {
        if(this.is_paused && this.ticket.comment) {
            return `${this.ticket.comment}`
        }

    }

    ticket_reject_reason = () => {
        if(this.is_rejected && this.ticket.comment) {
            return `${this.ticket.comment}`
        }
    }

    ticket_refinement_reason = () => {
        if(this.needs_refinement && this.ticket.comment) {
            return `${this.ticket.comment}`
        }
    }

    get_complaint = () => {
        if(this.ticket.complaint) {
            return this.ticket.complaint
        }
    }

    get_ticket_tiles = (order: Task.Order) => {
		let tiles:{title: string, color: string}[] = [];
		if (order.complaint) {
			tiles.push({title: 'ORDER.RECLAMATION', color: 'var(--yellow-dark)'})
		}
		return tiles;
	}

    get_ticket_history_report() {
        progress.listen(
            "ticket_admin_action",
            TaskRoutes.tickets.api_ticket_history(this.ticket.ticket_id, true)
        );
    }

    revert_dialog(title: string, message: string, status_id: Task.STATUS) {
        modal.open(
            TextBoxDialog,
            async (reason) => {
                if (!reason) return;
                let ticket_id = this.ticket.ticket_id;
                let payload = { status_id, reason };
                await TaskRoutes.tickets.api_ticket_admin(ticket_id, payload);
                this.refetch_tickets();
            }, {
                title: language.translate(title, this.ticket.ticket_id),
                message: {
                    message: language.translate(message),
                    icon: "receipt",
                    iconColor: color.variable(color.Variable.warn)
                },
                label: "TICKET.EXECUTED.REASON",
                placeholder: "TICKET.EXECUTED.ENTER_REASON",
                required: true,
                buttonColor: color.variable(color.Variable.warn),
            }
        );
    }

    revert_executed() {
        this.revert_dialog(
            "TICKET.CHANGE_TO_EXECUTED.TITLE",
            "TICKET.CHANGE_TO_EXECUTED.MSG",
            Task.STATUS.EXECUTED
        );
    }

    change_whier() {
        modal.open(
            WhierPickerDialog,
            async (picked_whier) => {
                if (!picked_whier) return;
                if (picked_whier.whier_id == this.ticket.whier.whier_id) return;

                await TaskRoutes.tickets.api_ticket_modify(
                    this.ticket.ticket_id,
                    { whier_id: picked_whier.whier_id }
                );
                this.refetch_tickets();
            }, {
                title: language.translate('TICKET.CHANGE_WHIER'),
                message: language.translate('TICKET.CHANGE_WHIER.MESSAGE'),
                icon: "account_tree",
                iconColor: color.variable(color.Variable.warn),
                whier: this.ticket.whier as Task.WorkHierarchy,
            }
        );
    }

}
