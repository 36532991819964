import { Component, OnInit } from '@angular/core';
import { ModalBase, notification, color, language } from 'curvy';
import { Task } from '@task-utils/types';
import { User } from './users';
import { TaskRoutes } from '@task-utils/routes';

type UserType = Pick<Task.User, 'first_name' | 'last_name' | 'email' | 'user_type_id' | 'sector_id'> & {company_id?: number};

@Component({
    selector: 'task-create-edit-user-dialog',
    template: `
        <task-create-edit-user [user]="user" (result)="handle_result($event)"></task-create-edit-user>
    `
})
export class CreateEditUserDialog extends ModalBase<UserType | Promise<UserType>, boolean> implements OnInit {
    preventSoftClose = true;
    user: UserType;

    ngOnInit() {
        if (this.injectedData instanceof Promise) {
            this.injectedData.then(res => {
                this.user = res || {
                    first_name: "",
                    last_name: "",
                    email: "",
                    user_type_id: null,
                    sector_id: null,
                }
            });
        } else {
            this.user = this.injectedData || {
                first_name: "",
                last_name: "",
                email: "",
                user_type_id: null,
                sector_id: null,

            };
        }
    }

    handle_result(user: UserType) {
        if (user == null) {
            this.submit(false);
            return;
        }

        this.user = user;

        let promise: Promise<any>;

        // Edit
        if (this.user["user_id"]) {
            promise = User.modify(user as Task.User);
        // Add
        } else {
            promise = TaskRoutes.users.api_user_add(user as Task.User);
        }

        promise.then(() => {
            this.submit(true);
        }).catch(err=>{
            if (!this.user["user_id"]) {
                switch (err.internal_error_code) {
                    case 4003:
                    notification.show({
                        message: language.translate("ERROR.USER_EXISTS_TEXT"),
                        title: language.translate("ERROR"),
                        color: color.Variable.warn,
                        icon: "error",
                        timeout: 0
                    })
                    break;
                    default:
                    notification.show({
                        message: language.translate("ERROR.USER_GENERIC_TEXT"),
                        title: language.translate("ERROR"),
                        color: color.Variable.warn,
                        icon: "error"
                    })
                    break;

                }
            }
        });
    }
}
