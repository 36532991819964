import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';


type WhierPickerInput = {
	title: string;
	message: string;
	icon: string;
	iconColor: string;
	whier: Task.WorkHierarchy;
};

@Component({
	selector: 'task-whier-picker-dialog',
	template: `
		<h2 class="modal-title">{{ injectedData.title | trans }}</h2>

		<div class="flex-row align-center" style="gap: 1em; padding: 0 1em 2em 1em; max-width: 60ch">
			<i class="large flex-auto"
				[attr.icon]="injectedData.icon"
				[knwStyle]="{
					color: injectedData.iconColor,
				}"
			></i>
			<div class="flex-dynamic" style="flex: 12; white-space: pre-line">
				{{injectedData.message}}
			</div>
		</div>

		<div class="flex-column" form-collector #iForm="collector">
			<task-whier-picker required [(ngModel)]="whier">
			</task-whier-picker>
		</div>

		<div class="flex-row modal-footer">
			<button color="primary" (click)="cancel()">
				<i icon="clear"></i>
				{{ 'CANCEL' | trans }}
			</button>

			<div class="flex-dynamic"></div>

			<button class="filled"
					color="secondary"
					(click)="done()"
					[disabled]="iForm.containsErrors()">
				<i icon="check"></i>
				{{ 'SAVE' | trans }}
			</button>
		</div>
	`
})
export class WhierPickerDialog extends ModalBase<WhierPickerInput, Task.WorkHierarchy|null> {
	preventSoftClose = true;
	whier: Task.WorkHierarchy;

	ngOnInit() {
		this.whier = this.injectedData.whier;
	}

	done() {
		this.submit(this.whier);
	}

	cancel() {
		this.submit(null);
	}
}
