<div class="header">
	<div class="flex-row"
			style="flex-flow: row wrap">
		<ng-container *ngFor="let filter of this.filterInput">
			<ng-container *ngIf="!filter?.options?.hidden">
				<ng-container [ngSwitch]="filter.field_type">

					<label *ngSwitchCase="'text'"
							class="input filled"
							[attr.title]="filter.field_label | trans"
							style="flex: 1 1 auto">
						<input type="text"
								[placeholder]="'SRFILTER.SEARCHBY' | trans"
								[(ngModel)]="filter.current_value"
								(keydown.enter)="triggerFilter()"
								style="width: 100px"/>
					</label>

					<knw-auto-search *ngSwitchCase="'search'"
							classes="filled"
							[label]="filter.field_label | trans"
							[to-label]="filter.label_function"
							[auto-blur]="false"
							[popover-class]="get_popover_class | fn : filter"
							[multi]="filter.multi"
							[no-search]="filter.no_search"
							[allow-null]="!!filter.allow_null"
							[function]="filter.search_function"
							(keydown.enter)="triggerFilter()"
							[(ngModel)]="filter.current_value"
							(ngModelChange)="triggerFilter()"
							[popover-width]="filter.popover_width"
					>
					</knw-auto-search>

					<ng-container *ngSwitchCase="'date-range'">
						<task-date-picker-input
							[label]="filter.field_label | trans"
							style="flex: 1 1 auto;"
							class="filled input"
							[classes]="'filled input'"
							[(ngModel)]="filter.start_date"
							(ngModelChange)="triggerFilter()">
						</task-date-picker-input>

						<task-date-picker-input
							[label]="filter.field_end_label | trans"
							style="flex: 1 1 auto;"
							class="filled input"
							[classes]="'filled input'"
							[(ngModel)]="filter.end_date"
							(ngModelChange)="triggerFilter()">
						</task-date-picker-input>
					</ng-container>

					<task-date-picker-input *ngSwitchCase="'date'"
						[label]="filter.field_label | trans"
						style="flex: 1 1 auto"
						class="input filled"
						[classes]="'filled input'"
						[(ngModel)]="filter.current_value"
						(ngModelChange)="triggerFilter()">
					</task-date-picker-input>

					<button #b *ngSwitchCase="'button'"
							[class.round]="filter.field_label == ''"
							[class.pill]="filter.field_label != ''"
							(click)="buttonFilter(filter, b)">
						<i *ngIf="filter.field_icon != ''" [attr.icon]="filter.field_icon"></i>
						{{ filter.field_label }}
					</button>

					<label *ngSwitchCase="'number'"
							class="input filled"
							[attr.title]="filter.field_label | trans"
							style="flex: 1 1 auto">
						<input type="number"
								[(ngModel)]="filter.current_value"
								(keydown.enter)="triggerFilter()"
								style="width: 100px"/>
					</label>

					<label *ngSwitchCase="'toggle'"
							class="input filled"
							[attr.title]="filter.field_label | trans"
							style="flex: 1 1 auto">
						<select [ngModel]="filter.current_value"
								(ngModelChange)="filter.current_value = $event; triggerFilter()"
								(keydown.enter)="triggerFilter()">
							<option [ngValue]="null">{{'SRFILTER.ANY' | trans}}</option>
							<option [ngValue]="true">{{'SRFILTER.YES' | trans}}</option>
							<option [ngValue]="false">{{'SRFILTER.NO' | trans}}</option>
						</select>
					</label>

					<label *ngSwitchCase="'dropdown'"
							class="input filled"
							[attr.title]="filter.field_label | trans"
							style="flex: 1 1 auto">
						<select [(ngModel)]="filter.current_value"
								(ngModelChange)="triggerFilter()"
								(keydown.enter)="triggerFilter()">
							<option [ngValue]="null">{{'SRFILTER.ANY' | trans}}</option>
							<option *ngFor="let option of filter.select_options"
									[ngValue]="option.value">
								{{ option.label | trans }}
							</option>
						</select>
					</label>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>

	<div class="flex-dynamic"></div>

	<div>
		<button class="round trigger-button"
				(click)="triggerFilter()">
			<i icon="search"></i>
		</button>

		<button class="filled round filters-button"
				(click)="showFilters()">
			<i icon="tune"></i>
		</button>

		<button class="round"
				(click)="clearFilter()">
			<i icon="clear"></i>
		</button>
	</div>
</div>
