import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "TICKET.NEW": "New Ticket",
        "TICKET.TITLE": "Ticket",
        "TICKET.INVENTORY": "Inventory",
        "TICKET.RESPONSIBLE": "Responsible person",
        "TICKET.ASSIGNED": "Assigned",
        "TICKET.ASSIGNED_TO": "Responsible person",
        "TICKET.ASSIGNED_TO_OTHERS": "Other responsible persons",
        "TICKET.TAKE_TICKET": "Take ticket",
        "TICKET.APPROVE_TICKET": "Approve",
        "TICKET.REJECT_TICKET": "Reject",
        "TICKET.REQUEST_REFINEMENT": "Request refinement",
        "TICKET.DELETE_TICKET": "Delete",
        "TICKET.PAUSE_TICKET": "Pause",
        "TICKET.UNPAUSE_TICKET": "Unpause",
        "TICKET.COMPANY": "Company",

        "TICKET.REJECTED_REASON": "Rejection reason",
        "TICKET.REFINEMENT_REASON": "Refinement requested",
        "TICKET.PAUSED_REASON": "Hold reason",

        "TICKET.REJECT.TITLE": "Ticket rejection reason",
        "TICKET.REJECT.REASON": "Reason",

        "TICKET.REFINE.TITLE": "Ticket refinement reason",
        "TICKET.REFINE.REASON": "Reason",

        "TICKET.ON_HOLD.TITLE": "Ticket hold reason",
        "TICKET.ON_HOLD.REASON": "Reason",

        "TICKET.CREATED_ORDER": "Order created",
        "TICKET.DELETE.TITLE": "Delete ticket",
        "TICKET.DELETE.CONTENT": (ticket_id, title) => `Are you sure you want to delete the ticket #${ticket_id} ${title}?`,

        "TICKET.IMAGES": "Images",

        "NEW.ORDER": "New Order",

        "NO_LOCATION_ASSIGNED_MSG": "You have no locations assigned. Contact your administrator.",
        'TICKET.ORDER.DETAILS': 'Ticket details',
        'TICKET.DOCUMENTS': 'Documents',
        'TICKET.STORE_MANAGER': 'Store manager',
        'TICKET.TICKET_HISTORY': 'History report',
        'TICKET.CREATED_BY': 'Ticket creted by',
        'TICKET.CHANGE_TO_EXECUTED': 'Change to executed',
		'TICKET.CHANGE_TO_EXECUTED.TITLE': (ticket_id) => `Change status to executed for the ticket #${ticket_id}`,
		"TICKET.CHANGE_TO_EXECUTED.MSG": "Are you sure you want to chanage the status of this ticket?",
        "TICKET.EXECUTED.REASON" : 'Execution reason',
        "TICKET.EXECUTED.ENTER_REASON": "Enter the reason for executing this ticket",

        "TICKET.CHANGE_WHIER": "Change hierarchy",
        "TICKET.CHANGE_WHIER.MESSAGE": "Select a new work hierarchy.\nNote: Changing the work hierarchy implies sending emails to responsible persons and the ticket creator.",

    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "TICKET.NEW": "Nova prijava",
        "TICKET.TITLE": "Prijava",
        "TICKET.INVENTORY": "Inventar",
        "TICKET.RESPONSIBLE": "Područni voditelj",
        "TICKET.ASSIGNED": "Voditelj održavanja",
        "TICKET.ASSIGNED_TO": "Odgovorna osoba",
        "TICKET.ASSIGNED_TO_OTHERS": "Odgovorne osobe na zamjeni",
        "TICKET.TAKE_TICKET": "Preuzmi prijavu",
        "TICKET.APPROVE_TICKET": "Odobri",
        "TICKET.REJECT_TICKET": "Odbij",
        "TICKET.REQUEST_REFINEMENT": "Zatraži doradu",
        "TICKET.DELETE_TICKET": "Obriši",
        "TICKET.PAUSE_TICKET": "Na čekanje",
        "TICKET.UNPAUSE_TICKET": "Nastavi",
        "TICKET.COMPANY": 'Tvrtka',

        "TICKET.REJECTED_REASON": "Razlog odbijanja",
        "TICKET.REFINEMENT_REASON": "Zatražena dorada",
        "TICKET.PAUSED_REASON": "Razlog čekanja",

        "TICKET.REJECT.TITLE": "Razlog odbijanja prijave",
        "TICKET.REJECT.REASON": "Razlog",

        "TICKET.REFINE.TITLE": "Razlog dorade prijave",
        "TICKET.REFINE.REASON": "Razlog",

        "TICKET.ON_HOLD.TITLE": "Razlog pauziranja prijave",
        "TICKET.ON_HOLD.REASON": "Razlog",

        "TICKET.CREATED_ORDER": "Kreiran nalog",
        "TICKET.DELETE.TITLE": "Brisanje prijave",
        "TICKET.DELETE.CONTENT": (ticket_id, title) => `Želite obrisati prijavu #${ticket_id} ${title}?`,

        "TICKET.IMAGES": "Slike",

        "NEW.ORDER": "Novi nalog",

        "NO_LOCATION_ASSIGNED_MSG": "Niste odgovorni niti za jednu lokaciju. Obratite se administratoru.",
        'TICKET.ORDER.DETAILS': 'Detalji prijave',
        'TICKET.DOCUMENTS': 'Dokumenti',
        'TICKET.STORE_MANAGER': 'Voditelj prodavaonice',
        'TICKET.TICKET_HISTORY': 'Izvještaj događaja',
        'TICKET.CREATED_BY': 'Prijavu kreirao',
        'TICKET.CHANGE_TO_EXECUTED': 'Prebaci u izvršeno',
        'TICKET.CHANGE_TO_EXECUTED.TITLE': (ticket_id) => `Promijeni status u izvrešno za prijavu #${ticket_id}`,
		"TICKET.CHANGE_TO_EXECUTED.MSG": "Jeste li sigurni da želite izmijeniti status za ovu prijavu?",
        "TICKET.EXECUTED.REASON" : 'Razlog izvršavanja',
        "TICKET.EXECUTED.ENTER_REASON": "Unesite razlog izvršavanja prijave",

        "TICKET.CHANGE_WHIER": "Promjena hijerarhije",
        "TICKET.CHANGE_WHIER.MESSAGE": "Odaberite novu radnu hijerarhiju.\nNapomena: Promjena radne hijerarhije podrazumijeva slanje emailova odgovornim osobama i kreatoru prijave.",

    }
};

export const tickets_translations = [english, croatian];
