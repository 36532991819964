import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import type * as F from './single-row-filter.component';

function clone<T>(obj: T): T {
	switch (typeof obj) {
		case "object":
			if (obj == null) return obj;
			if (Array.isArray(obj)) {
				let out: any[] = [];
				for (let item of obj) {
					out.push(clone(item));
				}
				return out as T;
			}
			if (obj.constructor == Object) {
				let out: Record<any, any> = {};
				for (let key in obj) {
					out[key] = clone(obj[key]);
				}
				return out as T;
			}
			return obj;
		default: return obj;
	}
}

@Component({
    selector: 'knw-filter-dialog',
    template: `
	<h3>{{ 'SEARCH' | trans }}</h3>
	<div class="filters-list">
		<div class="flex-column">
			<ng-container *ngFor="let filter of this.injectedData.filterInput">
				<ng-container *ngIf="!filter?.options?.hidden">
					<ng-container [ngSwitch]="filter.field_type">

						<label *ngSwitchCase="'text'"
								class="input filled"
								[attr.title]="filter.field_label | trans"
								style="flex: 1 1 auto">
							<input type="text"
									[placeholder]="'SRFILTER.SEARCHBY' | trans"
									[(ngModel)]="filter.current_value"
									style="width: 100px"/>
						</label>

						<knw-auto-search *ngSwitchCase="'search'"
								classes="filled"
								[label]="filter.field_label | trans"
								[to-label]="filter.label_function"
								[auto-blur]="false"
								[popover-class]="get_popover_class | fn : filter"
								[multi]="filter.multi"
								[no-search]="filter.no_search"
								[allow-null]="!!filter.allow_null"
								[function]="filter.search_function"
								[(ngModel)]="filter.current_value"
								[popover-width]="filter.popover_width"
						>
						</knw-auto-search>

						<ng-container *ngSwitchCase="'date-range'">
							<knw-date-picker-input
								[label]="filter.field_label | trans"
								style="flex: 1 1 auto;"
								class="filled input"
								[classes]="'filled input'"
								[(ngModel)]="filter.start_date">
							</knw-date-picker-input>

							<knw-date-picker-input
								[label]="filter.field_end_label | trans"
								style="flex: 1 1 auto;"
								class="filled input"
								[classes]="'filled input'"
								[(ngModel)]="filter.end_date">
							</knw-date-picker-input>
						</ng-container>

						<knw-date-picker-input *ngSwitchCase="'date'"
							[label]="filter.field_label | trans"
							style="flex: 1 1 auto"
							class="input filled"
							[classes]="'filled input'"
							[(ngModel)]="filter.current_value">
						</knw-date-picker-input>

						<button #b *ngSwitchCase="'button'"
								[class.round]="filter.field_label == ''"
								[class.pill]="filter.field_label != ''"
								(click)="buttonFilter(filter, b)">
							<i *ngIf="filter.field_icon != ''" [attr.icon]="filter.field_icon"></i>
							{{ filter.field_label }}
						</button>

						<label *ngSwitchCase="'number'"
								class="input filled"
								[attr.title]="filter.field_label | trans"
								style="flex: 1 1 auto">
							<input type="number"
									[(ngModel)]="filter.current_value"
									style="width: 100px"/>
						</label>

						<label *ngSwitchCase="'toggle'"
								class="input filled"
								[attr.title]="filter.field_label | trans"
								style="flex: 1 1 auto">
							<select [(ngModel)]="filter.current_value">
								<option [ngValue]="null">{{'SRFILTER.ANY' | trans}}</option>
								<option [ngValue]="true">{{'SRFILTER.YES' | trans}}</option>
								<option [ngValue]="false">{{'SRFILTER.NO' | trans}}</option>
							</select>
						</label>

						<label *ngSwitchCase="'dropdown'"
								class="input filled"
								[attr.title]="filter.field_label | trans"
								style="flex: 1 1 auto">
							<select [(ngModel)]="filter.current_value">
								<option [ngValue]="null">{{'SRFILTER.ANY' | trans}}</option>
								<option *ngFor="let option of filter.select_options"
										[ngValue]="option.value">
									{{ option.label | trans }}
								</option>
							</select>
						</label>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div class="flex-dynamic"></div>

	<div class="flex-row">
		<button (click)="rejectChanges()" color="primary">
			<i icon="clear"></i>
			{{ 'CANCEL' | trans }}
		</button>

		<div class="flex-dynamic"></div>

		<button class="filled" (click)="submit(true)" color="secondary">
			<i icon="check"></i>
			{{ 'CONFIRM' | trans }}
		</button>
	</div>
	`,
    encapsulation: ViewEncapsulation.None,
    styles: [`
		knw-filter-dialog {
			display: flex;
			flex-flow: column nowrap;
		}

		knw-filter-dialog knw-auto-search {
			display: contents !important;
		}

		knw-filter-dialog .filters-list .flex-column > button {
			align-self: center;
		}

		knw-filter-dialog .filters-list {
			overflow: auto;
		}
	`],
    standalone: false
})
export class FilterDialogComponent extends ModalBase<F.TaskFilterComponent, boolean> implements OnInit {
	storedValue: any;

	ngOnInit() {
		this.storedValue = clone(this.injectedData.filterInput);
	}

	rejectChanges() {
		this.injectedData.filterInput = this.storedValue;
		this.submit(false);
	}

	buttonFilter(filter: F.ButtonFilterInput, button: HTMLButtonElement) {
		this.injectedData.buttonFilter(filter, button);
	}

	get get_popover_class() {
		return this.injectedData.get_popover_class;
	};
}
