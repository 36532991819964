<div class="main-page-wrapper" *ngIf="ticket">
    <!-- Ticket header section -->
    <header class="ticket-header">
        <div *ngIf="ticket.assigned_user_id || can_take || can_see_admin_actions()">
            <div class="flex-row flex-dynamic justify-flex-end wrappable" style="padding: 0 0 1em 0; margin-left: auto;" *ngIf="!edited">
                <button class="filled round"
                    [disabled]="edited"
                    color="secondary"
                    (click)="approve_ticket()"
                    [title]="'TICKET.APPROVE_TICKET' | trans"
                    *ngIf="can_approve">
                    <i icon="check"></i>
                </button>

                <button class="filled round"
                    [disabled]="edited"
                    color="warn"
                    [title]="'TICKET.REJECT_TICKET' | trans"
                    (click)="reject_ticket()"
                    *ngIf="can_reject">
                    <i icon="clear"></i>
                </button>

                <button class="filled round"
                    [disabled]="edited"
                    color="secondary"
                    [title]="'TICKET.REQUEST_REFINEMENT'| trans"
                    (click)="request_refinement()"
                    *ngIf="can_request_refinement">
                    <i icon="restore_page"></i>
                </button>

                <button class="filled round"
                    [disabled]="edited"
                    color="secondary"
                    [title]="(is_paused ? 'TICKET.UNPAUSE_TICKET' : 'TICKET.PAUSE_TICKET') | trans"
                    (click)="toggle_pause_ticket()"
                    *ngIf="can_pause">
                    <i icon="schedule"></i>
                </button>

                <button *ngIf="can_take"
                    [disabled]="edited"
                    class="filled round"
                    color="secondary"
                    [title]="'TICKET.TAKE_TICKET' | trans"
                    (click)="take_ticket()">
                    <i icon="assignment_returned"></i>
                </button>

                <button class="filled round"
                    [disabled]="edited"
                    color="warn"
                    style="margin-right: 0"
                    [title]="'TICKET.DELETE_TICKET' | trans"
                    (click)="delete_ticket()"
                    *ngIf="can_delete">
                    <i icon="delete"></i>
                </button>


                <knw-spinner  style="padding: 0; margin-left: 4px;" color="primary" [size]="32" *ngIf="'ticket_admin_action' | inProgress" />
                <details *ngIf="!edited && can_see_admin_actions() && !('ticket_admin_action' | inProgress)"
                    #adminMenu
                    class="as-button fake-button filled round"
                    style="padding: 0; margin-left: 4px;"
                    (clickOutside)="adminMenu.open = false"
                    title="{{'ADMIN-BUTTONS' | trans}}"
                >
                    <summary class="no-arrow" style="padding: .5em">
                        <i icon="admin_panel_settings"></i>
                    </summary>

                    <div class="admin-floating shadow-bottom">
                        <button class="sharp"
                            style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                            *ngIf="can_see_ticket_history "
                            (click)="get_ticket_history_report(); adminMenu.open = false"
                        >
                            <i [attr.icon]="ICON_TICKET_HISTORY.icon"
                                [style.color]="ICON_TICKET_HISTORY.color"></i>
                            <i style="position: absolute; left: 1.15rem; top: 1.25rem; font-size: 70%" icon="watch_later"></i>
                            {{'TICKET.TICKET_HISTORY' | trans}}
                        </button>

                        <button class="sharp"
                            style="width: 100%; text-transform: none; justify-content: start; gap: .25em"
                            *ngIf="can_revert_executed"
                            (click)="revert_executed(); adminMenu.open = false">
                            <i [attr.icon]="ICON_EXECUTED.icon"
                                [style.color]="ICON_EXECUTED.color"
                            ></i>
                            <i style="position: absolute; left: 1.35rem; top: 1.25rem; font-size: 70%" icon="fast_forward"></i>
                            {{ 'TICKET.CHANGE_TO_EXECUTED' | trans }}
                        </button>
                    </div>
                </details>
            </div>
        </div>
    </header>

    <!-- Page sections start here -->
    <section class="single-page-section grid-layout grid-entity-header">
        <div class="grid-card main-info-card">
            <div class="card-header">
                <div class="flex-row justify-space-between align-center width-full">
				    <h3 class="card-header-title">{{'TICKET' | trans }}</h3>

                    <div>
                        <button
                            [disabled]="edited"
                            class="pill"
                            style="font-size:90%;"
                            (click)="toggle_priority()">
                            <i [attr.icon]="priority_icon" [style.color]="priority_color"></i> &nbsp;
                            {{ priority_text }}
                        </button>
                    </div>
                </div>
			</div>
            <div class="card-body flex-column" style="gap: .5em;" *ngIf="ticket.assigned_user_id || can_take">
                <task-entity-info
					[title]="{text: get_ticket_title()}"
					[properties]="{
						'LOCATION': get_location(),
                        'CREATED_DATE': ticket.created_date | datefmt : 'short_date_time' : false,
                        'TICKET.CREATED_BY': ticket | fullname : 'created_by',
                        'TICKET.ASSIGNED_TO': assigned_to(),
                        'TICKET.PAUSED_REASON': ticket_paused_reason(),
                        'TICKET.REJECTED_REASON': ticket_reject_reason(),
                        'TICKET.REFINEMENT_REASON': ticket_refinement_reason(),
                        'NAVROUTE.WORKHIER': ticket.whier,
					}"
					[status]="get_ticket_status(ticket)"
					[tiles]="get_ticket_tiles | fn : ticket"></task-entity-info>
            </div>
        </div>

        <!-- Ticket's orders card-->
        <div class="grid-card linked-entity-card">
			<div class="card-header">
                <h3 class="card-header-title">{{'RELATED.ORDERS' | trans }}</h3>
                <button  class="add-btn round filled"
                    color="primary"
                    *ngIf="can_create_order"
                    [attr.tooltip-left]="('NEW.ORDER') | trans"
                    (click)="createOrder()">
                    <i icon="add"></i>
                </button>
			</div>
			<div class="card-body flex-column wrappable align-flex-start" style="margin-bottom: 1.5rem; gap: .6em;">
                <ng-container *ngFor="let order of orders">
                    <button class="filled pill flex-row justify-flex-start align-flex-start"
                            color="secondary"
                            [goto]="'/orders/' + order.order_id">
                        <b>#{{order.order_id}}</b> &nbsp;
                        <i class="truncate-btn-text">{{order.title}}</i>
                    </button>
                </ng-container>
			</div>
		</div>
    </section>

    <!-- Ticket details section -->
    <section class="single-page-section" id="ticket-details" form-collector #ticketDetailsForm="collector">
        <div class="sticky-header">
            <h2>{{'TICKET.ORDER.DETAILS' | trans}}</h2>
            <div class="flex-row wrappable justify-flex-end" style="gap: .5em;" *ngIf="edited">
                <button class="enlarge-btn pill filled"
                        color="secondary"
                        (click)="save()">
                    <i icon="save"></i><span class="screen-big">{{ "SAVE" | trans }}</span>
                </button>

                <button class="enlarge-btn outlined pill"
                        style="margin-right: 0"
                        color="primary"
                        (click)="cancel_edit()">
                    <i icon="cancel"></i><span class="screen-big">{{ "CANCEL" | trans}}</span>
                </button>
            </div>
        </div>

        <div class="grid-layout grid-entity-detilas" style="margin-bottom: .6rem;">
            <!-- Ticket description detail  -->
            <div class="grid-card text-details-card">
                <div class="card-header">
                    <h3 class="card-header-title">{{ 'DETAILS' | trans }}</h3>
                </div>

                <div class="card-body">
                    <ng-container *ngFor="let detail of ticket.ticket_details">
                        <ng-container [ngSwitch]="detail.component_type_id">
                            <div class="flex-row" *ngSwitchCase="1">
                                <i [attr.icon]="
                                        detail.answer === 'true' ?
                                            'check_circle' :
                                        detail.answer === 'false' ?
                                            'cancel' :
                                        'help'"
                                    style="padding: 0.5em">
                                </i>
                                <i><b>{{detail.question}}</b></i>
                            </div>

                            <div class="flex-row wrappable text-details-uneditable"  *ngSwitchCase="3">
                                <div class="flex-row wrappable flex-dynamic align-flex-start" [ngClass]="{'editable': can_edit}">
                                    <strong style="flex: 0 0 100%;">{{detail.question}}:</strong>
                                    <task-click-toggle [can-toggle]="can_edit" [classList]="'flex-dynamic flex-row align-flex-start width-full'">
                                        <div on class="flex-dynamic">
                                            <label class="textarea">
                                                <textarea [(ngModel)]="detail.answer"
                                                    (ngModelChange)="edited = true">
                                                </textarea>
                                            </label>
                                        </div>

                                        <h4 class="card-header-title">{{detail.question}}: </h4>
                                        <div class="flex-row justify-space-between insert-text-toggle width-full" off>
                                            <span [ngStyle]="{'cursor': can_edit ? 'pointer': 'auto'}">{{detail.answer}}</span>

                                            <button *ngIf="can_edit" class="add-btn round" color="primary">
                                                <i icon="edit"></i>
                                            </button>
                                        </div>
                                    </task-click-toggle>
                                </div>
                                <div class="flex-row wrappable">
                                    <strong>{{"TIME" | trans}}: </strong>
                                    <span>{{ ticket.created_date | datefmt : "short_date_time" : false }}</span>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>

            </div>
            <!-- Inventory component -->
            <div class="grid-card inventory-card">
                <div class="card-header">
                    <h3 class="card-header-title">{{'INVENTORY.PICK' | trans}}</h3>
                </div>
                <div class="card-body">
                    <task-inventory-cards
                        *ngIf="can_edit"
                        [ngModel]="additional_inventory"
                        (ngModelChange)="
                            additional_inventory=$event;
                            edited=true"
                        [location_cin]="ticket.location_cin"
                        [in_grid]="true"
                        [classes]="' flex-dynamic'"
					></task-inventory-cards>
                    <ng-container *ngFor="let detail of ticket.ticket_details">
                        <ng-container *ngIf="detail.inventory_data">
                            <task-kam-inventory [inventory]="detail.inventory_data || detail"></task-kam-inventory>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <!-- Images component -->
            <div class="grid-card images-card">
                <div class="card-body flex-column" style="gap: .5rem;">
                    <div *ngFor="let question of image_questions | keyvalue">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 0.5rem; position: sticky; top: 0; background: rgb(var(--background-light)); z-index: 10;">
                            <h3 class="card-header-title">
                               {{question.key == "-" ? ("TICKET.IMAGES" | trans) : question.key}}
                            </h3>
                            <button *ngIf="can_edit" class="add-btn round filled" color="primary" (click)="image.get_images()">
                                <i icon="add"></i>
                            </button>
                        </div>
                        <div class="overflow-card-body">
                            <task-multi-image-new
                                #image
                                [(ngModel)]="question.value"
                                (ngModelChange)="image_questions[question.key]=$event;edited = true"
                                [disabled]="!can_edit"
                                [in_grid]="true">
                            </task-multi-image-new>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Documnets component -->
            <div class="grid-card files-card">

                <div class="card-body flex-column" style="gap: .5rem;">
                    <div *ngFor="let question of file_questions | keyvalue">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 0.5rem; position: sticky; top: 0; background: rgb(var(--background-light)); z-index: 10;">
                            <h3 class="card-header-title">
                                {{question.key == "-" ? ("FILES" | trans) : question.key}}
                            </h3>
                            <button *ngIf="can_edit" class="add-btn round filled" color="primary" (click)="file.get_files_for_upload()">
                                <i icon="add"></i>
                            </button>
                        </div>
                        <div class="overflow-card-body">
                            <task-multi-files
                                #file
                                [ngModel]="question.value"
                                (ngModelChange)="
                                file_questions[question.key]=$event;
                                edited=true"
                                [allow-deleting-uploaded]="true"
                                [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                                [download_file]="download_file"
                                [disabled]="!can_edit"
                                [in_grid]="true"
                            ></task-multi-files>
                        </div>
                    </div>

				</div>
            </div>
        </div>
    </section>

    <!-- Comments section -->
	<section class="single-page-section" style="margin-bottom: 0;">
		<h2>{{ 'COMMENTS' | trans }}</h2>
		<task-new-comments [entity]="entity" [entity_id]="ticket.ticket_id"></task-new-comments>
	</section>
</div>