import { Component } from "@angular/core";
import { Task } from "@task-utils/types";
import { ModalBase } from "curvy";

interface InputType { financial_items: Task.ArrivalDetail[], };

@Component({
    selector: 'task-revert-items',
    template: `
        <div class="flex-column fullheight-noscroll">
            <h2 class="flex-static modal-title">{{ "ORDER.REVERT_ITEMS" | trans }}</h2>

            <div class="flex-row justify-space-between">
                <button [disabled]="details_to_revert.size == injectedData.financial_items.length" (click)="revertAll()">
                    <i icon="check"></i>
                    {{ "SELECT_ALL" | trans }}
                </button>
                <button [disabled]="details_to_revert.size == 0" (click)="revertNone()">
                    <i icon="clear"></i>
                    {{ "DESELECT_ALL" | trans }}
                </button>
            </div>

            <div class="details-container" style="overflow: auto;">
                <ng-container *ngFor="let item of injectedData.financial_items">
                    <label class="checkbox-input" style="display: flex">
                        <input type="checkbox"
                            [ngModel]="details_to_revert.has(item)"
                            (ngModelChange)="detailChanged(item)" />
                        <div>
                            <b>{{ item.element_name }}</b>
                            ({{item.element_quantity}}x)
                        </div>
                    </label>
                </ng-container>
            </div>

            <div class="flex-row modal-footer">
                <button color="primary" (click)="cancel()">
                    <i icon="clear"></i>
                    <span>{{ 'CANCEL' | trans }}</span>
                </button>

                <button class="filled"
                        color="secondary"
                        (click)="save()">
                    <i icon="check"></i>
                    <span>{{ 'SAVE' | trans }}</span>
                </button>

            </div>
        </div>


    `,
    styleUrls: ['./order.component.scss']

})
export class RevertItemsDialog extends ModalBase<InputType, Set<Task.ArrivalDetail>> {
    preventSoftClose = true;

    details_to_revert: Set<Task.ArrivalDetail> = new Set();

    detailChanged(detail: Task.ArrivalDetail) {
        if (this.details_to_revert.has(detail)) {
            this.details_to_revert.delete(detail);
        } else {
            this.details_to_revert.add(detail);
        }
    }

    revertAll() {
        for (let item of this.injectedData.financial_items) {
            this.details_to_revert.add(item);
        }
    }

    revertNone() {
        this.details_to_revert.clear();
    }

    cancel() {
        this.submit(null);
    }

    save() {
        this.submit(this.details_to_revert);
    }
}
