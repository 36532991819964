import { environment } from "environments/environment";
import { network } from "curvy";
import { Task } from "@task-utils/types";

let backendUrl: string;

// Automatically determine backendURL if we're not in production or if it isn't specified.
if (environment.production && environment["backendURL"]) {
	backendUrl = environment["backendURL"];
} else {
	let base: HTMLBaseElement = document.querySelector('base[href]');

	if (base) {
		backendUrl = base.href + "api";
		backendUrl = backendUrl.replace(/:\d+[/]/, ":5001/");
	} else {
		throw new Error("Unable to determine backend URL - no environment file and can't find <base href='...'>!)");
	}
}

export namespace TaskRoutes {
	type Paginator = network.Headers.Paginator;

	export type Sort = {
		/** Set to `true` to sort ascending. Sorts are descending by default. */
		ascending?: boolean,
		/** Look at {@link Search_Params.attributes} and {@link Search_Params.sort}. */
		attribute?: string,

		/** @deprecated use {@link Sort.attribute} instead */
		label?: string
		/** @deprecated use {@link Sort.ascending} instead */
		direction?: "asc" | "desc",
	};

	/**
		Anything can be sent as a search param, but ultimately,
		each search param will be cast according to what flags are
		put in the corresponding place in the 'attributes' array.
	*/
	export type Search_Param = string|number|boolean|null;
	export type Search_Param_List = Search_Param[];

	/**
		```javascript
			// Find with user_id==1
			{
				search: ["#=:user_id"],
				attributes: [1]
			}

			// Find where first name includes 'ivan'
			// and email includes '@gmail.com'
			{
				search: ["first_name", "email"],
				attributes: ["ivan", "@gmail.com"]
			}

			// Find where order title includes 'vitrina'
			{
				search: ["order.title"],
				attributes: ["vitrina"]
			}

			// Find where (first_name + " " + last_name)
			// includes 'ivan horvat'
			{
				search: ["first_name+ +last_name"],
				attributes: ["ivan horvat"]
			}

			// Find where status_id!=3
			{
				search: ["#!:status_id"],
				attributes: [3]
			}

			// Find where status_id is one of (1, 2, 3, 4, 5)
			{
				search: ["U:status_id"],
				attributes: [[1, 2, 3, 4, 5]]
			}

			// Find where status_id is NOT one of (1, 2, 3, 4, 5)
			{
				search: ["X:status_id"],
				attributes: [[1, 2, 3, 4, 5]]
			}
		```
		More details and examples in comments below.
	*/
	export type Search_Params = {
		/**
			Array of items we are searching for.
			These must be in the same order as the {@link Search_Params.attributes} array.
		*/
		search: (Search_Param|Search_Param_List)[],

		/**
			The attributes that we are searching by. They are an array
			of strings that explain to the backend _how_ we want to perform
			out search.

			The general format of an attribute string is
			`  "FLAGS:PROPERTIES"  `.

			__NOTE__: Flags can be specified in any order.

			Some examples:

			- `  "#=:user_id"  `
				- will cast your search parameter to a whole number (# type flag)
				and then do exact matching (= comparison flag) to the 'user_id' property
				Note that the ':' character separates the flags from the rest of the attribute
			- `  "first_name+ +last_name"  `
				- will cast your search parameter to a string (because no type flags are specified)
				and then match that (partially and case-insensitive, because no comparison flags)
				to the 'first_name' and 'last_name' properties, separated by a single space.
				Effectively, in javascript, this would be:
					(first_name + " " + last_name).includes(value)


			## PROPERTIES

			Properties that can be used depend on the route.
			They can be found in the backend code, but usually they will correspond to
			the properties of the entity that is being searched.

			For example, if you're searching for an arrival, the available attributes might be:

				- arrival_id
				- status_id
				- order_id
				- start_time
				- end_time
				- planned_start_time
				- planned_end_time
				- emergency
				- ended_without_qr

			...and more, which can all be found in the backend
				at `project/api/models/arrivals.py`

			Additionally, sometimes other entities will be joined by the backend, which
			allows us to access their attributes as well.
			Usually, for arrivals, the corresponding order will be joined, so we will
			be able to access the order's attributes as well:

				- order.status_id
				- order.title
				- order.supplier_name
				- order.investment_type

			... and more, which can all be found in the backend at
				project/api/models/orders.py

			Unfortunately, to find out what has been joined, you have to look at the backend
			code, or ask the backend dev.

			## PROPERTY CONCATENATION
			The '+' character can be used in the "PROPERTIES" part of the attribute string
			to concatenate different properties as a string.

			For example, `   "first_name+last_name"   ` will concatenate the
			`first_name` and `last_name` properties. But we probably want
			the properties to be separated by a space when we are searching,
			so we can do `   "first_name+ +last_name"   `
			to add a space between them.

			We can also concatenate other strings, like:
			- `   "ticket_id+ +title"   `
				- will concatenate ticket_id and title with a space
			- `  "'+title+'"   `
				- will put ' around the title
			- `   "#+ticket_id"   `
				- will put # in front of the ticket_id

			## TYPE FLAGS
			These flags determine how the search value
			and the database attributes will be transformed.

			- `#` - integer (whole number)
			- `.` - float (decimal number)
			- `s` - string
			- `d` - date
			- `b` - boolean
			- `?` - null/none
			- `A` - array
			- defaults:
				- If no type flags are provided, the default type flag
				is 's' (string), unless the search value is an array,
				in which case the default type flag will be 'A'.

			## COMPARISON FLAGS
			These flags determine how matching
			is done on the transformed values.

			- `=`  - value == attribute
			- `!`  - value != attribute
			- `>`  - value > attribute
			- `<`  - value < attribute
			- `+`  - value >= attribute
			- `-`  - value <= attribute
			- `L`  - attribute LIKE value (case-sensitive string match)
			- `I`  - attribute ILIKE value (case-insensitive string match)
			- `S`  - behaves like I if value is lowercase, and like L if it is not
			- `*L` - attribute LIKE %value% (case-sensitive partial string match)
			- `*I` - attribute ILIKE %value% (case-insensitive partial string match)
			- `*S` - behaves like *I if value is lowercase, and like *L if it is not
			- `U`  - attribute IN value (value MUST be an array, implies the A type flag)
			- `X`  - attribute NOT IN value (value MUST be an array, implies the A type flag)
			- defaults:
				- The default comparison depends on the type flag:
				- `#` - =
				- `.` - =
				- `s` - *I
				- `d` - =
				- `b` - =
				- `?` - =
				- `A` - U

		*/
		attributes: string[],

		/**
			Attributes that we are sorting by.
			TYPE FLAGS and PROPERTY CONCATENATION can be used here as well.
		*/
		sort?: Sort[] | Sort
	};

	export type Payload<T=unknown> = {
		status: string,
		message: string,
		count: number,
		data: T
	};

	export type Log<T=unknown, KEYNAME extends string=""> = T & { log_created_date: Date; } & Record<KEYNAME, number>;

	function keep_props(obj: any, ...allowed_props: string[]) {
		for (let prop in obj) {
			if (!allowed_props.includes(prop)) {
				obj[prop] = undefined;
			}
		}
	}

	function normalize_sort(obj: { sort?: Sort[] | Sort }) {
		if (!obj || !obj.sort) { return; }
		if (!Array.isArray(obj.sort)) {
			obj.sort = [obj.sort];
		}
		for (let item of obj.sort) {
			if (item.direction) {
				item.ascending = item.direction == "asc";
			}
			if (item.label) {
				item.attribute = item.label;
			}
		}
	}

	function normalized_header(header_name: string, header_value: any) {
		if (header_value==="" || header_value==null) return {};
		header_name = header_name.replace(/_/g, "-");
		return {[header_name]: header_value+""};
	}
	export const backend = backendUrl;

	export namespace arrivals {
		export const arrival_delete = (arrival_id: number) => `${backend}/arrivals/${arrival_id}`;
		export async function api_arrival_delete(arrival_id: number) {
			return await network.DELETE<Payload>({ url: arrival_delete(arrival_id) });
		};


		export const arrival_detail_delete = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}`;
		export async function api_arrival_detail_delete(arrival_detail_id: number) {
			return await network.DELETE<Payload>({ url: arrival_detail_delete(arrival_detail_id) });
		};


		export const arrival_worker_delete = (arrival_worker_id: number) => `${backend}/arrivals/arrival_workeres/${arrival_worker_id}`;
		export async function api_arrival_worker_delete(arrival_worker_id: number) {
			return await network.DELETE<Payload>({ url: arrival_worker_delete(arrival_worker_id) });
		};


		export const arrival_get_all = () => `${backend}/arrivals`;
		export async function api_arrival_get_all(paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Arrival[]>>(
				{
					url: arrival_get_all(),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const arrival_get_single = (arrival_id: number) => `${backend}/arrivals/${arrival_id}`;
		export async function api_arrival_get_single(arrival_id: number, expand_details = true) {
			return await network.GET<Payload<Task.Arrival>>(
				{
					url: arrival_get_single(arrival_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_order_get = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/order`;
		export async function api_arrival_order_get(arrival_id: number, expand_details = false) {
			return await network.GET<Payload<Task.Order>>(
				{
					url: arrival_order_get(arrival_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const qr_code_create = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/create_qr_code?nocache=${Date.now()}`;
		export async function api_qr_code_create(arrival_id: number) {
			return await network.GET<Payload<Task.QR_Code>>({ url: qr_code_create(arrival_id) });
		};


		export const arrival_non_started_deactivate_all = () => `${backend}/arrivals/deactivate_non_started_arrivals`;
		export async function api_arrival_non_started_deactivate_all() {
			return await network.GET<Payload>({ url: arrival_non_started_deactivate_all() });
		};


		export const arrival_detail_logs_get = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}/logs`;
		export async function api_arrival_detail_logs_get(arrival_detail_id: number) {
			return await network.GET<Payload<Log<Task.ArrivalDetail, "arrival_detail_log_id">[]>>(
				{ url: arrival_detail_logs_get(arrival_detail_id) },
			);
		};


		export const arrival_detail_approve = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}/approve`;
		export async function api_arrival_detail_approve(arrival_detail_id: number) {
			return await network.GET<Payload<Task.ArrivalDetail>>(
				{ url: arrival_detail_approve(arrival_detail_id) },
			);
		};


		export const arrival_work_order_get = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/work_order`;
		export async function api_arrival_work_order_get(arrival_id: number, download: boolean) {
			return await network.GET<Blob>({ url: arrival_work_order_get(arrival_id), download });
		};


		export const arrival_detail_inventory_dissociate = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}/dissociate_inventory`;
		export async function api_arrival_detail_inventory_dissociate(arrival_detail_id: number) {
			return await network.GET<Payload<Task.ArrivalDetail>>(
				{ url: arrival_detail_inventory_dissociate(arrival_detail_id) },
			);
		};


		export const get_document_by_arrival_detail_id = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}`;
		export async function api_get_document_by_arrival_detail_id(arrival_detail_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: get_document_by_arrival_detail_id(arrival_detail_id), download },
			);
		};


		export const arrival_modify = (arrival_id: number) => `${backend}/arrivals/${arrival_id}`;
		interface i_arrival_modify {
			arrival_workers?: Task.ArrivalWorker[];
			planned_no_of_workers?: number;
			planned_start_time?: Date | string;
		};
		export async function api_arrival_modify(arrival_id: number, patch_data: i_arrival_modify, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"arrival_workers",
				"planned_no_of_workers",
				"planned_start_time",
			);
			return await network.PATCH<Payload<Task.Arrival>>(
				{
					url: arrival_modify(arrival_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_detail_modify = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}`;
		interface i_arrival_detail_modify {
			arrival_detail_type_id?: number;
			description?: string;
			element_cin?: string;
			element_currency?: string;
			element_id?: Task.Pricelist["element_id"];
			element_name?: string;
			element_price?: number;
			element_quantity?: number;
			element_type_id?: number;
			element_uom?: string;
			lump_sum?: boolean;
			picture_url?: string;
		};
		export async function api_arrival_detail_modify(arrival_detail_id: number, patch_data: i_arrival_detail_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"arrival_detail_type_id",
				"description",
				"element_cin",
				"element_currency",
				"element_id",
				"element_name",
				"element_price",
				"element_quantity",
				"element_type_id",
				"element_uom",
				"lump_sum",
				"picture_url",
			);
			return await network.PATCH<Payload<Task.ArrivalDetail>>(
				{ url: arrival_detail_modify(arrival_detail_id), body: patch_data },
			);
		};


		export const arrival_detail_inventory_associate = (arrival_detail_id: number) => `${backend}/arrivals/arrival_details/${arrival_detail_id}/associate_inventory`;
		interface i_arrival_detail_inventory_associate {
			inventory_id: number;
			inventory_name: string;
			inventory_no?: string;
			inventory_serial_no?: string;
		};
		export async function api_arrival_detail_inventory_associate(arrival_detail_id: number, patch_data: i_arrival_detail_inventory_associate) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"inventory_id",
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
			);
			return await network.PATCH<Payload<Task.ArrivalDetail>>(
				{ url: arrival_detail_inventory_associate(arrival_detail_id), body: patch_data },
			);
		};


		export const arrival_worker_modify = (arrival_worker_id: number) => `${backend}/arrivals/arrival_workers/${arrival_worker_id}`;
		interface i_arrival_worker_modify {
			description?: string;
			first_name?: string;
			last_name?: string;
			user_id?: Task.User["user_id"];
		};
		export async function api_arrival_worker_modify(arrival_worker_id: number, patch_data: i_arrival_worker_modify) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "description", "first_name", "last_name", "user_id");
			return await network.PATCH<Payload<Task.ArrivalWorker>>(
				{ url: arrival_worker_modify(arrival_worker_id), body: patch_data },
			);
		};


		export const arrival_add = () => `${backend}/arrivals`;
		interface i_arrival_add {
			order_id: Task.Order["order_id"];
			planned_start_time: Date | string;
			arrival_workers?: Task.ArrivalWorker[];
			planned_no_of_workers?: number;
		};
		export async function api_arrival_add(post_data: i_arrival_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_workers",
				"order_id",
				"planned_no_of_workers",
				"planned_start_time",
			);
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: arrival_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_detail_add = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/arrival_details`;
		interface i_arrival_detail_add {
			arrival_detail_type_id: number;
			description?: string;
			element_cin?: string;
			element_currency?: string;
			element_id?: Task.Pricelist["element_id"];
			element_name?: string;
			element_price?: number;
			element_quantity?: number;
			element_type_id?: number;
			element_uom?: string;
			inventory_data?: string;
			inventory_id?: number;
			inventory_name?: string;
			inventory_no?: string;
			inventory_serial_no?: string;
			invoice_type_id?: number;
			lump_sum?: boolean;
			picture_url?: string;
			work_description?: string;
			work_description_id?: number;
		};
		export async function api_arrival_detail_add(arrival_id: number, post_data: i_arrival_detail_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_detail_type_id",
				"description",
				"element_cin",
				"element_currency",
				"element_id",
				"element_name",
				"element_price",
				"element_quantity",
				"element_type_id",
				"element_uom",
				"inventory_data",
				"inventory_id",
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
				"invoice_type_id",
				"lump_sum",
				"picture_url",
				"work_description",
				"work_description_id",
			);
			return await network.POST<Payload<Task.ArrivalDetail>>(
				{
					url: arrival_detail_add(arrival_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_search = () => `${backend}/arrivals/search`;
		export async function api_arrival_search(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Arrival[]>>(
				{
					url: arrival_search(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const arrival_calendar_search = () => `${backend}/arrivals/calendar/search`;
		export async function api_arrival_calendar_search(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload<Task.CalendarArrival[]>>(
				{ url: arrival_calendar_search(), body: search },
			);
		};


		export const arrival_start = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/start`;
		interface i_arrival_start {
			no_of_workers: number;
		};
		export async function api_arrival_start(arrival_id: number, post_data: i_arrival_start, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "no_of_workers");
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: arrival_start(arrival_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_end = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/end`;
		interface i_arrival_end {
			end_time?: Date | string;
		};
		export async function api_arrival_end(arrival_id: number, post_data: i_arrival_end, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "end_time");
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: arrival_end(arrival_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_end_new = (arrival_id: number) => `${backend}/arrivals/${arrival_id}/end/new`;
		interface i_arrival_end_new {
			end_time?: Date | string;
			start_time?: Date | string;
		};
		export async function api_arrival_end_new(arrival_id: number, post_data: i_arrival_end_new, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "end_time", "start_time");
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: arrival_end_new(arrival_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_emergency_add = () => `${backend}/arrivals/add_emergency_arrival`;
		interface i_arrival_emergency_add {
			arrival_workers: Task.ArrivalWorker[];
			end_time: Date | string;
			order_id: Task.Order["order_id"];
			start_time: Date | string;
		};
		export async function api_arrival_emergency_add(post_data: i_arrival_emergency_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "arrival_workers", "end_time", "order_id", "start_time");
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: arrival_emergency_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const arrival_admin_delete = (arrival_id: number) => `${backend}/arrivals/admin/${arrival_id}/delete`;
		interface i_arrival_admin_delete {
			reason: string;
		};
		export async function api_arrival_admin_delete(arrival_id: number, post_data: i_arrival_admin_delete) {
			post_data = { ...post_data };
			keep_props(post_data, "reason");
			return await network.POST<Payload>({ url: arrival_admin_delete(arrival_id), body: post_data });
		};


		export const upload_document = (arrival_id: number) => `${backend}/arrivals/document_upload/${arrival_id}`;
		export async function api_upload_document(arrival_id: number, file: File, expand_details = false, x_arrival_detail_type?: Task.ARRIVAL_DETAIL_TYPE) {
			return await network.POST<Payload<Task.ArrivalDetail>>(
				{
					url: upload_document(arrival_id),
					body: file,
					headers: {
						...network.Headers.expand(expand_details),
						...normalized_header("x_arrival_detail_type", x_arrival_detail_type)
					}
				},
			);
		};

	}

	export namespace auth {
		export const user_logout = () => `${backend}/auth/logout`;
		export async function api_user_logout() {
			return await network.GET<Payload>({ url: user_logout() });
		};


		export const admin_user_spoof = () => `${backend}/auth/login-spoof`;
		interface i_admin_user_spoof {
			user_id: number;
		};
		export async function api_admin_user_spoof(post_data: i_admin_user_spoof) {
			post_data = { ...post_data };
			keep_props(post_data, "user_id");
			return await network.POST<Payload<Task.User> & { auth_token: string }>(
				{ url: admin_user_spoof(), body: post_data },
			);
		};


		export const user_login = () => `${backend}/auth/login`;
		interface i_user_login {
			email: string;
			password: string;
		};
		export async function api_user_login(post_data: i_user_login) {
			post_data = { ...post_data };
			keep_props(post_data, "email", "password");
			return await network.POST<Payload<Task.User> & { auth_token: string }>(
				{ url: user_login(), body: post_data },
			);
		};


		export const can_register = () => `${backend}/auth/can_register`;
		interface i_can_register {
			key: string;
		};
		export async function api_can_register(post_data: i_can_register) {
			post_data = { ...post_data };
			keep_props(post_data, "key");
			return await network.POST<Payload<boolean>>({ url: can_register(), body: post_data });
		};


		export const user_register = () => `${backend}/auth/register`;
		interface i_user_register {
			key: Task.User["pass_key"];
			password: string;
		};
		export async function api_user_register(post_data: i_user_register) {
			post_data = { ...post_data };
			keep_props(post_data, "key", "password");
			return await network.POST<Payload>({ url: user_register(), body: post_data });
		};


		export const request_recovery_email = () => `${backend}/auth/request_recovery_email`;
		interface i_request_recovery_email {
			email: string;
		};
		export async function api_request_recovery_email(post_data: i_request_recovery_email) {
			post_data = { ...post_data };
			keep_props(post_data, "email");
			return await network.POST<Payload>({ url: request_recovery_email(), body: post_data });
		};

	}

	export namespace checklists {
		export const checklist_question_delete = (checklist_question_id: number) => `${backend}/checklists/questions/${checklist_question_id}`;
		export async function api_checklist_question_delete(checklist_question_id: number) {
			return await network.DELETE<Payload>(
				{ url: checklist_question_delete(checklist_question_id) },
			);
		};


		export const checklist_user_questions_get = () => `${backend}/checklists/user_questions`;
		export async function api_checklist_user_questions_get(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.CheckListAnswer[]>>(
				{
					url: checklist_user_questions_get(),
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const checklist_question_modify = (checklist_question_id: number) => `${backend}/checklists/questions/${checklist_question_id}`;
		interface i_checklist_question_modify {
			description: string;
			frequency: number;
			location_group_ids: number[];
			location_ids: number[];
			name: string;
			whier_id: number;
		};
		export async function api_checklist_question_modify(checklist_question_id: number, patch_data: i_checklist_question_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"description",
				"frequency",
				"location_group_ids",
				"location_ids",
				"name",
				"whier_id",
			);
			return await network.PATCH<Payload>(
				{ url: checklist_question_modify(checklist_question_id), body: patch_data },
			);
		};


		export const checklist_answer_modify = (checklist_answer_id: number) => `${backend}/checklists/answers/${checklist_answer_id}/answers`;
		interface i_checklist_answer_modify {
			checklist_ok: boolean;
			checklist_answer_details?: any[];
			checklist_answer_text?: string;
		};
		export async function api_checklist_answer_modify(checklist_answer_id: number, patch_data: i_checklist_answer_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"checklist_answer_details",
				"checklist_answer_text",
				"checklist_ok",
			);
			return await network.PATCH<Payload>(
				{ url: checklist_answer_modify(checklist_answer_id), body: patch_data },
			);
		};


		export const checklist_questions_search = () => `${backend}/checklists/questions/search`;
		export async function api_checklist_questions_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.CheckListQuestion[]>>(
				{
					url: checklist_questions_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const checklist_question_add = () => `${backend}/checklists/questions`;
		interface i_checklist_question_add {
			description: string;
			frequency: number;
			location_group_ids: number[];
			location_ids: number[];
			name: string;
			whier_id: Task.WorkHierarchy["whier_id"];
		};
		export async function api_checklist_question_add(post_data: i_checklist_question_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"description",
				"frequency",
				"location_group_ids",
				"location_ids",
				"name",
				"whier_id",
			);
			return await network.POST<Payload>({ url: checklist_question_add(), body: post_data });
		};

	}

	export namespace comments {
		export const comment_delete = (comment_id: number) => `${backend}/comments/${comment_id}`;
		export async function api_comment_delete(comment_id: number) {
			return await network.DELETE<Payload>({ url: comment_delete(comment_id) });
		};


		export const comments_by_entity_value_get_all = (entity_id: Task.ENTITY, entity_value: number) => `${backend}/comments/${entity_id}/${entity_value}`;
		export async function api_comments_by_entity_value_get_all(entity_id: Task.ENTITY, entity_value: number) {
			return await network.GET<Payload<Task.Comment[]>>(
				{ url: comments_by_entity_value_get_all(entity_id, entity_value) },
			);
		};


		export const comment_get_single = (comment_id: number) => `${backend}/comments/${comment_id}`;
		export async function api_comment_get_single(comment_id: number) {
			return await network.GET<Payload<Task.Comment>>({ url: comment_get_single(comment_id) });
		};


		export const comment_modify = (comment_id: number) => `${backend}/comments/${comment_id}`;
		interface i_comment_modify {
			comment?: Task.Comment["comment"];
		};
		export async function api_comment_modify(comment_id: number, patch_data: i_comment_modify) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "comment");
			return await network.PATCH<Payload<Task.Comment>>(
				{ url: comment_modify(comment_id), body: patch_data },
			);
		};


		export const comment_add = () => `${backend}/comments`;
		interface i_comment_add {
			comment: Task.Comment["comment"];
			entity_id: Task.Comment["entity_id"];
			entity_value: Task.Comment["entity_value"];
		};
		export async function api_comment_add(post_data: i_comment_add) {
			post_data = { ...post_data };
			keep_props(post_data, "comment", "entity_id", "entity_value");
			return await network.POST<Payload<Task.Comment>>({ url: comment_add(), body: post_data });
		};

	}

	export namespace companies {
		export const company_get_single = (company_id: number) => `${backend}/companies/${company_id}`;
		export async function api_company_get_single(company_id: number) {
			return await network.GET<Payload<Task.Company>>({ url: company_get_single(company_id) });
		};


		export const company_modify = (company_id: number) => `${backend}/companies/${company_id}`;
		interface i_company_modify {
			address?: string;
			city?: string;
			contact_name?: string;
			contact_phone?: string;
			country?: string;
			description?: string;
			identification_no?: string;
			name?: string;
			postal_code?: string;
			tax_number?: string;
		};
		export async function api_company_modify(company_id: number, patch_data: i_company_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"address",
				"city",
				"contact_name",
				"contact_phone",
				"country",
				"description",
				"identification_no",
				"name",
				"postal_code",
				"tax_number",
			);
			return await network.PATCH<Payload<Task.Company>>(
				{ url: company_modify(company_id), body: patch_data },
			);
		};


		export const company_search = () => `${backend}/companies/search`;
		export async function api_company_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Company[]>>(
				{
					url: company_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace financial_details {
		export const monthly_invoice_for_company = () => `${backend}/financial_details/monthly`;
		interface i_monthly_invoice_for_company {
			month: number;
			year: number;
		};
		export async function api_monthly_invoice_for_company(post_data: i_monthly_invoice_for_company) {
			post_data = { ...post_data };
			keep_props(post_data, "month", "year");
			return await network.POST<Payload>({ url: monthly_invoice_for_company(), body: post_data });
		};

	}

	export namespace health_check {
		export const health_check = () => `${backend}/task`;
		export async function api_health_check() {
			return await network.GET<Payload>({ url: health_check() });
		};


		export const health_check_no_2 = () => `${backend}/`;
		export async function api_health_check_no_2() {
			return await network.GET<Payload>({ url: health_check_no_2() });
		};


		export const get_doc = () => `${backend}/doc`;
		export async function api_get_doc() {
			return await network.GET<Payload>({ url: get_doc() });
		};


		export const get_debug = () => `${backend}/debug`;
		export async function api_get_debug() {
			return await network.GET<Payload>({ url: get_debug() });
		};


		export const get_mailboxes = () => `${backend}/mailboxes`;
		export async function api_get_mailboxes() {
			return await network.GET<Payload>({ url: get_mailboxes() });
		};


		export const get_mailbox = (mailbox: string) => `${backend}/mailboxes/${mailbox}`;
		export async function api_get_mailbox(mailbox: string) {
			return await network.GET<Payload>({ url: get_mailbox(mailbox) });
		};


		export const get_mailbox_email = (mailbox: string, idx: number) => `${backend}/mailboxes/${mailbox}/${idx}`;
		export async function api_get_mailbox_email(mailbox: string, idx: number) {
			return await network.GET<Payload>({ url: get_mailbox_email(mailbox, idx) });
		};

	}

	export namespace integrations {
		export const get_mproces_order = (order_id: number, supplier_cin: string) => `${backend}/integrations/mproces/${order_id}/${supplier_cin}`;
		export async function api_get_mproces_order(order_id: number, supplier_cin: string) {
			return await network.GET<Payload>({ url: get_mproces_order(order_id, supplier_cin) });
		};

	}

	export namespace jobs {
		export const are_jobs_running = () => `${backend}/jobs/running`;
		export async function api_are_jobs_running() {
			return await network.GET<Payload<boolean>>({ url: are_jobs_running() });
		};


		export const run_jobs = () => `${backend}/jobs`;
		export async function api_run_jobs() {
			return await network.POST<Payload>({ url: run_jobs(), body: undefined });
		};

	}

	export namespace kam_data {
		export const kam_inventory_images_get = (inventory_id: number) => `${backend}/kam_data/inventory/${inventory_id}/images`;
		export async function api_kam_inventory_images_get(inventory_id: number) {
			return await network.GET<Payload>({ url: kam_inventory_images_get(inventory_id) });
		};


		export const kam_inventory_search = () => `${backend}/kam_data/inventory/search`;
		interface i_kam_inventory_search {
			location_cin: string;
			inventory_name?: string;
			inventory_no?: string;
			inventory_serial_no?: string;
		};
		export async function api_kam_inventory_search(post_data: i_kam_inventory_search, paginator: Paginator = {}) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
				"location_cin",
			);
			return await network.POST<Payload>(
				{
					url: kam_inventory_search(),
					body: post_data,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace location_groups {
		export const location_group_delete = (location_group_id: number) => `${backend}/location_groups/${location_group_id}`;
		export async function api_location_group_delete(location_group_id: number) {
			return await network.DELETE<Payload>({ url: location_group_delete(location_group_id) });
		};


		export const location_group_get_all = () => `${backend}/location_groups`;
		export async function api_location_group_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.LocationGroup[]>>(
				{
					url: location_group_get_all(),
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const location_group_get_single = (location_group_id: number) => `${backend}/location_groups/${location_group_id}`;
		export async function api_location_group_get_single(location_group_id: number) {
			return await network.GET<Payload<Task.LocationGroup>>(
				{ url: location_group_get_single(location_group_id) },
			);
		};


		export const location_group_modify = (location_group_id: number) => `${backend}/location_groups/${location_group_id}`;
		interface i_location_group_modify {
			locations?: Task.Location[];
			name?: Task.LocationGroup["name"];
			nfc?: boolean;
			user_ids?: number[];
		};
		export async function api_location_group_modify(location_group_id: number, patch_data: i_location_group_modify) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "locations", "name", "nfc", "user_ids");
			return await network.PATCH<Payload<Task.LocationGroup>>(
				{ url: location_group_modify(location_group_id), body: patch_data },
			);
		};


		export const location_group_has_nfc = (location_group_id: number) => `${backend}/location_groups/${location_group_id}/has_nfc`;
		interface i_location_group_has_nfc {
			nfc: boolean;
		};
		export async function api_location_group_has_nfc(location_group_id: number, patch_data: i_location_group_has_nfc) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "nfc");
			return await network.PATCH<Payload>(
				{ url: location_group_has_nfc(location_group_id), body: patch_data },
			);
		};


		export const location_group_add = () => `${backend}/location_groups`;
		interface i_location_group_add {
			name: Task.LocationGroup["name"];
			locations?: Task.Location[];
			nfc?: boolean;
			user_ids?: number[];
		};
		export async function api_location_group_add(post_data: i_location_group_add) {
			post_data = { ...post_data };
			keep_props(post_data, "locations", "name", "nfc", "user_ids");
			return await network.POST<Payload<Task.LocationGroup>>(
				{ url: location_group_add(), body: post_data },
			);
		};


		export const location_group_locations_assign = (location_group_id: number) => `${backend}/location_groups/${location_group_id}/assign_locations`;
		interface i_location_group_locations_assign {
			locations: Task.Location[];
		};
		export async function api_location_group_locations_assign(location_group_id: number, post_data: i_location_group_locations_assign) {
			post_data = { ...post_data };
			keep_props(post_data, "locations");
			return await network.POST<Payload>(
				{ url: location_group_locations_assign(location_group_id), body: post_data },
			);
		};


		export const location_group_locations_unassign = (location_group_id: number) => `${backend}/location_groups/${location_group_id}/unassign_locations`;
		interface i_location_group_locations_unassign {
			locations: Task.Location[];
		};
		export async function api_location_group_locations_unassign(location_group_id: number, post_data: i_location_group_locations_unassign) {
			post_data = { ...post_data };
			keep_props(post_data, "locations");
			return await network.POST<Payload>(
				{ url: location_group_locations_unassign(location_group_id), body: post_data },
			);
		};


		export const location_groups_search = () => `${backend}/location_groups/search`;
		export async function api_location_groups_search(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.LocationGroup[]>>(
				{
					url: location_groups_search(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const location_groups_search_visible = () => `${backend}/location_groups/search/visible`;
		export async function api_location_groups_search_visible(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.LocationGroup[]>>(
				{
					url: location_groups_search_visible(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const location_groups_search_responsible = () => `${backend}/location_groups/search/responsible`;
		export async function api_location_groups_search_responsible(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.LocationGroup[]>>(
				{
					url: location_groups_search_responsible(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};

	}

	export namespace locations {
		export const location_delete = (location_id: number) => `${backend}/locations/${location_id}`;
		export async function api_location_delete(location_id: number) {
			return await network.DELETE<Payload>({ url: location_delete(location_id) });
		};


		export const location_get_all = () => `${backend}/locations`;
		export async function api_location_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.Location[]>>(
				{ url: location_get_all(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const location_get_single = (location_id: number) => `${backend}/locations/${location_id}`;
		export async function api_location_get_single(location_id: number) {
			return await network.GET<Payload<Task.Location>>({ url: location_get_single(location_id) });
		};


		export const location_modify = (location_id: number) => `${backend}/locations/${location_id}`;
		interface i_location_modify {
			address?: string;
			area?: string;
			automatically_approve_tickets?: boolean;
			cin?: string;
			city?: string;
			contact_phone?: string;
			country?: string;
			description?: string;
			district?: string;
			email?: string;
			fax?: string;
			format?: string;
			gross_area?: number;
			latitude?: Task.Location["latitude"];
			location_type_id?: Task.Location["location_type_id"];
			longitude?: Task.Location["longitude"];
			name?: Task.Location["name"];
			net_area?: number;
			nfc?: boolean;
			parent_id?: Task.Location["parent_id"];
			postal_code?: string;
			region?: string;
			short_name?: string;
			user_ids?: number[];
		};
		export async function api_location_modify(location_id: number, patch_data: i_location_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"address",
				"area",
				"automatically_approve_tickets",
				"cin",
				"city",
				"contact_phone",
				"country",
				"description",
				"district",
				"email",
				"fax",
				"format",
				"gross_area",
				"latitude",
				"location_type_id",
				"longitude",
				"name",
				"net_area",
				"nfc",
				"parent_id",
				"postal_code",
				"region",
				"short_name",
				"user_ids",
			);
			return await network.PATCH<Payload<Task.Location>>(
				{ url: location_modify(location_id), body: patch_data },
			);
		};


		export const location_add = () => `${backend}/locations`;
		interface i_location_add {
			location_type_id: number;
			name: string;
			address?: string;
			area?: string;
			automatically_approve_tickets?: boolean;
			cin?: string;
			city?: string;
			contact_phone?: string;
			country?: string;
			description?: string;
			district?: string;
			email?: string;
			fax?: string;
			format?: string;
			gross_area?: number;
			latitude?: Task.Location["latitude"];
			longitude?: Task.Location["longitude"];
			net_area?: number;
			nfc?: boolean;
			parent_id?: Task.Location["parent_id"];
			postal_code?: string;
			region?: string;
			short_name?: string;
			user_ids?: number[];
			work_group_ids?: number[];
		};
		export async function api_location_add(post_data: i_location_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"address",
				"area",
				"automatically_approve_tickets",
				"cin",
				"city",
				"contact_phone",
				"country",
				"description",
				"district",
				"email",
				"fax",
				"format",
				"gross_area",
				"latitude",
				"location_type_id",
				"longitude",
				"name",
				"net_area",
				"nfc",
				"parent_id",
				"postal_code",
				"region",
				"short_name",
				"user_ids",
				"work_group_ids",
			);
			return await network.POST<Payload<Task.Location>>({ url: location_add(), body: post_data });
		};


		export const location_bulk_add = () => `${backend}/locations/upload`;
		export async function api_location_bulk_add() {
			return await network.POST<Payload<Task.Location[]>>({ url: location_bulk_add(), body: undefined });
		};


		export const location_search = () => `${backend}/locations/search`;
		export async function api_location_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Location[]>>(
				{
					url: location_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const location_search_visible = () => `${backend}/locations/search/visible`;
		export async function api_location_search_visible(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Location[]>>(
				{
					url: location_search_visible(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const location_search_responsible = () => `${backend}/locations/search/responsible`;
		export async function api_location_search_responsible(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Location[]>>(
				{
					url: location_search_responsible(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace nfc {
		export const generate_new_location_nfc_key = (location_id: number) => `${backend}/nfc/location/${location_id}/new`;
		export async function api_generate_new_location_nfc_key(location_id: number) {
			return await network.GET<Payload>({ url: generate_new_location_nfc_key(location_id) });
		};


		export const generate_new_location_group_nfc_key = (location_group_id: number) => `${backend}/nfc/location_group/${location_group_id}/new`;
		export async function api_generate_new_location_group_nfc_key(location_group_id: number) {
			return await network.GET<Payload>(
				{ url: generate_new_location_group_nfc_key(location_group_id) },
			);
		};


		export const activate_location_nfc = (location_id: number) => `${backend}/nfc/location/${location_id}/activate`;
		interface i_activate_location_nfc {
			nfc_key: string;
		};
		export async function api_activate_location_nfc(location_id: number, patch_data: i_activate_location_nfc) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "nfc_key");
			return await network.PATCH<Payload>(
				{ url: activate_location_nfc(location_id), body: patch_data },
			);
		};


		export const activate_location_group_nfc = (location_group_id: number) => `${backend}/nfc/location_group/${location_group_id}/activate`;
		interface i_activate_location_group_nfc {
			nfc_key: string;
		};
		export async function api_activate_location_group_nfc(location_group_id: number, patch_data: i_activate_location_group_nfc) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "nfc_key");
			return await network.PATCH<Payload>(
				{ url: activate_location_group_nfc(location_group_id), body: patch_data },
			);
		};


		export const nfc_scan = (arrival_id: number) => `${backend}/nfc/${arrival_id}`;
		interface i_nfc_scan {
			nfc_key: string;
			arrival_workers?: Task.ArrivalWorker[];
			no_of_workers?: number;
			planned_no_of_workers?: number;
			planned_start_time?: Date | string;
		};
		export async function api_nfc_scan(arrival_id: number, post_data: i_nfc_scan, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_workers",
				"nfc_key",
				"no_of_workers",
				"planned_no_of_workers",
				"planned_start_time",
			);
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: nfc_scan(arrival_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};

	}

	export namespace orders {
		export const order_delete = (order_id: number) => `${backend}/orders/${order_id}`;
		export async function api_order_delete(order_id: number) {
			return await network.DELETE<Payload>({ url: order_delete(order_id) });
		};


		export const order_detail_delete = (order_detail_id: number) => `${backend}/orders/order_details/${order_detail_id}`;
		export async function api_order_detail_delete(order_detail_id: number) {
			return await network.DELETE<Payload>({ url: order_detail_delete(order_detail_id) });
		};


		export const order_get_all = () => `${backend}/orders`;
		export async function api_order_get_all(paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Order[]>>(
				{
					url: order_get_all(),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const order_get_single = (order_id: number) => `${backend}/orders/${order_id}`;
		export async function api_order_get_single(order_id: number, expand_details = true) {
			return await network.GET<Payload<Task.Order>>(
				{
					url: order_get_single(order_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_financial_details_get = (order_id: number) => `${backend}/orders/${order_id}/financial_details`;
		export async function api_order_financial_details_get(order_id: number) {
			return await network.GET<Payload<Task.ArrivalDetail[]>>(
				{ url: order_financial_details_get(order_id) },
			);
		};


		export const order_tickets_get_all = (order_id: number) => `${backend}/orders/${order_id}/tickets`;
		export async function api_order_tickets_get_all(order_id: number, paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Ticket[]>>(
				{
					url: order_tickets_get_all(order_id),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const order_arrivals_get_all = (order_id: number) => `${backend}/orders/${order_id}/arrivals`;
		export async function api_order_arrivals_get_all(order_id: number, paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Arrival[]>>(
				{
					url: order_arrivals_get_all(order_id),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const order_arrivals_set_all_finished = (order_id: number) => `${backend}/orders/${order_id}/arrivals_finished`;
		export async function api_order_arrivals_set_all_finished(order_id: number, expand_details = false) {
			return await network.GET<Payload<Task.Order>>(
				{
					url: order_arrivals_set_all_finished(order_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_invoice_proposal_get = (order_id: number) => `${backend}/orders/${order_id}/invoice_proposal`;
		export async function api_order_invoice_proposal_get(order_id: number, download: boolean) {
			return await network.GET<Blob>({ url: order_invoice_proposal_get(order_id), download });
		};


		export const order_invoice_proposal_without_cost_get = (order_id: number) => `${backend}/orders/${order_id}/invoice_proposal_without_cost`;
		export async function api_order_invoice_proposal_without_cost_get(order_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: order_invoice_proposal_without_cost_get(order_id), download },
			);
		};


		export const order_invoice_proposal_with_total_cost_get = (order_id: number) => `${backend}/orders/${order_id}/invoice_proposal_with_total_cost`;
		export async function api_order_invoice_proposal_with_total_cost_get(order_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: order_invoice_proposal_with_total_cost_get(order_id), download },
			);
		};


		export const order_history = (order_id: number) => `${backend}/orders/${order_id}/order_history`;
		export async function api_order_history(order_id: number, download: boolean) {
			return await network.GET<Blob>({ url: order_history(order_id), download });
		};


		export const arrivals_work_order_snapshot_get = (order_id: number) => `${backend}/orders/${order_id}/work_order_snapshot`;
		export async function api_arrivals_work_order_snapshot_get(order_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: arrivals_work_order_snapshot_get(order_id), download },
			);
		};


		export const arrivals_work_order__get = (order_id: number) => `${backend}/orders/${order_id}/work_order`;
		export async function api_arrivals_work_order__get(order_id: number, download: boolean) {
			return await network.GET<Blob>({ url: arrivals_work_order__get(order_id), download });
		};


		export const order_detail_inventory_get = (order_id: number) => `${backend}/orders/${order_id}/detail_inventory`;
		export async function api_order_detail_inventory_get(order_id: number) {
			return await network.GET<Payload<any[]>>({ url: order_detail_inventory_get(order_id) });
		};


		export const get_document_by_order_detail_id = (order_detail_id: number) => `${backend}/orders/order_details/${order_detail_id}`;
		export async function api_get_document_by_order_detail_id(order_detail_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: get_document_by_order_detail_id(order_detail_id), download },
			);
		};


		export const order_supplier_reasign = (order_id: number) => `${backend}/orders/${order_id}/assign_new_supplier`;
		interface i_order_supplier_reasign {
			supplier_id: Task.Company["company_id"];
			arrival_worker?: Task.User;
			arrival_worker_id?: number;
		};
		export async function api_order_supplier_reasign(order_id: number, patch_data: i_order_supplier_reasign, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "arrival_worker", "arrival_worker_id", "supplier_id");
			return await network.PATCH<Payload<Task.Order>>(
				{
					url: order_supplier_reasign(order_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_admin_revert = (order_id: number) => `${backend}/orders/${order_id}/admin`;
		interface i_order_admin_revert {
			reason: string;
			status_id: number;
		};
		export async function api_order_admin_revert(order_id: number, patch_data: i_order_admin_revert) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "reason", "status_id");
			return await network.PATCH<Payload>({ url: order_admin_revert(order_id), body: patch_data });
		};


		export const order_details_admin_revert = (order_id: number) => `${backend}/orders/${order_id}/admin/revert_items`;
		interface i_order_details_admin_revert {
			arrival_detail_ids: number[];
		};
		export async function api_order_details_admin_revert(order_id: number, patch_data: i_order_details_admin_revert) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "arrival_detail_ids");
			return await network.PATCH<Payload>(
				{ url: order_details_admin_revert(order_id), body: patch_data },
			);
		};


		export const order_modify = (order_id: number) => `${backend}/orders/${order_id}`;
		interface i_order_modify {
			comment?: string;
			complaint?: boolean;
			cost_estimate?: number;
			inspection?: boolean;
			insurance?: boolean;
			investment_type?: number;
			lease?: boolean;
			location_id?: Task.Location["location_id"];
			order_details?: any[];
			preinvoice?: boolean;
			title?: string;
			whier_id?: Task.WorkHierarchy["whier_id"];
		};
		export async function api_order_modify(order_id: number, patch_data: i_order_modify, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"comment",
				"complaint",
				"cost_estimate",
				"inspection",
				"insurance",
				"investment_type",
				"lease",
				"location_id",
				"order_details",
				"preinvoice",
				"title",
				"whier_id",
			);
			return await network.PATCH<Payload<Task.Order>>(
				{
					url: order_modify(order_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_detail_modify = (order_detail_id: number) => `${backend}/orders/order_details/${order_detail_id}`;
		interface i_order_detail_modify {
			description?: Task.OrderDetail["description"];
			inventory_id?: number;
			inventory_name?: string;
			inventory_no?: string;
			inventory_serial_no?: string;
			order_detail_type_id?: number;
			picture_url?: Task.OrderDetail["picture_url"];
			unit_of_measure_id?: number;
		};
		export async function api_order_detail_modify(order_detail_id: number, patch_data: i_order_detail_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"description",
				"inventory_id",
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
				"order_detail_type_id",
				"picture_url",
				"unit_of_measure_id",
			);
			return await network.PATCH<Payload<Task.OrderDetail>>(
				{ url: order_detail_modify(order_detail_id), body: patch_data },
			);
		};


		export const order_report_get = () => `${backend}/orders/report`;
		export async function api_order_report_get(search: Search_Params, download: boolean) {
			normalize_sort(search);
			return await network.POST<Blob>({ url: order_report_get(), body: search, download });
		};


		export const supplier_order_report_get = () => `${backend}/orders/supplier/report`;
		export async function api_supplier_order_report_get(search: Search_Params, download: boolean) {
			normalize_sort(search);
			return await network.POST<Blob>({ url: supplier_order_report_get(), body: search, download });
		};


		export const order_send_on_review = (order_id: number) => `${backend}/orders/${order_id}/send_on_review`;
		interface i_order_send_on_review {
			arrival_details: any[];
		};
		export async function api_order_send_on_review(order_id: number, post_data: i_order_send_on_review) {
			post_data = { ...post_data };
			keep_props(post_data, "arrival_details");
			return await network.POST<Payload<Task.ArrivalDetail[]>>(
				{ url: order_send_on_review(order_id), body: post_data },
			);
		};


		export const order_reject = (order_id: number) => `${backend}/orders/${order_id}/reject`;
		interface i_order_reject {
			comment: string;
		};
		export async function api_order_reject(order_id: number, post_data: i_order_reject, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload<Task.Order>>(
				{
					url: order_reject(order_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_invoiced = (order_id: number) => `${backend}/orders/${order_id}/set_invoiced`;
		interface i_order_invoiced {
			invoice_no?: string;
		};
		export async function api_order_invoiced(order_id: number, post_data: i_order_invoiced, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "invoice_no");
			return await network.POST<Payload<Task.Order>>(
				{
					url: order_invoiced(order_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_add = () => `${backend}/orders`;
		interface i_order_add {
			location_id: Task.Location["location_id"];
			order_details: any[];
			supplier_id: Task.Company["company_id"];
			title: string;
			whier_id: Task.WorkHierarchy["whier_id"];
			arrival_worker?: Task.User;
			arrival_worker_id?: number;
			cc_emails?: any[];
			complaint?: boolean;
			cost_estimate?: number;
			cost_estimate_currency?: string;
			in_warranty_period?: boolean;
			inspection?: boolean;
			insurance?: boolean;
			investment_type?: number;
			lease?: boolean;
			preinvoice?: boolean;
			ticket_ids?: number[];
		};
		export async function api_order_add(post_data: i_order_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_worker",
				"arrival_worker_id",
				"cc_emails",
				"complaint",
				"cost_estimate",
				"cost_estimate_currency",
				"in_warranty_period",
				"inspection",
				"insurance",
				"investment_type",
				"lease",
				"location_id",
				"order_details",
				"preinvoice",
				"supplier_id",
				"ticket_ids",
				"title",
				"whier_id",
			);
			return await network.POST<Payload<Task.Order>>(
				{
					url: order_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const order_search = () => `${backend}/orders/search`;
		export async function api_order_search(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Order[]>>(
				{
					url: order_search(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const order_calendar_search = () => `${backend}/orders/calendar/search`;
		export async function api_order_calendar_search(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload<Task.CalendarOrder[]>>(
				{ url: order_calendar_search(), body: search },
			);
		};


		export const order_tickets_assign = (order_id: number) => `${backend}/orders/${order_id}/assign_tickets`;
		interface i_order_tickets_assign {
			ticket_ids: number[];
		};
		export async function api_order_tickets_assign(order_id: number, post_data: i_order_tickets_assign) {
			post_data = { ...post_data };
			keep_props(post_data, "ticket_ids");
			return await network.POST<Payload>({ url: order_tickets_assign(order_id), body: post_data });
		};


		export const order_ticekts_unassign = (order_id: number) => `${backend}/orders/${order_id}/unassign_tickets`;
		interface i_order_ticekts_unassign {
			ticket_ids: number[];
		};
		export async function api_order_ticekts_unassign(order_id: number, post_data: i_order_ticekts_unassign) {
			post_data = { ...post_data };
			keep_props(post_data, "ticket_ids");
			return await network.POST<Payload>({ url: order_ticekts_unassign(order_id), body: post_data });
		};


		export const order_detail_inventory_set = (order_id: number) => `${backend}/orders/${order_id}/detail_inventory`;
		interface i_order_detail_inventory_set {
			detail_inventory: any[];
		};
		export async function api_order_detail_inventory_set(order_id: number, post_data: i_order_detail_inventory_set) {
			post_data = { ...post_data };
			keep_props(post_data, "detail_inventory");
			return await network.POST<Payload>(
				{ url: order_detail_inventory_set(order_id), body: post_data },
			);
		};


		export const send_order_internal_approval_mail = (order_id: number) => `${backend}/orders/${order_id}/send_internal_approval_mail`;
		export async function api_send_order_internal_approval_mail(order_id: number) {
			return await network.POST<Payload>(
				{ url: send_order_internal_approval_mail(order_id), body: undefined },
			);
		};


		export const order_admin_delete = (order_id: number) => `${backend}/orders/${order_id}/admin/delete`;
		interface i_order_admin_delete {
			reason: string;
		};
		export async function api_order_admin_delete(order_id: number, post_data: i_order_admin_delete) {
			post_data = { ...post_data };
			keep_props(post_data, "reason");
			return await network.POST<Payload>({ url: order_admin_delete(order_id), body: post_data });
		};


		export const upload_document = (order_id: number) => `${backend}/orders/document_upload/${order_id}`;
		export async function api_upload_document(order_id: number, file: File, expand_details = false, x_order_detail_type?: Task.ORDER_DETAIL_TYPE) {
			return await network.POST<Payload<Task.OrderDetail>>(
				{
					url: upload_document(order_id),
					body: file,
					headers: {
						...network.Headers.expand(expand_details),
						...normalized_header("x_order_detail_type", x_order_detail_type)
					}
				},
			);
		};

	}

	export namespace pricelists {
		export const pricelist_element_delete = (element_id: number) => `${backend}/pricelists/${element_id}`;
		export async function api_pricelist_element_delete(element_id: number) {
			return await network.DELETE<Payload>({ url: pricelist_element_delete(element_id) });
		};


		export const pricelist_get_whole = () => `${backend}/pricelists`;
		export async function api_pricelist_get_whole() {
			return await network.GET<Payload<Task.Pricelist[]>>({ url: pricelist_get_whole() });
		};


		export const pricelist_element_get_single = (element_id: number) => `${backend}/pricelists/${element_id}`;
		export async function api_pricelist_element_get_single(element_id: number) {
			return await network.GET<Payload<Task.Pricelist>>(
				{ url: pricelist_element_get_single(element_id) },
			);
		};


		export const pricelist_xlsx_generate = (supplier_id: number) => `${backend}/pricelists/download_for_editing/${supplier_id}?nocache=${Date.now()}`;
		export async function api_pricelist_xlsx_generate(supplier_id: number, download: boolean) {
			return await network.GET<Blob>({ url: pricelist_xlsx_generate(supplier_id), download });
		};


		export const pricelist_element_modify = (element_id: number) => `${backend}/pricelists/${element_id}`;
		interface i_pricelist_element_modify {
			cin?: string;
			currency?: string;
			element_type_id?: number;
			lump_sum?: boolean;
			name?: string;
			price?: number;
			unit_of_measure?: string;
			warranty_period?: number;
			work_hour_type_id?: Task.WORK_HOUR_TYPE;
		};
		export async function api_pricelist_element_modify(element_id: number, patch_data: i_pricelist_element_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"cin",
				"currency",
				"element_type_id",
				"lump_sum",
				"name",
				"price",
				"unit_of_measure",
				"warranty_period",
				"work_hour_type_id",
			);
			return await network.PATCH<Payload<Task.Pricelist>>(
				{ url: pricelist_element_modify(element_id), body: patch_data },
			);
		};


		export const pricelist_search_groups = () => `${backend}/pricelists/groups`;
		interface i_pricelist_search_groups {
			search?: string;
		};
		export async function api_pricelist_search_groups(post_data: i_pricelist_search_groups) {
			post_data = { ...post_data };
			keep_props(post_data, "search");
			return await network.POST<Payload<string[]>>({ url: pricelist_search_groups(), body: post_data });
		};


		export const pricelist_search_work_groups = () => `${backend}/pricelists/work_groups`;
		interface i_pricelist_search_work_groups {
			filter?: boolean;
			search?: string;
		};
		export async function api_pricelist_search_work_groups(post_data: i_pricelist_search_work_groups) {
			post_data = { ...post_data };
			keep_props(post_data, "filter", "search");
			return await network.POST<Payload<Task.WorkDescription[]>>(
				{ url: pricelist_search_work_groups(), body: post_data },
			);
		};


		export const pricelist_element_add = () => `${backend}/pricelists`;
		interface i_pricelist_element_add {
			cin: string;
			currency: string;
			element_type_id: number;
			name: string;
			price: number;
			company_id?: Task.Company["company_id"];
			lump_sum?: boolean;
			unit_of_measure?: string;
			warranty_period?: number;
			work_hour_type_id?: Task.WORK_HOUR_TYPE;
		};
		export async function api_pricelist_element_add(post_data: i_pricelist_element_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"cin",
				"company_id",
				"currency",
				"element_type_id",
				"lump_sum",
				"name",
				"price",
				"unit_of_measure",
				"warranty_period",
				"work_hour_type_id",
			);
			return await network.POST<Payload<Task.Pricelist>>(
				{ url: pricelist_element_add(), body: post_data },
			);
		};


		export const pricelist_search = () => `${backend}/pricelists/search`;
		export async function api_pricelist_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Pricelist[]>>(
				{
					url: pricelist_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const upload_edited_pricelist = (supplier_id: number) => `${backend}/pricelists/upload/${supplier_id}`;
		export async function api_upload_edited_pricelist(supplier_id: number, file: File) {
			return await network.POST<Payload<string>>(
				{ url: upload_edited_pricelist(supplier_id), body: file },
			);
		};


		export const list_pricelists_edits = () => `${backend}/pricelists/edits`;
		export async function api_list_pricelists_edits(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.PricelistEdit[]>>(
				{
					url: list_pricelists_edits(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace qr_codes {
		export const qr_code_scan = (hash: string) => `${backend}/qr_code/${hash}`;
		interface i_qr_code_scan {
			arrival_workers?: Task.ArrivalWorker[];
			no_of_workers?: number;
			planned_no_of_workers?: number;
			planned_start_time?: Date | string;
		};
		export async function api_qr_code_scan(hash: string, post_data: i_qr_code_scan, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_workers",
				"no_of_workers",
				"planned_no_of_workers",
				"planned_start_time",
			);
			return await network.POST<Payload<Task.Arrival>>(
				{
					url: qr_code_scan(hash),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};

	}

	export namespace questionnaires {
		export const questionnaire_delete = (questionnaire_id: number) => `${backend}/questionnaires/${questionnaire_id}`;
		export async function api_questionnaire_delete(questionnaire_id: number) {
			return await network.DELETE<Payload>({ url: questionnaire_delete(questionnaire_id) });
		};


		export const questionnaire_get_all = () => `${backend}/questionnaires`;
		export async function api_questionnaire_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.Questionnaire[]>>(
				{ url: questionnaire_get_all(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const questionnaire_get_single = (questionnaire_id: number) => `${backend}/questionnaires/${questionnaire_id}`;
		export async function api_questionnaire_get_single(questionnaire_id: number) {
			return await network.GET<Payload<Task.Questionnaire>>(
				{ url: questionnaire_get_single(questionnaire_id) },
			);
		};


		export const questionnaire_modify = (questionnaire_id: number) => `${backend}/questionnaires/${questionnaire_id}`;
		interface i_questionnaire_modify {
			component_type_id?: number;
			group?: Task.Questionnaire["group"];
			max_images?: number;
			min_images?: number;
			option?: Task.Questionnaire["option"];
			question?: Task.Questionnaire["question"];
			whier_id?: Task.WorkHierarchy["whier_id"];
		};
		export async function api_questionnaire_modify(questionnaire_id: number, patch_data: i_questionnaire_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"component_type_id",
				"group",
				"max_images",
				"min_images",
				"option",
				"question",
				"whier_id",
			);
			return await network.PATCH<Payload<Task.Questionnaire>>(
				{ url: questionnaire_modify(questionnaire_id), body: patch_data },
			);
		};


		export const questionnaires_modify_alt = () => `${backend}/questionnaires`;
		export async function api_questionnaires_modify_alt() {
			return await network.PATCH<Payload<Task.Questionnaire[]>>(
				{ url: questionnaires_modify_alt(), body: undefined },
			);
		};


		export const questionnaire_add = () => `${backend}/questionnaires`;
		interface i_questionnaire_add {
			component_type_id: number;
			group: Task.Questionnaire["group"];
			question: Task.Questionnaire["question"];
			max_images?: number;
			min_images?: number;
			option?: Task.Questionnaire["option"];
			whier_id?: Task.WorkHierarchy["whier_id"];
		};
		export async function api_questionnaire_add(post_data: i_questionnaire_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"component_type_id",
				"group",
				"max_images",
				"min_images",
				"option",
				"question",
				"whier_id",
			);
			return await network.POST<Payload<Task.Questionnaire>>(
				{ url: questionnaire_add(), body: post_data },
			);
		};


		export const questionnaire_add_alt = () => `${backend}/questionnaires/alt`;
		interface i_questionnaire_add_alt {
			component_type_id: number;
			group: Task.Questionnaire["group"];
			options: any[];
			question: Task.Questionnaire["question"];
			whier_id: Task.WorkHierarchy["whier_id"];
		};
		export async function api_questionnaire_add_alt(post_data: i_questionnaire_add_alt) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"component_type_id",
				"group",
				"options",
				"question",
				"whier_id",
			);
			return await network.POST<Payload<Task.Questionnaire[]>>(
				{ url: questionnaire_add_alt(), body: post_data },
			);
		};

	}

	export namespace reports {
		export const inventory_by_costs_report_get = () => `${backend}/reports/inventory_by_costs`;
		export async function api_inventory_by_costs_report_get(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload>({ url: inventory_by_costs_report_get(), body: search });
		};


		export const pricelist_elements_report_get = () => `${backend}/reports/pricelist_elements`;
		export async function api_pricelist_elements_report_get(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload>({ url: pricelist_elements_report_get(), body: search });
		};


		export const monthly_invoice_proposal_get = () => `${backend}/reports/monthly_invoice_proposal`;
		interface i_monthly_invoice_proposal_get {
			month: number;
			supplier_id: number;
			year: number;
		};
		export async function api_monthly_invoice_proposal_get(post_data: i_monthly_invoice_proposal_get, download: boolean) {
			post_data = { ...post_data };
			keep_props(post_data, "month", "supplier_id", "year");
			return await network.POST<Blob>(
				{ url: monthly_invoice_proposal_get(), body: post_data, download },
			);
		};

	}

	export namespace scheduled_orders {
		export const scheduled_order_detail_delete = (scheduled_order_detail_id: number) => `${backend}/scheduled_orders/order_details/${scheduled_order_detail_id}`;
		export async function api_scheduled_order_detail_delete(scheduled_order_detail_id: number) {
			return await network.DELETE<Payload>(
				{ url: scheduled_order_detail_delete(scheduled_order_detail_id) },
			);
		};


		export const scheduled_order_get_all = () => `${backend}/scheduled_orders`;
		export async function api_scheduled_order_get_all(paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.ScheduledOrder[]>>(
				{
					url: scheduled_order_get_all(),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const scheduled_order_get_single = (scheduled_order_id: number) => `${backend}/scheduled_orders/${scheduled_order_id}`;
		export async function api_scheduled_order_get_single(scheduled_order_id: number, expand_details = true) {
			return await network.GET<Payload<Task.ScheduledOrder>>(
				{
					url: scheduled_order_get_single(scheduled_order_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const download_template_xlsx = (supplier_id: number) => `${backend}/scheduled_orders/download/${supplier_id}?nocache=${Date.now()}`;
		export async function api_download_template_xlsx(supplier_id: number, download: boolean) {
			return await network.GET<Blob>({ url: download_template_xlsx(supplier_id), download });
		};


		export const scheduled_order_modify = (scheduled_order_id: number) => `${backend}/scheduled_orders/${scheduled_order_id}`;
		interface i_scheduled_order_modify {
			arrival_worker?: number;
			cost_estimate?: number;
			investment_type?: number;
			location_id?: Task.Location["location_id"];
			planned_end_date?: Date | string;
			planned_start_date?: Date | string;
			scheduled_order_details?: Task.ScheduledOrderDetail[];
			supplier_id?: Task.Supplier["supplier_id"];
			title?: string;
			whier_id?: Task.WorkHierarchy["whier_id"];
		};
		export async function api_scheduled_order_modify(scheduled_order_id: number, patch_data: i_scheduled_order_modify, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"arrival_worker",
				"cost_estimate",
				"investment_type",
				"location_id",
				"planned_end_date",
				"planned_start_date",
				"scheduled_order_details",
				"supplier_id",
				"title",
				"whier_id",
			);
			return await network.PATCH<Payload<Task.ScheduledOrder>>(
				{
					url: scheduled_order_modify(scheduled_order_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const scheduled_order_detail_modify = (scheduled_order_detail_id: number) => `${backend}/scheduled_orders/order_details/${scheduled_order_detail_id}`;
		interface i_scheduled_order_detail_modify {
			description?: Task.ScheduledOrderDetail["description"];
			inventory_id?: number;
			inventory_name?: string;
			inventory_no?: string;
			inventory_serial_no?: string;
			order_detail_type_id?: number;
			picture_url?: Task.ScheduledOrderDetail["picture_url"];
			unit_of_measure_id?: number;
		};
		export async function api_scheduled_order_detail_modify(scheduled_order_detail_id: number, patch_data: i_scheduled_order_detail_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"description",
				"inventory_id",
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
				"order_detail_type_id",
				"picture_url",
				"unit_of_measure_id",
			);
			return await network.PATCH<Payload<Task.ScheduledOrderDetail>>(
				{ url: scheduled_order_detail_modify(scheduled_order_detail_id), body: patch_data },
			);
		};


		export const scheduled_order_delete = (scheduled_order_id: number) => `${backend}/scheduled_orders/delete/${scheduled_order_id}`;
		interface i_scheduled_order_delete {
			comment: string;
		};
		export async function api_scheduled_order_delete(scheduled_order_id: number, patch_data: i_scheduled_order_delete) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "comment");
			return await network.PATCH<Payload>(
				{ url: scheduled_order_delete(scheduled_order_id), body: patch_data },
			);
		};


		export const scheduled_order_add = () => `${backend}/scheduled_orders`;
		interface i_scheduled_order_add {
			location_id: Task.Location["location_id"];
			planned_date: Date | string;
			scheduled_order_details: Task.ScheduledOrderDetail[];
			supplier_id: Task.Company["company_id"];
			title: string;
			whier_id: Task.WorkHierarchy["whier_id"];
			arrival_worker_id?: number;
			cost_estimate?: number;
			investment_type?: number;
		};
		export async function api_scheduled_order_add(post_data: i_scheduled_order_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"arrival_worker_id",
				"cost_estimate",
				"investment_type",
				"location_id",
				"planned_date",
				"scheduled_order_details",
				"supplier_id",
				"title",
				"whier_id",
			);
			return await network.POST<Payload<Task.ScheduledOrder>>(
				{
					url: scheduled_order_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const scheduled_order_search = () => `${backend}/scheduled_orders/search`;
		export async function api_scheduled_order_search(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.ScheduledOrder[]>>(
				{
					url: scheduled_order_search(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const scheduled_order_calendar_search = () => `${backend}/scheduled_orders/calendar/search`;
		export async function api_scheduled_order_calendar_search(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload<Task.CalendarScheduledOrder[]>>(
				{ url: scheduled_order_calendar_search(), body: search },
			);
		};


		export const calendar_events_get = () => `${backend}/calendar`;
		export async function api_calendar_events_get(search: Search_Params) {
			normalize_sort(search);
			return await network.POST<Payload<Task.CalendarEvent[]>>(
				{ url: calendar_events_get(), body: search },
			);
		};


		export const scheduled_orders_plan_get = () => `${backend}/scheduled_orders/plan`;
		export async function api_scheduled_orders_plan_get(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.ScheduledEvent[]>>(
				{
					url: scheduled_orders_plan_get(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const upload_scheduled_orders_for_supplier = (supplier_id: number) => `${backend}/scheduled_orders/upload/${supplier_id}`;
		export async function api_upload_scheduled_orders_for_supplier(supplier_id: number, file: File) {
			return await network.POST<Payload<string>>(
				{ url: upload_scheduled_orders_for_supplier(supplier_id), body: file },
			);
		};

	}

	export namespace scheduled_orders_templates {
		export const scheduled_order_template = (template_id: number) => `${backend}/scheduled_orders_templates/${template_id}`;
		export async function api_scheduled_order_template(template_id: number) {
			return await network.GET<Payload<Task.ScheduledOrderTemplate>>(
				{ url: scheduled_order_template(template_id) },
			);
		};


		export const scheduled_order_templates = () => `${backend}/scheduled_orders_templates`;
		export async function api_scheduled_order_templates() {
			return await network.GET<Payload<Task.ScheduledOrderTemplate[]>>(
				{ url: scheduled_order_templates() },
			);
		};


		export const scheduled_order_template_approve = (template_id: number) => `${backend}/scheduled_orders_templates/${template_id}/approve`;
		export async function api_scheduled_order_template_approve(template_id: number, expand_details = false) {
			return await network.GET<Payload<Task.ScheduledOrder[]>>(
				{
					url: scheduled_order_template_approve(template_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const scheduled_order_template_modify = (template_id: number) => `${backend}/scheduled_orders_templates/${template_id}`;
		interface i_scheduled_order_template_modify {
			annual_frequency?: number;
			arrival_worker_id?: number;
			comment?: string;
			cut_date?: Date | string;
			locations?: Task.Location[];
			planned_end_date?: Date | string;
			planned_start_date?: Date | string;
			scheduled_orders_template_details?: any[];
			supplier_id?: number;
			title?: string;
			whier_id?: number;
		};
		export async function api_scheduled_order_template_modify(template_id: number, patch_data: i_scheduled_order_template_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"annual_frequency",
				"arrival_worker_id",
				"comment",
				"cut_date",
				"locations",
				"planned_end_date",
				"planned_start_date",
				"scheduled_orders_template_details",
				"supplier_id",
				"title",
				"whier_id",
			);
			return await network.PATCH<Payload<Task.ScheduledOrderTemplate>>(
				{ url: scheduled_order_template_modify(template_id), body: patch_data },
			);
		};


		export const scheduled_order_add = () => `${backend}/scheduled_orders_templates`;
		interface i_scheduled_order_add {
			scheduled_orders_templates: any[];
		};
		export async function api_scheduled_order_add(post_data: i_scheduled_order_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "scheduled_orders_templates");
			return await network.POST<Payload<Task.ScheduledOrderTemplate>>(
				{
					url: scheduled_order_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const scheduled_order_template_reject = (template_id: number) => `${backend}/scheduled_orders_templates/${template_id}/reject`;
		interface i_scheduled_order_template_reject {
			comment: string;
		};
		export async function api_scheduled_order_template_reject(template_id: number, post_data: i_scheduled_order_template_reject, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload<Task.ScheduledOrderTemplate>>(
				{
					url: scheduled_order_template_reject(template_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const scheduled_orders_template_delete = (template_id: number) => `${backend}/scheduled_orders_templates/${template_id}/delete`;
		interface i_scheduled_orders_template_delete {
			comment?: string;
		};
		export async function api_scheduled_orders_template_delete(template_id: number, post_data: i_scheduled_orders_template_delete) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload>(
				{ url: scheduled_orders_template_delete(template_id), body: post_data },
			);
		};

	}

	export namespace sectors {
		export const sector_delete = (sector_id: number) => `${backend}/sectors/${sector_id}`;
		export async function api_sector_delete(sector_id: number) {
			return await network.DELETE<Payload>({ url: sector_delete(sector_id) });
		};


		export const sector_get_all = () => `${backend}/sectors`;
		export async function api_sector_get_all(paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Sector[]>>(
				{
					url: sector_get_all(),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const sector_get_single = (sector_id: number) => `${backend}/sectors/${sector_id}`;
		export async function api_sector_get_single(sector_id: number, expand_details = true) {
			return await network.GET<Payload<Task.Sector>>(
				{
					url: sector_get_single(sector_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const sector_modify = (sector_id: number) => `${backend}/sectors/${sector_id}`;
		interface i_sector_modify {
			name?: string;
			whiers?: any[];
		};
		export async function api_sector_modify(sector_id: number, patch_data: i_sector_modify, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(patch_data, "name", "whiers");
			return await network.PATCH<Payload<Task.Sector>>(
				{
					url: sector_modify(sector_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const sector_add = () => `${backend}/sectors`;
		interface i_sector_add {
			name: string;
			whiers?: any[];
		};
		export async function api_sector_add(post_data: i_sector_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "name", "whiers");
			return await network.POST<Payload<Task.Sector>>(
				{
					url: sector_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};

	}

	export namespace suppliers {
		export const supplier_delete = (supplier_id: number) => `${backend}/suppliers/${supplier_id}`;
		export async function api_supplier_delete(supplier_id: number) {
			return await network.DELETE<Payload>({ url: supplier_delete(supplier_id) });
		};


		export const supplier_get_all = () => `${backend}/suppliers`;
		export async function api_supplier_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.Supplier[]>>(
				{ url: supplier_get_all(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const supplier_get_single = (supplier_id: number) => `${backend}/suppliers/${supplier_id}`;
		export async function api_supplier_get_single(supplier_id: number) {
			return await network.GET<Payload<Task.Supplier>>({ url: supplier_get_single(supplier_id) });
		};


		export const supplier_pricelist_get_whole = (supplier_id: number) => `${backend}/suppliers/${supplier_id}/pricelist`;
		export async function api_supplier_pricelist_get_whole(supplier_id: number) {
			return await network.GET<Payload<Task.Pricelist[]>>(
				{ url: supplier_pricelist_get_whole(supplier_id) },
			);
		};


		export const supplier_modify = (supplier_id: number) => `${backend}/suppliers/${supplier_id}`;
		interface i_supplier_modify {
			address?: string;
			cin?: Task.Supplier["cin"];
			city?: string;
			contact_name?: string;
			contact_phone?: string;
			country?: string;
			description?: string;
			identification_no?: string;
			name?: string;
			postal_code?: string;
			sectors?: number[];
			tax_number?: string;
		};
		export async function api_supplier_modify(supplier_id: number, patch_data: i_supplier_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"address",
				"cin",
				"city",
				"contact_name",
				"contact_phone",
				"country",
				"description",
				"identification_no",
				"name",
				"postal_code",
				"sectors",
				"tax_number",
			);
			return await network.PATCH<Payload<Task.Supplier>>(
				{ url: supplier_modify(supplier_id), body: patch_data },
			);
		};


		export const supplier_add = () => `${backend}/suppliers`;
		interface i_supplier_add {
			company_type_id: number;
			email: Task.Company["email"];
			name: Task.Company["name"];
			address?: string;
			cin?: Task.Supplier["cin"];
			city?: string;
			contact_name?: string;
			contact_phone?: string;
			country?: string;
			description?: string;
			identification_no?: string;
			postal_code?: string;
			sectors?: number[];
			smtp_email?: string;
			smtp_password?: string;
			smtp_port?: number;
			smtp_url?: string;
			smtp_use_default?: boolean;
			smtp_username?: string;
			tax_number?: string;
		};
		export async function api_supplier_add(post_data: i_supplier_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"address",
				"cin",
				"city",
				"company_type_id",
				"contact_name",
				"contact_phone",
				"country",
				"description",
				"email",
				"identification_no",
				"name",
				"postal_code",
				"sectors",
				"smtp_email",
				"smtp_password",
				"smtp_port",
				"smtp_url",
				"smtp_use_default",
				"smtp_username",
				"tax_number",
			);
			return await network.POST<Payload<Task.Supplier>>({ url: supplier_add(), body: post_data });
		};


		export const supplier_search = () => `${backend}/suppliers/search`;
		export async function api_supplier_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Supplier[]>>(
				{
					url: supplier_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace tickets {
		export const ticket_delete = (ticket_id: number) => `${backend}/tickets/${ticket_id}`;
		export async function api_ticket_delete(ticket_id: number) {
			return await network.DELETE<Payload>({ url: ticket_delete(ticket_id) });
		};


		export const ticket_detail_delete = (ticket_detail_id: number) => `${backend}/tickets/ticket_details/${ticket_detail_id}`;
		export async function api_ticket_detail_delete(ticket_detail_id: number) {
			return await network.DELETE<Payload>({ url: ticket_detail_delete(ticket_detail_id) });
		};


		export const ticket_get_all = () => `${backend}/tickets`;
		export async function api_ticket_get_all(paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Ticket[]>>(
				{
					url: ticket_get_all(),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const ticket_get_single = (ticket_id: number) => `${backend}/tickets/${ticket_id}`;
		export async function api_ticket_get_single(ticket_id: number, expand_details = true) {
			return await network.GET<Payload<Task.Ticket>>(
				{
					url: ticket_get_single(ticket_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_orders_get_all = (ticket_id: number) => `${backend}/tickets/${ticket_id}/orders`;
		export async function api_ticket_orders_get_all(ticket_id: number, paginator: Paginator = {}, expand_details = false) {
			return await network.GET<Payload<Task.Order[]>>(
				{
					url: ticket_orders_get_all(ticket_id),
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const ticket_take_over = (ticket_id: number) => `${backend}/tickets/${ticket_id}/take_over`;
		export async function api_ticket_take_over(ticket_id: number, expand_details = false) {
			return await network.GET<Payload<Task.Ticket>>(
				{
					url: ticket_take_over(ticket_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_approve = (ticket_id: number) => `${backend}/tickets/${ticket_id}/approve`;
		export async function api_ticket_approve(ticket_id: number, expand_details = false) {
			return await network.GET<Payload<Task.Ticket>>(
				{
					url: ticket_approve(ticket_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_proceed = (ticket_id: number) => `${backend}/tickets/${ticket_id}/proceed`;
		export async function api_ticket_proceed(ticket_id: number, expand_details = false) {
			return await network.GET<Payload<Task.Ticket>>(
				{
					url: ticket_proceed(ticket_id),
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_history = (ticket_id: number) => `${backend}/tickets/${ticket_id}/ticket_history`;
		export async function api_ticket_history(ticket_id: number, download: boolean) {
			return await network.GET<Blob>({ url: ticket_history(ticket_id), download });
		};


		export const get_document_by_ticket_detail_id = (ticket_detail_id: number) => `${backend}/tickets/ticket_details/${ticket_detail_id}`;
		export async function api_get_document_by_ticket_detail_id(ticket_detail_id: number, download: boolean) {
			return await network.GET<Blob>(
				{ url: get_document_by_ticket_detail_id(ticket_detail_id), download },
			);
		};


		export const ticket_modify = (ticket_id: number) => `${backend}/tickets/${ticket_id}`;
		interface i_ticket_modify {
			active?: boolean;
			assigned_user_id?: Task.User["user_id"];
			location_id?: Task.Location["location_id"];
			priority_id?: number;
			reference_ticket_id?: Task.Ticket["ticket_id"];
			ticket_details?: any[];
			title?: Task.Ticket["title"];
			whier_id?: Task.WorkHierarchy["whier_id"];
		};
		export async function api_ticket_modify(ticket_id: number, patch_data: i_ticket_modify, expand_details = false) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"active",
				"assigned_user_id",
				"location_id",
				"priority_id",
				"reference_ticket_id",
				"ticket_details",
				"title",
				"whier_id",
			);
			return await network.PATCH<Payload<Task.Ticket>>(
				{
					url: ticket_modify(ticket_id),
					body: patch_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_detail_modify = (ticket_detail_id: number) => `${backend}/tickets/ticket_details/${ticket_detail_id}`;
		interface i_ticket_detail_modify {
			answer?: Task.TicketDetail["answer"];
			component_type_id?: number;
			inventory_data?: Task.KAM_Inventory;
			inventory_id?: number;
			inventory_name?: string;
			inventory_no?: string;
			inventory_serial_no?: string;
			question?: Task.TicketDetail["question"];
		};
		export async function api_ticket_detail_modify(ticket_detail_id: number, patch_data: i_ticket_detail_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"answer",
				"component_type_id",
				"inventory_data",
				"inventory_id",
				"inventory_name",
				"inventory_no",
				"inventory_serial_no",
				"question",
			);
			return await network.PATCH<Payload<Task.TicketDetail>>(
				{ url: ticket_detail_modify(ticket_detail_id), body: patch_data },
			);
		};


		export const ticket_admin = (ticket_id: number) => `${backend}/tickets/${ticket_id}/admin`;
		interface i_ticket_admin {
			reason: string;
			status_id: number;
		};
		export async function api_ticket_admin(ticket_id: number, post_data: i_ticket_admin, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "reason", "status_id");
			return await network.POST<Payload<Task.Ticket>>(
				{
					url: ticket_admin(ticket_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_reject = (ticket_id: number) => `${backend}/tickets/${ticket_id}/reject`;
		interface i_ticket_reject {
			comment: string;
		};
		export async function api_ticket_reject(ticket_id: number, post_data: i_ticket_reject, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload<Task.Ticket>>(
				{
					url: ticket_reject(ticket_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_set_on_hold = (ticket_id: number) => `${backend}/tickets/${ticket_id}/on_hold`;
		interface i_ticket_set_on_hold {
			comment: string;
		};
		export async function api_ticket_set_on_hold(ticket_id: number, post_data: i_ticket_set_on_hold, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload<Task.Ticket>>(
				{
					url: ticket_set_on_hold(ticket_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_set_on_refinement = (ticket_id: number) => `${backend}/tickets/${ticket_id}/on_refinement`;
		interface i_ticket_set_on_refinement {
			comment: string;
		};
		export async function api_ticket_set_on_refinement(ticket_id: number, post_data: i_ticket_set_on_refinement, expand_details = false) {
			post_data = { ...post_data };
			keep_props(post_data, "comment");
			return await network.POST<Payload<Task.Ticket>>(
				{
					url: ticket_set_on_refinement(ticket_id),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_add = () => `${backend}/tickets`;
		interface i_ticket_add {
			location_id: Task.Location["location_id"];
			priority_id: number;
			title: string;
			whier_id: Task.WorkHierarchy["whier_id"];
			assigned_user_id?: Task.User["user_id"];
			complaint?: boolean;
			reference_ticket_id?: Task.Ticket["ticket_id"];
			ticket_details?: any[];
		};
		export async function api_ticket_add(post_data: i_ticket_add, expand_details = false) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"assigned_user_id",
				"complaint",
				"location_id",
				"priority_id",
				"reference_ticket_id",
				"ticket_details",
				"title",
				"whier_id",
			);
			return await network.POST<Payload<Task.Ticket>>(
				{
					url: ticket_add(),
					body: post_data,
					headers: { ...network.Headers.expand(expand_details) }
				},
			);
		};


		export const ticket_search = () => `${backend}/tickets/search`;
		export async function api_ticket_search(search: Search_Params, paginator: Paginator = {}, expand_details = false) {
			normalize_sort(search);
			return await network.POST<Payload<Task.Ticket[]>>(
				{
					url: ticket_search(),
					body: search,
					headers: {
						...network.Headers.paginator(paginator),
						...network.Headers.expand(expand_details)
					}
				},
			);
		};


		export const ticket_orders_assign = (ticket_id: number) => `${backend}/tickets/${ticket_id}/assign_orders`;
		interface i_ticket_orders_assign {
			order_ids: number[];
		};
		export async function api_ticket_orders_assign(ticket_id: number, post_data: i_ticket_orders_assign) {
			post_data = { ...post_data };
			keep_props(post_data, "order_ids");
			return await network.POST<Payload>({ url: ticket_orders_assign(ticket_id), body: post_data });
		};


		export const ticket_orders_unassign = (ticket_id: number) => `${backend}/tickets/${ticket_id}/unassign_orders`;
		interface i_ticket_orders_unassign {
			order_ids: number[];
		};
		export async function api_ticket_orders_unassign(ticket_id: number, post_data: i_ticket_orders_unassign) {
			post_data = { ...post_data };
			keep_props(post_data, "order_ids");
			return await network.POST<Payload>({ url: ticket_orders_unassign(ticket_id), body: post_data });
		};


		export const upload_document = (ticket_id: number, questionnaire_id: number|string) => `${backend}/tickets/${ticket_id}/upload-document/${questionnaire_id}`;
		export async function api_upload_document(ticket_id: number, questionnaire_id: number|string, file: File, expand_details = false, x_component_type?: Task.COMPONENT_TYPE) {
			return await network.POST<Payload<Task.TicketDetail>>(
				{
					url: upload_document(ticket_id, questionnaire_id),
					body: file,
					headers: {
						...network.Headers.expand(expand_details),
						...normalized_header("x_component_type", x_component_type)
					}
				},
			);
		};

	}

	export namespace upload {
		export const uploaded_file_get = (filename: string) => `${backend}/upload/${filename}`;
		export async function api_uploaded_file_get(filename: string, download: boolean) {
			return await network.GET<Blob>({ url: uploaded_file_get(filename), download });
		};


		export const user_manual_get = () => `${backend}/upload/user_manual`;
		export async function api_user_manual_get(download: boolean) {
			return await network.GET<Blob>({ url: user_manual_get(), download });
		};


		export const upload_file = () => `${backend}/upload`;
		export async function api_upload_file(file: File) {
			return await network.POST<Payload<string>>({ url: upload_file(), body: file });
		};

	}

	export namespace users {
		export const user_delete = (user_id: number) => `${backend}/users/${user_id}`;
		export async function api_user_delete(user_id: number) {
			return await network.DELETE<Payload>({ url: user_delete(user_id) });
		};


		export const user_get_all = () => `${backend}/users`;
		export async function api_user_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.User[]>>(
				{ url: user_get_all(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const user_work_groups_get_all = (user_id: number) => `${backend}/users/${user_id}/user_work_group`;
		export async function api_user_work_groups_get_all(user_id: number, paginator: Paginator = {}) {
			return await network.GET<Payload<Task.WorkGroup[]>>(
				{
					url: user_work_groups_get_all(user_id),
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const user_get_single = (user_id: number) => `${backend}/users/${user_id}`;
		export async function api_user_get_single(user_id: number) {
			return await network.GET<Payload<Task.User>>({ url: user_get_single(user_id) });
		};


		export const user_locations_get_all = (user_id: number) => `${backend}/users/${user_id}/locations`;
		export async function api_user_locations_get_all(user_id: number) {
			return await network.GET<Payload<Task.Location[]>>({ url: user_locations_get_all(user_id) });
		};


		export const user_check = () => `${backend}/users/check`;
		export async function api_user_check() {
			return await network.GET<Payload<Task.User>>({ url: user_check() });
		};


		export const send_password_recovery = (user_id: number) => `${backend}/users/${user_id}/send_password_recovery_email`;
		export async function api_send_password_recovery(user_id: number) {
			return await network.GET<Payload>({ url: send_password_recovery(user_id) });
		};


		export const user_take_over_history_get = (user_id: number) => `${backend}/users/${user_id}/take_over_history`;
		export async function api_user_take_over_history_get(user_id: number) {
			return await network.GET<Payload<Task.UserTakeoverHistory[]>>(
				{ url: user_take_over_history_get(user_id) },
			);
		};


		export const user_take_over_cancel = (user_takeover_history_id: number) => `${backend}/users/take_over/${user_takeover_history_id}/cancel`;
		export async function api_user_take_over_cancel(user_takeover_history_id: number) {
			return await network.GET<Payload>({ url: user_take_over_cancel(user_takeover_history_id) });
		};


		export const user_modify = (user_id: number) => `${backend}/users/${user_id}`;
		interface i_user_modify {
			communication_type?: number;
			contact_phone?: string;
			email?: Task.User["email"];
			first_name?: string;
			last_name?: string;
			password?: string;
			pic_url?: string;
			sector_id?: Task.Sector["sector_id"];
			supplier_data?: any[];
			user_type_id?: number;
			work_hour_type_id?: Task.WORK_HOUR_TYPE;
		};
		export async function api_user_modify(user_id: number, patch_data: i_user_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"communication_type",
				"contact_phone",
				"email",
				"first_name",
				"last_name",
				"password",
				"pic_url",
				"sector_id",
				"supplier_data",
				"user_type_id",
				"work_hour_type_id",
			);
			return await network.PATCH<Payload<Task.User>>({ url: user_modify(user_id), body: patch_data });
		};


		export const user_add = () => `${backend}/users`;
		interface i_user_add {
			email: Task.User["email"];
			first_name: string;
			last_name: string;
			user_type_id: number;
			communication_type?: number;
			company_id?: Task.Company["company_id"];
			contact_phone?: string;
			password?: string;
			pic_url?: string;
			sector_id?: Task.Sector["sector_id"];
			supplier_data?: any[];
			work_hour_type_id?: Task.WORK_HOUR_TYPE;
		};
		export async function api_user_add(post_data: i_user_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"communication_type",
				"company_id",
				"contact_phone",
				"email",
				"first_name",
				"last_name",
				"password",
				"pic_url",
				"sector_id",
				"supplier_data",
				"user_type_id",
				"work_hour_type_id",
			);
			return await network.POST<Payload<Task.User>>({ url: user_add(), body: post_data });
		};


		export const user_locations_add = (user_id: number) => `${backend}/users/${user_id}/locations`;
		interface i_user_locations_add {
			location_group_ids: number;
			location_ids: number[];
		};
		export async function api_user_locations_add(user_id: number, post_data: i_user_locations_add) {
			post_data = { ...post_data };
			keep_props(post_data, "location_group_ids", "location_ids");
			return await network.POST<Payload>({ url: user_locations_add(user_id), body: post_data });
		};


		export const user_search = () => `${backend}/users/search`;
		export async function api_user_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.User[]>>(
				{ url: user_search(), body: search, headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const user_take_over_history_search = () => `${backend}/users/take_over_history/search`;
		export async function api_user_take_over_history_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.UserTakeoverHistory[]>>(
				{
					url: user_take_over_history_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const take_over_user_locations = () => `${backend}/users/take_over`;
		interface i_take_over_user_locations {
			expiration_date: Date | string;
			original_user_id: Task.User["user_id"];
			user_id: Task.User["user_id"];
		};
		export async function api_take_over_user_locations(post_data: i_take_over_user_locations) {
			post_data = { ...post_data };
			keep_props(post_data, "expiration_date", "original_user_id", "user_id");
			return await network.POST<Payload>({ url: take_over_user_locations(), body: post_data });
		};

	}

	export namespace versions {
		export const get_version = () => `${backend}/versions/mobile`;
		export async function api_get_version() {
			return await network.GET<Payload>({ url: get_version() });
		};

	}

	export namespace work_descriptions {
		export const work_descriptions_get = () => `${backend}/work_descriptions`;
		export async function api_work_descriptions_get(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.WorkDescription[]>>(
				{ url: work_descriptions_get(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const work_description_modify = (work_description_id: number) => `${backend}/work_descriptions/${work_description_id}`;
		interface i_work_description_modify {
			automatically_update_arrival_details?: boolean;
			work_description?: string;
		};
		export async function api_work_description_modify(work_description_id: number, patch_data: i_work_description_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"automatically_update_arrival_details",
				"work_description",
			);
			return await network.PATCH<Payload<Task.WorkDescription>>(
				{ url: work_description_modify(work_description_id), body: patch_data },
			);
		};


		export const work_descriptions_search = () => `${backend}/work_descriptions/search`;
		export async function api_work_descriptions_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.WorkDescription[]>>(
				{
					url: work_descriptions_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const add_work_description = () => `${backend}/work_descriptions`;
		interface i_add_work_description {
			work_description: string;
		};
		export async function api_add_work_description(post_data: i_add_work_description) {
			post_data = { ...post_data };
			keep_props(post_data, "work_description");
			return await network.POST<Payload<Task.WorkDescription>>(
				{ url: add_work_description(), body: post_data },
			);
		};

	}

	export namespace work_groups {
		export const work_group_delete = (work_group_id: number) => `${backend}/work_groups/${work_group_id}`;
		export async function api_work_group_delete(work_group_id: number) {
			return await network.DELETE<Payload>({ url: work_group_delete(work_group_id) });
		};


		export const work_group_get_all = () => `${backend}/work_groups`;
		export async function api_work_group_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.WorkGroup[]>>(
				{ url: work_group_get_all(), headers: { ...network.Headers.paginator(paginator) } },
			);
		};


		export const work_group_get_single = (work_group_id: number) => `${backend}/work_groups/${work_group_id}`;
		export async function api_work_group_get_single(work_group_id: number) {
			return await network.GET<Payload<Task.WorkGroup>>(
				{ url: work_group_get_single(work_group_id) },
			);
		};


		export const work_group_modify = (work_group_id: number) => `${backend}/work_groups/${work_group_id}`;
		interface i_work_group_modify {
			description?: string;
			email?: string;
			name?: Task.WorkGroup["name"];
			whier_id?: Task.WorkHierarchy["whier_id"];
			work_group_type_id?: number;
		};
		export async function api_work_group_modify(work_group_id: number, patch_data: i_work_group_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"description",
				"email",
				"name",
				"whier_id",
				"work_group_type_id",
			);
			return await network.PATCH<Payload<Task.WorkGroup>>(
				{ url: work_group_modify(work_group_id), body: patch_data },
			);
		};


		export const work_group_add = () => `${backend}/work_groups`;
		interface i_work_group_add {
			name: Task.WorkGroup["name"];
			whier_id: Task.WorkHierarchy["whier_id"];
			work_group_type_id: number;
			description?: string;
			email?: string;
			user_ids?: number[];
		};
		export async function api_work_group_add(post_data: i_work_group_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"description",
				"email",
				"name",
				"user_ids",
				"whier_id",
				"work_group_type_id",
			);
			return await network.POST<Payload<Task.WorkGroup>>({ url: work_group_add(), body: post_data });
		};


		export const work_group_users_assign = (work_group_id: number) => `${backend}/work_groups/${work_group_id}/assign_users`;
		interface i_work_group_users_assign {
			user_ids: number[];
		};
		export async function api_work_group_users_assign(work_group_id: number, post_data: i_work_group_users_assign) {
			post_data = { ...post_data };
			keep_props(post_data, "user_ids");
			return await network.POST<Payload>(
				{ url: work_group_users_assign(work_group_id), body: post_data },
			);
		};


		export const work_group_users_unassign = (work_group_id: number) => `${backend}/work_groups/${work_group_id}/unassign_users`;
		interface i_work_group_users_unassign {
			user_ids: number[];
		};
		export async function api_work_group_users_unassign(work_group_id: number, post_data: i_work_group_users_unassign) {
			post_data = { ...post_data };
			keep_props(post_data, "user_ids");
			return await network.POST<Payload>(
				{ url: work_group_users_unassign(work_group_id), body: post_data },
			);
		};


		export const work_group_search = () => `${backend}/work_groups/search`;
		export async function api_work_group_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.WorkGroup[]>>(
				{
					url: work_group_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

	export namespace work_hierarchies {
		export const work_hierarchy_delete = (whier_id: number) => `${backend}/work_hierarchies/${whier_id}`;
		export async function api_work_hierarchy_delete(whier_id: number) {
			return await network.DELETE<Payload>({ url: work_hierarchy_delete(whier_id) });
		};


		export const work_hierarchy_get_all = () => `${backend}/work_hierarchies`;
		export async function api_work_hierarchy_get_all(paginator: Paginator = {}) {
			return await network.GET<Payload<Task.WorkHierarchy[]>>(
				{
					url: work_hierarchy_get_all(),
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const work_hierarchy_get_single = (whier_id: number) => `${backend}/work_hierarchies/${whier_id}`;
		export async function api_work_hierarchy_get_single(whier_id: number) {
			return await network.GET<Payload<Task.WorkHierarchy>>(
				{ url: work_hierarchy_get_single(whier_id) },
			);
		};


		export const work_hierarchy_questionnaures_get_all = (whier_id: number) => `${backend}/work_hierarchies/${whier_id}/questionnaires`;
		export async function api_work_hierarchy_questionnaures_get_all(whier_id: number, paginator: Paginator = {}) {
			return await network.GET<Payload<Task.Questionnaire[]>>(
				{
					url: work_hierarchy_questionnaures_get_all(whier_id),
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};


		export const work_hierarchy_questionnaires_get_all_recursive = (whier_id: number) => `${backend}/work_hierarchies/${whier_id}/questionnaires/recursive`;
		export async function api_work_hierarchy_questionnaires_get_all_recursive(whier_id: number) {
			return await network.GET<Payload<Task.Questionnaire[]>>(
				{ url: work_hierarchy_questionnaires_get_all_recursive(whier_id) },
			);
		};


		export const work_hierarchy_modify = (whier_id: number) => `${backend}/work_hierarchies/${whier_id}`;
		interface i_work_hierarchy_modify {
			automatically_approve_ticket?: boolean;
			description?: string;
			level?: number;
			long_description?: string;
			name?: string;
			send_email_on_ticket_approval?: boolean;
			capex?: string;
		};
		export async function api_work_hierarchy_modify(whier_id: number, patch_data: i_work_hierarchy_modify) {
			patch_data = { ...patch_data };
			keep_props(
				patch_data,
				"automatically_approve_ticket",
				"description",
				"level",
				"long_description",
				"name",
				"send_email_on_ticket_approval",
				"capex"
			);
			return await network.PATCH<Payload<Task.WorkHierarchy>>(
				{ url: work_hierarchy_modify(whier_id), body: patch_data },
			);
		};


		export const work_hierarchy_add = () => `${backend}/work_hierarchies`;
		interface i_work_hierarchy_add {
			level: number;
			name: string;
			automatically_approve_tickets?: boolean;
			description?: string;
			long_description?: string;
			parent_whier_id?: Task.WorkHierarchy["whier_id"];
			send_email_on_ticket_approval?: boolean;
		};
		export async function api_work_hierarchy_add(post_data: i_work_hierarchy_add) {
			post_data = { ...post_data };
			keep_props(
				post_data,
				"automatically_approve_tickets",
				"description",
				"level",
				"long_description",
				"name",
				"parent_whier_id",
				"send_email_on_ticket_approval",
			);
			return await network.POST<Payload<Task.WorkHierarchy>>(
				{ url: work_hierarchy_add(), body: post_data },
			);
		};


		export const work_hierarchy_search = () => `${backend}/work_hierarchies/search`;
		export async function api_work_hierarchy_search(search: Search_Params, paginator: Paginator = {}) {
			normalize_sort(search);
			return await network.POST<Payload<Task.WorkHierarchy[]>>(
				{
					url: work_hierarchy_search(),
					body: search,
					headers: { ...network.Headers.paginator(paginator) }
				},
			);
		};

	}

}



