<ng-container *ngIf="ticket">

<div class="ticket-header">
    <div class="flex-row" style="margin-bottom: 0.25em; flex-wrap: wrap">
        <div class="flex-column">
            <div class="flex-row">
                <i [attr.icon]="icon" [style.color]="color" [title]="status_name"></i>
                <h2 style="margin: 0 0 0 0.5ch">#{{ticket.ticket_id}} {{ticket.title}}</h2>

                <button class="screen-big" (click)="toggle_priority()">
                    <i [attr.icon]="priority_icon" [style.color]="priority_color"></i> &nbsp;
                    {{ priority_text }}
                </button>
                <button class="round screen-small"
                        (click)="toggle_priority()"
                        [title]="priority_text">
                    <i [attr.icon]="priority_icon" [style.color]="priority_color"></i>
                </button>
            </div>

            <div class="flex-row align-baseline" style="padding-left: 25px; margin-top: .7em; margin-bottom: .25em;">
                <i><strong>#{{ ticket.location_cin }}</strong></i> &nbsp;
                <i style="display: inline-block; margin-top: .25em;">{{ ticket.location_name }}</i>
            </div>
        </div>


        <div class="flex-dynamic"></div>

        <i style="align-self: center">
            {{ ticket.created_date | datefmt:"short_date_time" : false }}
        </i>
    </div>

    <div class="flex-row for-toolbar"
            style="flex-wrap: wrap"
            *ngIf="ticket.assigned_user_id || can_take">
        <div class="flex-column" style="grid-gap: .25em;">
            <span *ngIf="ticket.assigned_user_id">
                <b>{{ 'TICKET.ASSIGNED_TO' | trans }}</b>:&nbsp;
                <ng-container *ngIf="ticket.authorized_maintenance_managers.length>0">
                    <span *ngFor="let mng of ticket.authorized_maintenance_managers;let i = index;">
                        <span *ngIf="i !== 0">, </span>
                        {{ mng.first_name }} {{ mng.last_name }}
                    </span>
                </ng-container>
            </span>
            <span *ngIf="is_paused && ticket.comment">
                <b>{{ 'TICKET.PAUSED_REASON' | trans }}</b>:&nbsp;
                {{ ticket.comment }}
            </span>
            <span *ngIf="is_rejected && ticket.comment">
                <b>{{ 'TICKET.REJECTED_REASON' | trans }}</b>:&nbsp;
                {{ ticket.comment }}
            </span>
            <span *ngIf="needs_refinement && ticket.comment">
                <b>{{ 'TICKET.REFINEMENT_REASON' | trans }}</b>:&nbsp;
                {{ ticket.comment }}
            </span>
            <span class="flex-row align-center wrappable">
                <b>{{ 'NAVROUTE.WORKHIER' | trans }}:&nbsp;</b>
                <i *ngIf="ticket.whier.category_description">{{ ticket.whier.category_description }}</i>
                <ng-container *ngIf="ticket.whier.subcategory_description" >
                    <i icon="chevron_right"></i>
                    <i *ngIf="ticket.whier.subcategory_description">{{  ticket.whier.subcategory_description  }}</i>
                </ng-container>
                <!-- <ng-container *ngIf="ticket.whier.description">
                    <i icon="chevron_right"></i>
                    <i>{{  ticket.whier.description  }}</i>
                </ng-container> -->
            </span>
            <strong *ngIf="ticket.complaint" class="label-pill uppercase" color="yellow-dark" style="align-self: flex-start; font-size: 80%; margin-top: .5em;">
                {{'ORDER.RECLAMATION' | trans }}
            </strong>
        </div>
        <div class="flex-dynamic"></div>

        <div class="flex-row flex-dynamic justify-flex-end" *ngIf="!edited">
            <button class="filled round"
                    color="secondary"
                    (click)="approve_ticket()"
                    [title]="'TICKET.APPROVE_TICKET' | trans"
                    *ngIf="can_approve">
                <i icon="check"></i>
            </button>

            <button class="filled round"
                    color="warn"
                    [title]="'TICKET.REJECT_TICKET' | trans"
                    (click)="reject_ticket()"
                    *ngIf="can_reject">
                <i icon="clear"></i>
            </button>

            <button class="filled round"
                    color="secondary"
                    [title]="'TICKET.REQUEST_REFINEMENT'| trans"
                    (click)="request_refinement()"
                    *ngIf="can_request_refinement">
                <i icon="restore_page"></i>
            </button>

            <button class="filled round"
                    color="secondary"
                    [title]="(is_paused ? 'TICKET.UNPAUSE_TICKET' : 'TICKET.PAUSE_TICKET') | trans"
                    (click)="toggle_pause_ticket()"
                    *ngIf="can_pause">
                <i icon="schedule"></i>
            </button>

            <button *ngIf="can_take"
                    class="filled round"
                    color="secondary"
                    [title]="'TICKET.TAKE_TICKET' | trans"
                    (click)="take_ticket()">
                <i icon="assignment_returned"></i>
            </button>

            <button class="filled round"
                color="warn"
                style="margin-right: 0"
                [title]="'TICKET.DELETE_TICKET' | trans"
                (click)="delete_ticket()"
                *ngIf="can_delete">
                <i icon="delete"></i>
            </button>
        </div>

        <div class="flex-row flex-dynamic justify-flex-end" *ngIf="edited">
            <button class="filled pill outlined"
                    color="secondary"
                    (click)="save()">
                <span class="screen-big">
                    {{ "SAVE" | trans }}
                </span>
                <i icon="save"></i>
            </button>


            <button class="outlined pill"
                    style="margin-right: 0"
                    color="primary"
                    (click)="cancel_edit()">
                <span class="screen-big">
                    {{ "CANCEL" | trans}}
                </span>
                <i icon="cancel"></i>
            </button>

        </div>

    </div>
</div>


<div class="ticket-details">
    <h4 style="margin-bottom: 0.5em;">{{ 'DETAILS' | trans }}</h4>

    <div class="ticket-details-list">

        <ng-container *ngFor="let detail of ticket.ticket_details">
            <ng-container [ngSwitch]="detail.component_type_id">

                <div class="flex-row" *ngSwitchCase="1">
                    <i [attr.icon]="
                            detail.answer === 'true' ?
                                'check_circle' :
                            detail.answer === 'false' ?
                                'cancel' :
                            'help'"
                        style="padding: 0.5em">
                    </i>
                    <i><b>{{detail.question}}</b></i>
                </div>

                <p *ngSwitchCase="3" style="margin-bottom: 1.5em;">
                    <task-click-toggle [can-toggle]="can_edit">
                        <div on>
                            <i><b>{{detail.question}}</b></i>
                            <label class="textarea" [title]="detail.question" on>
                                <textarea [(ngModel)]="detail.answer"
                                          (ngModelChange)="edited = true">
                                </textarea>
                            </label>
                        </div>
                        <div class="flex-row"
                            [ngClass]="{'bg-hover': can_edit}" off>
                            <div class="flex-dynamic">
                                <i><b>{{detail.question}}</b></i>
                                <span style="display: block; margin-bottom: .25em; margin-top: .25em;">{{detail.answer}}</span>
                            </div>
                            <i icon="edit" *ngIf="can_edit"></i>
                        </div>
                    </task-click-toggle>
                </p>

                <task-kam-inventory *ngSwitchCase="5"
                        [inventory]="detail?.inventory_data || detail">
                </task-kam-inventory>

            </ng-container>
        </ng-container>

        <div style="margin: 2em 0 1.5em;">
            <i style="margin-bottom: .25em; display: block;"><b>{{ 'INVENTORY.PICK' | trans }}</b></i>
            <task-inventory-picker
                *ngIf="can_edit"
                [ngModel]="additional_inventory"
                (ngModelChange)="
                    additional_inventory=$event;
                    edited=true"
                [location_cin]="ticket.location_cin">

            </task-inventory-picker>
        </div>


        <ng-container *ngFor="let question of image_questions | keyvalue">
            <p>
                <i><b>{{question.key == "-" ? ("TICKET.IMAGES" | trans) : question.key}}</b></i>
            </p>
            <task-multi-image
                [ngModel]="question.value"
                (ngModelChange)="
                    image_questions[question.key]=$event;
                    edited = true"
                [allow-deleting-uploaded]="true"
                [disabled]="!can_edit">
            </task-multi-image>
        </ng-container>

        <ng-container *ngFor="let question of file_questions | keyvalue">
            <p>
                <i><b>{{question.key == "-" ? ("FILES" | trans) : question.key}}</b></i>
            </p>
            <task-upload-files
                [ngModel]="question.value"
                (ngModelChange)="
                    file_questions[question.key]=$event;
                    edited=true"
                [allow-deleting-uploaded]="true"
                [type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
                [button-label]="'ADD_FILE'"
                [disabled]="!can_edit"
                [download_file]="download_file">
            </task-upload-files>
        </ng-container>
    </div>
</div>

<!-- ticket orders desktop -->
<div class="ticket-orders screen-big">
    <h4 style="margin-bottom: 0.5em;">{{ 'RELATED.ORDERS' | trans }}</h4>
    <div class="flex-row justify-center" style="padding: 1.5em 0em;">
        <button class="filled pill"
                color="primary"
                *ngIf="can_create_order"
                (click)="createOrder()">
            <i icon="ballot"></i>
            <div class="flex-dynamic"></div>
            <span>{{ 'NEW.ORDER' | trans }}</span>
        </button>
    </div>

    <div class="flex-column flex-dynamic" style="overflow: auto">
        <ng-container *ngFor="let order of orders">
            <button class="filled flex-row align-flex-start justify-flex-start"
                    color="secondary"
                    [goto]="'/orders/' + order.order_id">
                <b>#{{order.order_id}}</b> &nbsp;
                <i>{{order.title}}</i>
            </button>
        </ng-container>
    </div>
</div>

<!-- ticket orders mobile -->
<div class="flex-row justify-center screen-small" style="padding: 0.5em 0em;">
    <button class="filled pill"
            color="primary"
            *ngIf="can_create_order"
            (click)="createOrder()">
        <i icon="ballot"></i>
        <div class="flex-dynamic"></div>
        <span>{{ 'NEW.ORDER' | trans }}</span>
    </button>
</div>
<details class="ticket-orders screen-small">
    <summary>
        <h4 style="margin-bottom: 0.5em;">{{ 'RELATED.ORDERS' | trans }}</h4>
    </summary>
    <div class="flex-column flex-dynamic" style="overflow: auto">
        <ng-container *ngFor="let order of orders">
            <button class="filled flex-row align-flex-start justify-flex-start"
                    color="secondary"
                    [goto]="'/orders/' + order.order_id">
                <b>#{{order.order_id}}</b> &nbsp;
                <i>{{order.title}}</i>
            </button>
        </ng-container>
    </div>
</details>


<div class="ticket-comments">
    <task-comments [entity]="entity" [entity_id]="ticket.ticket_id">
    </task-comments>
</div>

</ng-container>
