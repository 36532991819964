import { Component, ElementRef, ViewEncapsulation, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { language, date } from 'curvy';
import { date_time_translations } from './date-time-picker.trans';


function move_month(year: number, month: number, dir: number): [number, number] {

	switch(dir) {
		case 1:
			if (month < 11) month++;
			else {
				month = 0;
				year++;
			}
		break;

		case -1:
			if (month > 0) month--;
			else {
				month = 11;
				year--;
			}
		break;
	}

	return [year, month];
}

function switch_to_next_input(val: string, max: number, targetEl:HTMLInputElement) {
	// max is input value on which is expected to be switched on to the next input
	if (parseFloat(val)>max) {
		targetEl.focus();
	} else if (val.toString().length > 1) {
		targetEl.focus();
	}

}


function date_is_valid(date: Date): boolean {
	let num = +date;
	return num === num;
}

@Component({
	selector: 'task-date-picker',
	template: `

			<div class="flex-column">
				<div class="date-picker-header flex-row justify-space-between" (click)="handle_calendar_mode()">

					<span class="picked-date">{{ picked_date | datefmt : 'short_date' : false }}</span>

					<button class="round"><i [attr.icon]="!show_date_input ? 'edit' : 'calendar_view_month'"></i></button>
				</div>

				<div *ngIf="show_date_input" class="date-input-view">
					<div class="year-month-picker" (keyup.enter)="submit_date.emit(picked_date)">
						<label class="input outlined flex-dynamic" [title]="'DAY' | trans">
							<input class="date-input"
								#dayInput
								placeholder="DD"
								type="number"
								max="31"
								min="1"
								constrain
								integer
								[(ngModel)]="picked_day"
								(ngModelChange)="update_values(); in_dateChange.emit(picked_date);"
								(input)="switch_to_next(dayInput.value, 3, monthInput)">
						</label>
						<label class="input outlined flex-dynamic" [title]="'MONTH' | trans">
							<input class="date-input"
								#monthInput
								type="number"
								placeholder="MM"
								max="12"
								min="1"
								constrain
								integer
								[ngModel]="picked_month+1"
								(ngModelChange)="update_month($event-1)"
								(input)="switch_to_next(monthInput.value, 2, yearInput)">
						</label>
						<label class="input outlined flex-dynamic" [title]="'YEAR' | trans">
							<input class="date-input"
								#yearInput
								placeholder="YY"
								type="number"
								[(ngModel)]="picked_year"
								(blur)="update_year($event);">
						</label>
					</div>
				</div>

				<div *ngIf="!show_date_input" class="date-calendar-view">
					<div class="date-navigator-wrapper flex-row align-center justify-space-between" style="position: relative;" >
						<button class="round arrow-left"
							(click)="switch_month(-1)">
							<i icon="keyboard_arrow_left"></i>
						</button>

						<div class="flex-row align-center justify-center">
							<div class="flex-row align-center justify-flex-end"
								style="flex: 1;"
								#monthsPicker>
								<div class="fake-button pill" (click)="open_months_popover()">
									<span class="picked-label" style="padding: .5em;" >{{ get_month() }}</span>
									<i [attr.icon]="picking_month ? 'arrow_drop_up' : 'arrow_drop_down'" ></i>
								</div>
								<div class="months-years-wrapper flex-row wrappable justify-space-between" *ngIf="picking_month">
									<button class="pill picked-label" *ngFor="let month of months; let i = index;" (click)="view_month = i;  update_values(); close_picking_popover();" [ngClass]="{'active' : i == view_month}">{{ month }}</button>
								</div>
							</div>
							<div class="flex-row align-center justify-flex-end"
								style="flex: 1;"
								#yearsPicker>
								<div class="fake-button pill" (click)="open_years_popover()">
									<span class="picked-label" style="padding: .5em;">{{ get_year() }}</span>
									<i [attr.icon]="picking_year ? 'arrow_drop_up' : 'arrow_drop_down'" ></i>
								</div>
								<div class="months-years-wrapper flex-row wrappable justify-space-between" *ngIf="picking_year">
									<button class="pill picked-label" *ngFor="let year of get_years | fn; let i = index;" (click)="view_year = year; update_values(); close_picking_popover();" [ngClass]="{'active': year == view_year}">{{ year }}</button>
								</div>
							</div>
						</div>

						<button class="round arrow-right"
							(click)="switch_month(1)">
							<i icon="keyboard_arrow_right"></i>
						</button>

					</div>

					<div class="calendar-wrapper wrappable flex-row">

					<ng-container *ngFor="let wd of days_in_week">
						<div class="single-day week-day justify-center align-center">
							<strong>{{ wd }}</strong>
						</div>
					</ng-container>

					<!-- last month days -->
					<ng-container *ngFor="let day of (-first_day_offset+1) | upto : 0">
						<div class="single-day justify-center align-center"
						[class.active-date]="is_active | fn : days_in_last_month + day + 1 : -1 : picked_date"
						(click)="select_day(days_in_last_month + day + 1, -1)">
							<span class="inactive-day last-month"><span>{{days_in_last_month + day + 1}}</span></span>
						</div>
					</ng-container>

					<!-- current month days -->
					<ng-container *ngFor="let day of  0 | upto : days_in_month; let i = index;">
						<div class="single-day justify-center align-center"
						[class.active-date]="is_active | fn : day+1 : 0 : picked_date"
						[class.today-date]="is_today | fn : day+1 : picked_date"
						[class.disabled]="disable_date | fn : day+1 : view_month : view_year : allow_min : allow_max"
						(click)="select_day(day+1, 0)">
							<span class="this-month">{{ day+1 }}</span>
						</div>
					</ng-container>

					<!-- next month days -->
					<ng-container *ngFor="let day of 0 | upto : (41-days_in_month-first_day_offset-1)">
						<div class="single-day justify-center align-center"
							[class.active-date]="is_active | fn : day+1 : 1 : picked_date"
							(click)="select_day(day+1, 1)">
							<span class="inactive-day next-month"><span>{{day+1}}</span></span>
						</div>
					</ng-container>
				</div>


			</div>
		`,
	styleUrls: ['./date-time-picker.scss'],
	encapsulation: ViewEncapsulation.None,

})
export class DatePickerComponent {
	@ViewChild('monthsPicker') monthsPicker: ElementRef<HTMLDivElement>;
	@ViewChild('yearsPicker') yearsPicker: ElementRef<HTMLDivElement>;


	show_date_input = false;
	picked_date: Date = null;
	picked_day:number = NaN;
	picked_month:number = NaN;
	picked_year:number = NaN;
	view_year:number = NaN;
	view_month:number = NaN;
	days_in_month:number = NaN;
	days_in_week = language.get_weekdays_monday_start().map(d=>d.slice(0,1));
	first_day_offset: number = 0;
	days_in_last_month:number = NaN;
	switch_to_next = switch_to_next_input;
	months = language.get_months();
	picking_month = false;
	picking_year = false;
	allow_min: Date|null = null;
	allow_max: Date|null = null;


	@Input('min')
	get min() {
		return this.allow_min;
	}

	set min(val: Date | string | null) {
		let original = val;
		if (typeof(val)=== "string") val = new Date(val);
		if (val == null) {
			this.allow_min = null;
			return;
		}
		if (!date_is_valid(val)) {
			console.warn("Invalid date provided to date picker: ", original);
			this.allow_min = null;
			return;
		}
		this.allow_min = date.subtract(date.start_of_day(val, false), date.duration({second: 1}));
	}

	@Input('max')
	get max() {
		return this.allow_max;
	}

	set max(val: Date | string) {
		let original = val;
		if(typeof(val)=== "string") val = new Date(val);
		if(val == null) {
			this.allow_max = null;
			return;
		}
		if(!date_is_valid(val)) {
			console.warn("Invalid date provided to date picker: ", original);
			this.allow_max = null;
			return;
		};
		this.allow_max = date.end_of_day(val, false);
	}

	@Input('in_date')
	get in_date() {
		return this.picked_date;
	}

	set in_date(val: Date | string | null) {

		if (typeof(val) === "string") val = new Date(val);
		if (val == null) val = new Date();
		if (!date_is_valid(val)) { return; }
		this.picked_date = val;
		this.picked_day = this.picked_date.getDate();
		this.picked_month = this.picked_date.getMonth();
		this.picked_year = this.picked_date.getFullYear();
		this.view_month = this.picked_month;
		this.view_year = this.picked_year;
	}


	@Output('in_dateChange')
	in_dateChange = new EventEmitter<Date>();

	@Output('submit_date')
	submit_date = new EventEmitter<Date>();

	handle_calendar_mode = ()=> {
		this.show_date_input = !this.show_date_input;
		this.view_month = this.picked_month;
		this.view_year = this.picked_year;
	}


	disable_date = (day: number) => {
		let date = new Date(this.view_year, this.view_month, day)
		if(this.allow_max != null) {
			if(date > this.allow_max) return true;
		}
		if(this.allow_min != null) {
			if (date < this.allow_min) return true;
		}
		return false;
	}

	open_months_popover() {
		this.picking_year = false;
		this.picking_month = !this.picking_month;

	}

	open_years_popover()  {
		this.picking_month = false;
		this.picking_year = !this.picking_year;
	}

	close_picking_popover() {
		this.picking_month = this.picking_year = false;
	}


	is_today = (d:number)=> {
		if (new Date().getDate() === d &&
		 this.view_month === new Date().getMonth() &&
		 this.view_year === new Date().getFullYear()) return true;
		else return false;

	};

	is_active = (d:number, dir=0)=> {
		let [check_year, check_month] = move_month(this.view_year, this.view_month, dir);
		let is_picked_date = this.picked_day === d && this.picked_month == check_month && this.picked_year == check_year;

		if (is_picked_date) return true;
		else return false;
	};

	get_month() {
		return language.get_month(this.view_month);
	}

	get_year() {
		let year;
		if (this.view_year != null) {
			year = this.view_year;
		} else {
			year = new Date().getFullYear();
			this.picked_year = year;
			this.view_year = this.picked_year;
		}

		return year;
	}

	get_months():number[] {
		let months = [];
		for(let i = 0; i< 12; i++) {
			months.push(i)
		};

		return months;
	}

	get_years() {
		let this_y = new Date().getFullYear();
		let max = this_y + 3;
		let min = this_y - 20;
		let years = [];

		for (let i = max; i >= min; i--) {
		  years.push(i)
		}
		return years.reverse()
	}

	switch_month(dir) {
		[this.view_year, this.view_month] = move_month(this.view_year, this.view_month, dir);
		this.update_values();
		this.close_picking_popover();
	}


	update_month(new_month:number) {
		if (new_month < 0 || new_month > 11) return;
		this.picked_month = new_month;
		this.update_values();
		this.in_dateChange.emit(this.picked_date);
	}

	update_year(event: Event) {
		let new_year = (event.target as HTMLInputElement).value;
		if (new_year == null) return;

		console.log(new_year);

		this.picked_year = +new_year;
		this.view_year = this.picked_year;
		this.update_values();
		this.in_dateChange.emit(this.picked_date);
	}

	update_values = ()=> {
		this.set_days_in_month();
		this.update_picked_date();

	}

	set_days_in_month() {
		let this_month = new Date(this.view_year, this.view_month, 15);
		let last_month = date.subtract(this_month, date.duration({ date: 30 }));

		this.days_in_month = date.days_in_month(this_month);
		this.first_day_offset = date.first_day_of_month(this_month);
		if (this.first_day_offset === 0) this.first_day_offset = 7;
		this.days_in_last_month = date.days_in_month(last_month);
	}

	select_day(day:number, dir) {
		this.switch_month(dir);


		if(this.disable_date(day))return;

		this.picked_day = day;
		this.picked_month = this.view_month;
		this.picked_year = this.view_year;

		this.update_values();
		this.in_dateChange.emit(this.picked_date);
		this.submit_date.emit(this.picked_date);

	}

	update_picked_date() {
		if (this.picked_day && this.picked_month!==null && this.picked_year) {

			let hours = this.picked_date.getHours();
			let minutes = this.picked_date.getMinutes();
			this.picked_date = new Date(this.picked_year, this.picked_month, this.picked_day, hours, minutes)
		}

	}


	ngOnInit() {

		language.load_translations(date_time_translations);
		this.set_days_in_month();

	}
}


