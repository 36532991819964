import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { User } from '@task-modules/users';

@Component({
    selector: 'task-create-edit-location-group-dialog',
    template: `
        <task-create-edit-location-group [location_group]="location_group" (result)="handle_result($event)"></task-create-edit-location-group>
    `
})
export class CreateEditLocationGroupDialog extends ModalBase<Task.LocationGroup, Task.LocationGroup> implements OnInit {
    preventSoftClose = true;
    location_group: Task.LocationGroup;

    handle_result(location_group) {
        if(location_group) {

            let prom:Promise<any>;
            let payload = {...location_group};

            if (location_group.location_group_users) {
                payload.user_ids = location_group.location_group_users.map(u=>u.user_id);
            }

            if (location_group.location_group_id) {
                prom = TaskRoutes.location_groups.api_location_group_modify(this.injectedData.location_group_id, payload);

                /**
                    this is front-end hack for backend: add nfc to all locations in a group manually if checked.
                */
                if (User.feature_flag_enabled('nfcs') && payload.nfc) {
                    prom = prom.then(async(res)=>{
                        await TaskRoutes.location_groups.api_location_group_has_nfc(this.injectedData.location_group_id, {nfc: true});
                        return res;
                    })
                }

            } else {
                prom = TaskRoutes.location_groups.api_location_group_add(payload);

                 /**
                    this is front-end hack for backend: add nfc to all locations in a group manually if checked.
                */
                if (User.feature_flag_enabled('nfcs') && payload.nfc) {
                    prom = prom.then(async res=>{
                        await TaskRoutes.location_groups.api_location_group_has_nfc(res.location_group_id.location_group_id, {nfc: true});
                        return res;
                    })
                }
            }

            prom.then(res=>{
                this.submit(location_group)
            })

        } else {
            this.submit(location_group)
        }
    }

    ngOnInit() {
        this.location_group = this.injectedData;

    }
}
