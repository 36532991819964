<div class="main-page-wrapper" *ngIf="arrival && order">

	<!-- Arrival header section -->
	<header class="arrival-header">
		<div class="flex-column">
			<!-- Arrival main action area -->
			<div class="flex-row justify-center" style="margin-bottom: 0.25em">
				<button class="filled pill"
					color="primary"
					style="padding: .8em 1.4em; letter-spacing: 0.07em;"
					*ngIf="canStartArrival"
					(click)="show_qr_code()">
					<i icon="qr_code_scanner"></i>
					&nbsp;
					QR -
					<strong *ngIf="arrival.status_id == 1">
						{{ 'ARRIVAL.START' | trans }}
					</strong>
					<strong *ngIf="arrival.status_id == 6">
						{{ 'ARRIVAL.END' | trans }}
					</strong>
				</button>
			</div>

			<!-- Arrival actions -->
			<div class="flex-row justify-space-between wrappable" style="padding: 0 0 1em 0; margin-left: auto;">
				<button class="filled round"
					[disabled]="edited"
					*ngIf="can_complete"
					color="primary"
					title="{{ 'ARRIVAL.COMPLETION' | trans }}"
					(click)="complete_arrival()">
					<i icon="fact_check"></i>
				</button>

				<button *ngIf="canGetPDF" class="round filled" color="secondary" title="{{ 'PDF.ARRIVALS' | trans }}" (click)="get_report()">
					<i icon="picture_as_pdf"></i>
				</button>

				<button class="round filled" color="warn"
					[disabled]="edited"
					title="{{ 'ARRIVAL.DELETE.TITLE' | trans }}"
					*ngIf="can_delete"
					(click)="delete_arrival()">
					<i icon="delete"></i>
				</button>

				<button class="round filled" color="warn"
					[disabled]="edited"
					title="{{ 'ARRIVAL.DELETE.TITLE' | trans }}"
					*ngIf="!can_delete && can_admin_delete"
					(click)="admin_delete_arrival()">
					<i icon="delete_forever"></i>
				</button>

			</div>
		</div>
	</header>

	<!-- Page sections start here -->
	<section class="single-page-section grid-layout grid-entity-header">
		<div class="grid-card main-info-card">
			<div class="card-header">
				<h3 class="card-header-title">{{'ARRIVAL' | trans }}</h3>
			</div>
			<div class="card-body flex-column" style="gap: .5em;">
				<task-entity-info
					[title]="{text: get_arrival_title()}"
					[properties]="{
						'LOCATION': get_location(),
						'SUPPLIER': arrival.order.supplier_name,
						'ARRIVAL.CREATED_BY': arrival | fullname : 'created_by',
						'CREATED_DATE': arrival.created_date | datefmt : 'short_date_time' : false,
						'ARRIVAL.PLAN.DATE': (arrival.start_time || arrival.planned_start_time) | date_multifmt : 'short_date_time' : 'R',
						'ARRIVAL.PLAN.END_DATE': arrival.end_time | date_multifmt : 'short_date_time' : 'R',
						'ARRIVAL.ENDED_BY': arrival | fullname : 'ended_by' : arrival.ended_without_qr
					}"
					[status]="get_arrival_status(arrival)"
					[tiles]="get_arrival_tiles | fn : arrival"></task-entity-info>
			</div>

		</div>

		<!-- Workers card -->
		<div class="grid-card workers-card">
			<div class="card-header">
				<h3 class="card-header-title">{{'ARRIVAL.WORKERS' | trans }}</h3>
			</div>
			<div class="card-body">
				<div class="flex-column" style="gap: .6em">
					<span class="flex-row" *ngFor="let worker of arrival.arrival_workers" style="gap: .6em;">
						<i icon="person"></i>
						<span> {{ worker.first_name}} {{ worker.last_name}}</span>
						<span *ngIf="worker.work_hour_type_id">({{ "USER.QUALIFICATION."+ WHT[worker.work_hour_type_id] | trans }})</span>
					</span>
				</div>
			</div>
		</div>

		<!-- Tickets/orders card -->
		<div class="grid-card linked-entity-card">
			<ng-container *ngIf="canSeeTickets">
				<div class="card-header">
					<h3 class="card-header-title">{{ 'ARRIVAL.RELATED.TICKETS' | trans }}</h3>
				</div>
				<div class="card-body flex-column wrappable align-flex-start" style="margin-bottom: 1.5rem; gap: .6em;">
					<ng-container *ngFor="let ticket of tickets">
						<button class="filled pill flex-row justify-flex-start align-flex-start"
								color="secondary"
								[goto]="'/tickets/' + ticket.ticket_id">
							<b> #{{ticket.ticket_id}}</b> &nbsp;
							<i class="truncate-btn-text">{{ticket.title}}</i>
						</button>
					</ng-container>
				</div>
			</ng-container>

			<div class="card-header">
				<h3 class="card-header-title">{{ 'ARRIVAL.RELATED.ORDER' | trans }}</h3>
			</div>
			<div class="card-body">
				<button class="filled pill flex-row justify-flex-start align-flex-start"
						color="secondary"
						[goto]="'/orders/' + order.order_id">
					<b> #{{order.order_id}}</b> &nbsp;
					<i class="truncate-btn-text">{{order.title}}</i>
				</button>

			</div>
		</div>
	</section>

	<!-- Accordion s nalozima -->
	<section class="single-page-section single-page-accordion">
		<details id="order-details">
			<summary>
				<h2>{{"ORDER.ORDER.DETAILS" | trans}}</h2>
			</summary>
			<div class="grid-layout grid-accordion-details" style="margin-bottom: 1rem;">
				<div class="grid-card main-info-card">
					<div class="card-header">
						<h3 class="card-header-title">{{'ORDER' | trans }}</h3>
						<button title="Idi na nalog" class="round filled" color="secondary" [goto]="'/orders/' + order.order_id" style="margin-left: auto;"><i icon="east"></i></button>
					</div>
					<div class="card-body flex-column" style="gap: .5em;">
						<task-entity-info
							[title]="{text: get_order_title(), url: '/orders/' + order.order_id}"
							[properties]="{
								'LOCATION': get_location(),
								'SUPPLIER': order.supplier_name,
								'DESCRIPTION': get_order_description | fn : order.order_details,
								'CREATED_DATE': order.created_date | datefmt : 'short_date_time' : false,
                        'CREATED_BY': (order | fullname : 'created_by'),
								'TICKET.ASSIGNED_TO': get_order_assigned(),
								'ORDER.APPROVED_BY': order | fullname : 'approved_by',
								'ORDER.APPROVED_DATE': order.status_id == 7 ? (order.status_date | datefmt : 'short_date_time' : false) : undefined,
								'TICKET.REJECTED_REASON': get_order_rejection(),
								'ORDER.REVERT.REASON': order_revert_reason(),
								'USER.TYPE.COMPANY_WORKER': get_order_worker(),
								'ORDER.INVESTMENT_TYPE': get_order_investment_type(),
								'ORDER.COST_ESTIMATE': get_cost_estimate | fn : order,
								'ORDER.INVOICE_NO': order.invoice_no || undefined,
								'NAVROUTE.WORKHIER': order.whier
							}"
							[status]="get_order_status(order)"
							[tiles]="get_order_tiles | fn : order">
						</task-entity-info>
					</div>
				</div>

				<!-- Inventory component -->
				<div class="grid-card inventory-card" *ngIf="order_inventory.length > 0">
					<div class="card-header">
						<h3 class="card-header-title">{{"ARRIVAL.INVENTORY" | trans}}</h3>
					</div>
					<div class="card-body">
						<task-inventory-cards
							[disabled]="true"
							[location_cin]="order.location_cin"
							[ngModel]="order_inventory"
							[in_grid]="true"
						></task-inventory-cards>
					</div>
				</div>

				<!-- Images component -->
				<div class="grid-card images-card" *ngIf="order_images.length > 0">
					<div class="card-header">
						<h3 class="card-header-title">{{"ARRIVAL.IMAGES" | trans}}</h3>
					</div>

					<div class="card-body">
						<task-multi-image-new
						[(ngModel)]="order_images"
						[disabled]="true"
						[in_grid]="true"></task-multi-image-new>
					</div>
				</div>

				<!-- Documnets component -->
				<div class="grid-card files-card" *ngIf="order_financial_files.length > 0 || order_service_files.length > 0">
					<div class="card-header">
						<h3 class="card-header-title">{{"ARRIVAL.DOCUMENTS" | trans}}</h3>
					</div>
					<div class="card-body">
						<h4 *ngIf="order_financial_files.length > 0" class="card-header-title" style="margin-top: 1em;">
							{{ 'FINANCIAL_FILES' | trans }}
						</h4>
						<task-multi-files
							[(ngModel)]="order_financial_files"
							[allow-deleting-uploaded]="false"
							[download_file]="download_file"
							[in_grid]="true"
						></task-multi-files>

						<h4 *ngIf="order_service_files.length > 0" class="card-header-title" style="margin-top: 1em;">
							{{ 'FILES' | trans }}
						</h4>
						<task-multi-files
							[(ngModel)]="order_service_files"
							[allow-deleting-uploaded]="false"
							[download_file]="download_file"
							[in_grid]="true"
						></task-multi-files>
					</div>
				</div>
			</div>
		</details>
	</section>


	<!-- Arrival details, was on separate layout before -->
	<section class="single-page-section" id="arrival-details" form-collector #arrivalDetailsForm="collector" >
		<div class="sticky-header" >
			<h2>{{"ARRIVAL.DETAILS" | trans}}</h2>
			<div class="flex-row wrappable justify-flex-end" style="gap: .5em;" *ngIf="edited">
				<ng-container *ngIf="can_edit_detail">
					<button class="enlarge-btn pill filled"
						color="secondary"
						[disabled]="arrivalDetailsForm.containsErrors() || ('edit-arrival-details-save' | inProgress)"
						(click)="save()"
					>
						<i icon="save"></i><span class="screen-big">{{ "SAVE" | trans }}</span>
					</button>

					<button class="enlarge-btn outlined pill" color="primary" (click)="cancel()">
						<i icon="cancel"></i><span class="screen-big">{{ "CANCEL" | trans}}</span>
					</button>
				</ng-container>
			</div>
		</div>

		<div class="grid-layout grid-entity-detilas" style="margin-bottom: .6rem;">

			<!-- Arrival textual details -->
			<div class="grid-card text-details-card">
				<div class="card-header">
					<h3 class="card-header-title">{{"DESCRIPTION" | trans}} / {{"COMMENT" | trans}}</h3>
					<button *ngIf="can_edit_detail"
						class="add-btn round filled"
						color="primary"
						(click)="open_details_text_menu()"
					>
						<i icon="add"></i>
					</button>
				</div>

				<div class="card-body" *ngIf="arrival" #container>
					<div class="flex-column" style="gap: 1rem;">
						<label class="input"
								title="HIDDEN IMAGE CONTAINER"
								style="display: none">
							<input [ngModel]="ar_details.length" type="number"/>
						</label>

						<!-- DODANI OPIS/RAZLOG/KOMENTAR ELEMENT SA PROPSIMA ZA PRIKAZ-->
						<ng-container *ngFor="let detail of ar_details; let index = index" [ngSwitch]="detail.arrival_detail_type_id">
							<ng-container *ngIf="detail['is_created'] && check_detail_type | fn : detail">
								<div class="grid-card flex-row wrappable" [style.background-color]="detail['is_deleted'] ? 'rgb(var(--warn), 0.2)' : ''" *ngIf="detail.arrival_detail_type_id !== 3">
									<div class="flex-dynamic">
										<h4 class="card-header-title" *ngSwitchCase="1">{{'ARRIVAL.DETAIL.WORK_DESCRIPTION' | trans}}: </h4>
										<h4 class="card-header-title" *ngSwitchCase="2">{{'ARRIVAL.DETAIL.WORK_COMMENTARY' | trans}}: </h4>
										<h4 class="card-header-title" *ngSwitchCase="4">{{'ARRIVAL.DETAIL.WORK_UNFINISHED_REASON' | trans}}: </h4>
										<div class="flex-row wrappable text-details-uneditable">
											<strong>{{detail | fullname : "created_by"}}: </strong>
											<span>"{{ detail.description }}"</span>
											<div class="flex-row wrappable">
												<strong>{{"TIME" | trans}}: </strong>
												<span>{{ detail.created_date | datefmt : "short_date_time" : false }}</span>
											</div>
										</div>
									</div>
									<button *ngIf="can_delete_detail" class="round align-self-baseline" color="warn"
											(click)="toggle_delete_detail(detail)">
										<i [attr.icon]="detail['is_deleted'] ? 'undo' : 'delete'"></i>
									</button>
								</div>
							</ng-container>
						</ng-container>
						<!-- OPIS/KOMENTAR/RAZLOG ELEMENT KOJI DODAJEMO -->
						<ng-container *ngFor="let detail of ar_details; let index = index" [ngSwitch]="detail.arrival_detail_type_id">
							<ng-container *ngIf="detail['is_edited'] && check_detail_type | fn : detail">
								<div class="grid-card flex-row wrappable" *ngIf="detail.arrival_detail_type_id !== 3">
									<div class="flex-dynamic">
										<h4 class="card-header-title" *ngSwitchCase="1">{{'ARRIVAL.DETAIL.WORK_DESCRIPTION' | trans}}: </h4>
										<h4 class="card-header-title" *ngSwitchCase="2">{{'ARRIVAL.DETAIL.WORK_COMMENTARY' | trans}}: </h4>
										<h4 class="card-header-title" *ngSwitchCase="4">{{'ARRIVAL.DETAIL.WORK_UNFINISHED_REASON' | trans}}: </h4>
										<!-- Ostali arraival tipovi -->
										<label *ngIf="!detail.arrival_detail_id && detail.arrival_detail_type_id !== 3" class="textarea" [id]="'detail_' + detail.arrival_detail_id">
											<textarea [(ngModel)]="detail.description"
												required
												(ngModelChange)="edited=true"></textarea>
										</label>
									</div>
									<button *ngIf="can_delete_detail" class="round align-self-baseline" color="warn"
											(click)="toggle_delete_detail(detail)">
										<i [attr.icon]="detail['is_deleted'] ? 'undo' : 'delete'"></i>
									</button>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>

			<!-- Images component -->
			<div class="grid-card images-card">
				<div class="card-header">
					<h3 class="card-header-title">{{'ARRIVAL.IMAGES' | trans}}</h3>
					<button class="add-btn round filled" color="primary" (click)="image.get_images()" *ngIf="can_edit_detail">
						<i icon="add"></i>
					</button>
				</div>
				<div class="card-body">
					<task-multi-image-new
						#image
						[(ngModel)]="arrival_images"
						(ngModelChange)="arrival_images=$event;edited=true"
						[disabled]="false"
						[allow-deleting-uploaded]="can_edit_detail"
						[in_grid]="true">
					</task-multi-image-new>
				</div>
			</div>


			<!-- Documnets component -->
			<div class="grid-card files-card">
				<div class="card-header">
					<h3 class="card-header-title">{{'ARRIVAL.DOCUMENTS' | trans}}</h3>
					<button class="add-btn round filled" color="primary" (click)="file.get_files_for_upload()" *ngIf="can_edit_detail_type(5) && can_edit_detail">
						<i icon="add"></i>
					</button>
				</div>
				<div class="card-body">
					<task-multi-files
						#file
						[(ngModel)]="arrival_files"
						(ngModelChange)="arrival_files=$event;edited=true"
						[allow-deleting-uploaded]="true"
						[type-of-files]="'application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/png, image/jpeg, message/rfc822, application/vnd.ms-outlook'"
						[download_file]="download_file"
						[disabled]="!can_edit_detail"
						[in_grid]="true"
					></task-multi-files>
				</div>
			</div>
		</div>

		<!-- Editable table component -->
		<div class="grid-card costs-card">
			<div class="card-header">
				<h3 class="card-header-title">{{'ARRIVAL.COST.SHEET' | trans}}</h3>
				<button class="add-btn round filled"
					color="primary"
					*ngIf="can_edit_detail && can_edit_detail_type(3)"
					(click)="open_details_text_menu('WORK_ELEMENT')"
				>
					<i icon="add"></i>
				</button>
			</div>

			<div class="card-body" form-collector  #work_element="collector">
				<div  class="table-container">
					<table>
						<thead>
								<tr class="row header-row">
								@for (head of headers; track $index) {
									<th [ngClass]="{
										'is-centered': head.centered,
										'is-right': head.is_right,
									}">
										<div class="header-cell"
											[style.justify-content]="head.key==='delete' ? 'flex-end': null">
												{{ head.name | trans }}
												<i *ngIf="head.icon" [attr.icon]="head.icon || ''" style="margin-left: .5em;"></i>
										</div>
									</th>
								}
								</tr>
						</thead>

						<tbody>
							@if (!are_work_els()) {
								<tr style="border-bottom: none">
									<td [attr.colspan]="headers.length">
										<p style="text-align: center;">{{ "ARRIVAL.DETAIL.NO_FIN_DETAILS" | trans }}</p>
									</td>
								</tr>

							} @else {
								@for (detail of ar_details; track detail_i; let detail_i=$index) {
									@if (detail.arrival_detail_type_id == 3) {

										<tr class="row"
											[class.is-deleted-soft]="detail.is_created && work_el_is_deleted(detail)== 'soft'"
											[class.is-deleted-hard]="detail.is_created && work_el_is_deleted(detail)=='hard'"
										>
											@for (header of headers; track col_i; let col_i = $index) {

												<td [ngClass]="{
													'is-centered': header.centered,
													'is-right': header.is_right
												}">

													@switch (header.key) {
														@case ('row-number') {
															<div class="row-counter"></div>
														}

														@case ('work_description') {
															@if (detail.arrival_detail_id !== undefined) {
																<div>
																	{{detail[header.key] || ''}}
																</div>

															} @else {
																<div>
																	@if (detail.tab == 'troskovnicka') {
																		<knw-auto-search
																			[label]="'ARRIVAL.DETAIL.GROUP' | trans"
																			classes="outlined"
																			[function]="make_serach_group(true)"
																			[to-label]="format_group"
																			[allow-null]="true"
																			[(ngModel)]="detail.work_desc"
																			(ngModelChange)="reset_work_element(detail); edited=true;"
																			[popover-width]="'auto'"
																		></knw-auto-search>

																	} @else {
																		<knw-auto-search
																			[label]="'ARRIVAL.DETAIL.GROUP' | trans"
																			classes="outlined"
																			[function]="make_serach_group(false)"
																			[to-label]="format_group"
																			[(ngModel)]="detail.work_desc"
																			(ngModelChange)="write_work_description(detail, $event); edited=true;"
																			[popover-width]="'auto'"
																			required
																		></knw-auto-search>
																	}
																</div>
															}
														}

														@case ('element_name') {
															@if (detail.arrival_detail_id !== undefined) {
																<div class="flex-row align-center" style="gap: .3em;">
																	<div
																		class="add-btn tooltip-icon"
																		style="
																			background-color: rgb(var(--primary));
																			color: white
																		"
																		[attr.tooltip]="'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT'|trans"
																		[style.background-color]="detail.element_quantity == 0 ? 'rgb(140,140,140)' : 'rgb(var(--primary))'"
																		*ngIf="!detail.element_id"
																		(click)="$event.stopPropagation()"
																	>VS</div>
																	<strong>{{detail[header.key]}}</strong>
																</div>

															} @else {
																<div>
																	@if (detail.tab == 'troskovnicka') {
																		<knw-auto-search
																			#searchPicker
																			[label]="'ARRIVAL.DETAIL.WORK_ELEMENT' | trans"
																			classes="outlined"
																			style="display: contents"
																			[function]="make_search_pricelist(detail.work_desc)"
																			[to-label]="format_pricelist"
																			[(ngModel)]="detail.element"
																			(ngModelChange)="set_element(detail, $event); edited=true;"
																			[popover-width]="'auto'"
																			required>
																		</knw-auto-search>

																	} @else {
																		<label class="input outlined" [title]="'ARRIVAL.DETAIL.NONPRICELIST_ELEMENT' | trans">
																			<input required type="text" [(ngModel)]="detail.element_name" (ngModelChange)="edited=true">
																		</label>
																	}
																</div>
															}
														}

														@case ('element_cin') {
															@if (detail.arrival_detail_id !== undefined || detail.tab == 'troskovnicka') {
																<div>
																	{{ detail[header.key] || '-' }}
																</div>

															} @else {
																<div>
																	<label class="input outlined" [title]="'SUPPLIER.CIN' | trans">
																		<input type="text" [(ngModel)]="detail.element_cin" (ngModelChange)="edited=true">
																	</label>
																</div>
															}
														}

														@case ('element_type_id') {
															@if (detail.arrival_detail_id !== undefined || detail.tab == 'troskovnicka') {
																<div>
																	{{ show_work_element_type(detail[header.key]) }}
																</div>

															} @else {
																<div>
																	<label class="input outlined" title="{{ 'PRICELIST.TYPE' | trans }}">
																		<select [(ngModel)]="detail.element_type_id" required (ngModelChange)="edited=true">
																			<option *ngFor="let type of ELEMENT_TYPES | keyvalue"
																					[ngValue]="+type.key">
																				{{ ("PRICELIST.TYPE." + type.value) | trans }}
																			</option>
																		</select>
																	</label>
																</div>
															}
														}

														@case ('element_uom') {
															@if (detail.arrival_detail_id !== undefined || detail.tab == 'troskovnicka') {
																<div>
																	{{ detail[header.key] || '-'}}
																</div>

															} @else {
																<div>
																	<label class="input outlined" [title]="'ARRIVAL.DETAIL.UOM' | trans">
																		<select required [(ngModel)]="detail.element_uom" (ngModelChange)="edited=true">
																			<option *ngFor="let uom of uom_types | keyvalue" [ngValue]="uom.key">{{ ("PRICELIST.UOM." + uom.value) | trans }} <span *ngIf="uom.key !==''">({{uom.key}})</span></option>
																		</select>
																	</label>
																</div>
															}
														}

														@case ('element_quantity') {
															@if (detail.arrival_detail_id !== undefined) {
																<div>
																	{{detail[header.key] ?? '-'}}
																</div>

															} @else {
																<div>
																	@if (detail.tab == 'troskovnicka') {
																		@if (detail.element) {
																			<label class="input outlined" [title]="'ARRIVAL.DETAIL.ELEMENT_QUANTITY' | trans">
																				<input type="number"
																					min="0"
																					required
																					[(ngModel)]="detail.element_quantity"
																					(ngModelChange)="edited=true"
																				/>
																					{{ detail.element_uom }}
																			</label>
																		} @else {
																			-
																		}
																	} @else {
																		<label class="input outlined" [title]="'ARRIVAL.DETAIL.ELEMENT_QUANTITY' | trans">
																			<input
																				required
																				type="number"
																				[(ngModel)]="detail.element_quantity"
																				(ngModelChange)="edited=true"
																				min="0"
																			>
																		</label>
																	}
																</div>
															}
														}

														@case ('element_price') {
															@if (can_see_individual_prices) {
																@if (detail.arrival_detail_id !== undefined || detail.tab == 'troskovnicka') {
																	<div style="min-width: 75px;">
																		<task-price [price]="detail[header.key]" [label]="false" [currency]="detail.element_currency || 'EUR'"></task-price>
																	</div>

																} @else {
																	<div>
																		<label class="input outlined" [title]="('ARRIVAL.DETAIL.ELEMENT_PRICE' | trans) + '(' + (detail.element_currency) + ')'">
																			<input required type="number" [(ngModel)]="detail.element_price" min="0" (ngModelChange)="edited=true">
																		</label>
																	</div>
																}

															}
														}

														@case ('element_total_price') {
															<div style="min-width: 75px;">
																@if (can_see_individual_prices) {
																	<strong>
																		<task-price [lump_sum]="detail.lump_sum" [label]="false" [price]="(detail.element_price || 0) * (detail.element_quantity || 0)" [currency]="detail.element_currency"></task-price>
																	</strong>
																}
															</div>
														}

														@case ('lump_sum') {
															@if (detail.arrival_detail_id !== undefined || detail.tab == 'troskovnicka') {
																<div>
																	@if (detail.lump_sum) {
																		<i icon="check"></i>
																	} @else {
																		<span>-</span>
																	}
																</div>

															} @else {
																<div>
																	<label class="checkbox-input">
																		<input type="checkbox" [(ngModel)]="detail.lump_sum" (ngModelChange)="edited=true"/>
																	</label>
																</div>
															}
														}

														@case ('delete') {
															<div>
																@if (can_delete_detail && work_el_is_deleted(detail) !== 'hard') {
																	<button
																		class="round align-self-baseline"
																		color="warn"
																		(click)="toggle_delete_detail(detail)"
																	>
																		<i [attr.icon]="detail['is_deleted'] ? 'undo' : 'delete'"></i>
																	</button>
																}
															</div>
														}

														@default {
															<div>
																{{ detail[header.key] ?? '-'}}
															</div>
														}
													}
												</td>
											}

										</tr>
									}
								}
							}


						</tbody>
					</table>
				</div>

				<!-- total price -->
				<div *ngIf="show_currency" class="total-price">
					<ng-container *ngFor="let sum of currency_sums | keyvalue">
						<task-price [label]="'ORDER.TOTAL'" [currency]="sum.key" [price]="sum.value || 0"></task-price>
					</ng-container>
				</div>

			</div>
		</div>
	</section>

	<!-- Comments section -->
	<section class="single-page-section" style="margin-bottom: 0;">
		<h2>{{ 'COMMENTS' | trans }}</h2>
		<task-new-comments [entity]="entity" [entity_id]="arrival.arrival_id"></task-new-comments>
	</section>



</div>