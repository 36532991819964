import { type language } from 'curvy';

type TransDef = language.TranslationDefinition;

function titlecase_conditional(str: string) {
	if (str[0] === str[0].toUpperCase() && str[1] === str[1].toUpperCase()) {
		return str;
	} else {
		return str[0].toLowerCase() + str.slice(1);
	}
}

const english: TransDef = {
	locale: 'en-US',
	translations: {
		'SRFILTER.SEARCHBY': 'Search...',
		'SRFILTER.YES': 'Yes',
		'SRFILTER.NO': 'No',
		'SRFILTER.ANY': 'Any',
	}
};

const croatian: TransDef = {
	locale: 'hr-HR',
	translations: {
		'SRFILTER.SEARCHBY': 'Pretraži...',
		'SRFILTER.YES': 'Da',
		'SRFILTER.NO': 'Ne',
		'SRFILTER.ANY': 'Svejedno',
	}
};

export const srfilter_translations: TransDef[] = [english, croatian];