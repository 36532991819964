import { environment } from 'environments/environment';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Frame, noOp, language, TableConfig, template, GenericFilterComponent, network, GenericFilter, DataTableComponent, modal, utils, color, ConfirmDialogComponent } from 'curvy';
import { Task } from '@task-utils/types';
import { Templates } from '@task-utils/utils';
import { CreateEditUserDialog } from '@task-modules/users/create-edit-user.dialog';
import { UserTakeoverDialog } from '@task-modules/users/user-takeover-dialog.component';
import { users_translations } from './users.trans';
import { User } from './users';
import { Sectors } from '@task-modules/sectors/sectors';
import { TaskRoutes } from '@task-utils/routes';


@Component({
    selector: 'task-users',
    template: `
        <div class="temp-add-button" *ngIf="can_create">
            <button (click)="add_user()"
                class="filled pill"
                color="primary"
            >
                <i icon="add_circle"></i>
                <span>{{ 'USER.ADD_NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #userstable [config]="tableConfig"></knw-data-table>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-users {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }
    `]
})
export class UsersComponent {

    @ViewChild('userstable') table: DataTableComponent<Task.User, DisplayUser, 'users', GenericFilterComponent>;

    can_create = false;

    constructor() {
        Frame.set({
            title: "NAVROUTE.USERS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(users_translations);

        User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.SUPPLIER_ADMIN
        ).then(res=>{
            this.can_create = res;
        })
    }


    add_user() {
        modal.open(CreateEditUserDialog, (res) => {
            if (res) {
                this.table.getData();
            }
        })
    }

    async search_companies(search: string) {
      let res = await TaskRoutes.companies.api_company_search(
         { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
         { page_no: 1, page_size: 10 }
      )

      return res.data;
   }

   format_company_name(comp: Task.Company) {
      if (!comp) return "";
      return comp.name;
   }

    tableConfig: TableConfig<Task.User, DisplayUser, 'users', GenericFilterComponent> = {
        tableName: 'users',
        getData: async (filter, paginator, sort_header) => {
            let parsed_filter = { search: [], attributes: [], sort: null };

            for (let key in filter) {
                if(key === 'company' && filter['company'] !== null) {
                    parsed_filter.attributes.push('#=:company_id')
                    parsed_filter.search.push(filter[key].company_id)
                }
                else {
                    parsed_filter.attributes.push(key);
                    parsed_filter.search.push(filter[key]);
                }
            }
            // SHOW ONLY ACTIVE USERS
            parsed_filter.attributes.push('#:status_id');
            parsed_filter.search.push(1);

            if (sort_header) {
                switch (sort_header.label) {
                    case 'name':
                        sort_header.label = 'last_name';
                        break;
                    case 'created':
                        sort_header.label = 'd:created_date';
                        break;
                    case 'usertype':
                        sort_header.label = '#=:user_type_id';
                        break;
               }
                parsed_filter.sort = sort_header;
            }
            let users = await TaskRoutes.users.api_user_search(parsed_filter, paginator);
            // return User.search(paginator, parsed_filter);
            return users;
        },
        getHeader: (header) => {

            switch (header) {
                case 'name':
                    return { icon: "person", label: "USER", sortable: true };

                case 'contact':
                    return { icon: "email", label: "EMAIL", sortable: false };

                case 'created':
                    return { icon: "person_add", label: "CREATED_BY", sortable: true };

                case 'usertype':
                    return { icon: "people", label: "USER.TYPE", sortable: true };

            }
        },
        unwrapData: (user) => {
            return {
                name: Templates.user(user),
                contact: Templates.email(user.email),
                created: Templates.created_by(user),
                usertype: template.transform(`
                    <div class="flex-column">
                        {{ type }}
                    </div>
                `,  {
                    type: language.translate( "USER.TYPE." + Task.User_Type[user.user_type_id] )
                })
            };
        },
        filterComponent: GenericFilterComponent,
        filterInput: async () => {
            let filters: GenericFilterComponent["filterInput"] = [
                GenericFilter.text("SEARCH", "first_name+ +last_name+ +email"),
                GenericFilter.dropdown("USER.TYPE", "#=:user_type_id", [
                    { label: "USER.TYPE.SYSTEM_ADMIN", value: Task.USER_TYPE.SYSTEM_ADMIN },
                    { label: "USER.TYPE.ADMIN", value: Task.USER_TYPE.ADMIN },
                    { label: "USER.TYPE.SUPER_USER", value: Task.USER_TYPE.SUPER_USER },
                    { label: "USER.TYPE.AUTHORIZED_PERSONEL", value: Task.USER_TYPE.AUTHORIZED_PERSONEL },
                    { label: "USER.TYPE.REGIONAL_MAINTENANCE_MANAGER", value: Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER },
                    { label: "USER.TYPE.REGIONAL_RETAIL_MANAGER", value: Task.USER_TYPE.REGIONAL_RETAIL_MANAGER },
                    { label: "USER.TYPE.STORE_MANAGER", value: Task.USER_TYPE.STORE_MANAGER },
                    { label: "USER.TYPE.SUPPLIER_ADMIN", value: Task.USER_TYPE.SUPPLIER_ADMIN },
                    { label: "USER.TYPE.SUPPLIER_WORKER", value: Task.USER_TYPE.SUPPLIER_WORKER },
                    { label: "USER.TYPE.COMPANY_WORKER", value: Task.USER_TYPE.COMPANY_WORKER },
                    { label: "USER.TYPE.SAFETY_MANAGER", value: Task.USER_TYPE.SAFETY_MANAGER },
                    { label: "USER.TYPE.MANAGER_1", value: Task.USER_TYPE.MANAGER_1},
                    { label: "USER.TYPE.MANAGER_2", value: Task.USER_TYPE.MANAGER_2}
                ]),

                // GenericFilter.dropdown("USER.STATUS", "#=:status_id", [
                //     { label: "STATUS.CREATED", value: 1 },
                //     { label: "STATUS.DELETED", value: 3 },
                // ]),
            ];


            if (await User.currentTypeIs(
                    Task.USER_TYPE.SYSTEM_ADMIN,
                    Task.USER_TYPE.ADMIN,
                    Task.USER_TYPE.SUPER_USER)
            ) {
                let sectors = await Sectors.getAll(null, false);
                let filter_sectors = sectors.data.map(sec => ({
                    label: sec.name,
                    value: sec.sector_id
                }));
                filters.push(
                    GenericFilter.dropdown("SECTOR", "#=:sector_id", filter_sectors)
                );
            }

            if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
               filters.push(GenericFilter.search("USER.COMPANY", "company", this.search_companies, this.format_company_name));
            }

            return filters;
        },

        maxActions: window.innerWidth < 600 ? 1 : 3,

        rowActions: [{
            label: language.translate("USER.TAKE_OVER_LOCATIONS"),
            icon: "sync",
            onClick: async (usr)=> {
                modal.open(UserTakeoverDialog, (res)=>{
                    if (res) {
                        this.table.getData();
                    }
                }, usr)
            },
            priority: 2,
            isVisible: async (user)=>{
                let is_admin = await User.currentTypeIs(
                    Task.USER_TYPE.ADMIN,
                    Task.USER_TYPE.SYSTEM_ADMIN
                );
                let is_retail_store_mng = user.user_type_id === Task.USER_TYPE.REGIONAL_RETAIL_MANAGER;
                let is_maintenance_mng = (user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER || user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER);
                let is_worker = user.user_type_id === Task.USER_TYPE.COMPANY_WORKER;
                // return is_admin && is_retail_store_mng;
                return is_admin && (is_retail_store_mng || is_maintenance_mng || is_worker);
            }
        }, {
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (usr) => {
                modal.open(CreateEditUserDialog, (res) => {
                    if (res) {
                        this.table.getData();
                    }
                }, usr);
            },
            priority: 1,
            isVisible: ()=>true
        }, {
            label: language.translate('DELETE'),
            icon: "delete",
            color: "warn",
            onClick: async (usr) => {
                modal.open(ConfirmDialogComponent, async (succ) => {
                    if (succ) {
                        await User.remove(usr);
                        this.table.getData();
                    }
                }, {
                    title: language.translate("USER.DELETE.TITLE"),
                    message: language.translate("USER.DELETE.MSG", usr.first_name, usr.last_name),
                    icon: "person",
                    iconColor: color.variable(color.Variable.warn)
                });
            },
            priority: 3,
            isVisible: async (user) => await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.SUPER_USER
            ) || user.status_id == 1
        },
        {
            label: "Spoof",
            icon: "badge",
            onClick: async (usr) => {
                await User.spoofUser(usr.user_id);
            },
            priority: 0,
            isVisible: async () => {
                let is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN);
                return is_admin && !environment.production;
            }
        }]
    }
}

type DisplayUser = {
    name: template.EscapedHTML,
    contact: template.EscapedHTML,
    created: template.EscapedHTML,
    usertype: template.EscapedHTML,
}
