import { Component, Input, OnInit, ViewEncapsulation, ViewChild, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { language, SearchPickerComponent, async, popover, notification, AutoSearchComponent, color, progress } from 'curvy';
import { components_trans } from './components.trans';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InventoryPickerComponent),
	multi: true
};


@Component({
	selector: 'task-inventory-picker',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="inventory-picker flex-column">
			<div class="flex-row" style="position: relative;">
			<knw-spinner
				[color]="'primary'"
				[size]="20"
				*ngIf="'paste_inv_numbers' | inProgress"
				style="position: absolute; right: 4px; width: 20px; height: 20px; top: 11px;" />
				<knw-auto-search
						#invpicker
						label="{{ 'COMP.INVENTORY' | trans }}"
						[function]="search_inventory"
						[multi]="true"
						[to-label]="format_kam_name"
						[(ngModel)]="picked_inventory"
						(ngModelChange)="add_inventory()"
						[disabled]="!location_cin || disabled"
						classes="outlined flex-dynamic"
						(paste)="on_paste($event)">
				</knw-auto-search>
			</div>

			<div class="flex-row wrappable inventory-items">
				<button
					*ngFor="let inv of picked_inventory"
					class="filled flex-row inventory-item"
					color="secondary"
					[attr.color]="(inv.inventory_warranty_period && !is_in_past(inv.inventory_warranty_period)) ? 'orange' : 'secondary'"
				>
					<div class="flex-column">
						<i *ngIf="inv.inventory_no">#{{ inv.inventory_no }}</i>
						<b style="white-space:break-spaces;">{{ inv.inventory_name }}</b>
						<i *ngIf="inv.inventory_serial_no">{{ inv.inventory_serial_no }}</i>
						<i class="flex-row align-center" *ngIf="inv.inventory_warranty_period && !is_in_past(inv.inventory_warranty_period)">
							<i icon="verified_user"></i>
							<strong>{{'WARRANTY_EXP' | trans}}:</strong> &nbsp;
							{{ inv.inventory_warranty_period | datefmt }}
						</i>
					</div>
					<button class="round"
							color="secondary-text"
							style="pointer-events: all"
							(click)="delete_inventory(inv)">
						<i icon="clear"></i>
					</button>
				</button>
			</div>
		</div>
	`,
	styleUrls: ['./components.scss'],
	providers: [ CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR ]
})
export class InventoryPickerComponent implements OnInit, ControlValueAccessor {
	@ViewChild('invpicker')
	invpicker: AutoSearchComponent;

	@ViewChild('invpicker', {read: ElementRef})
	invpicker_el: ElementRef<HTMLElement>


	picked_inventory: Task.KAM_Inventory[] = [];

	format_kam_name = format_kam_name;

	location_cin: string = null;
	get _location_cin() {
		return this.location_cin;
	}
	@Input('location_cin') set _location_cin(val: string) {
		let old_location_cin = this.location_cin;
		this.location_cin = val;
		if (this.location_cin == null || old_location_cin != this.location_cin) {
			this.picked_inventory = [];
			if (this.invpicker) {
				// this.invpicker.search_options = [];
				this.invpicker.writeValue(null);
			}
		}
	}

	@Input()
	classes = 'outlined';

	private on_changed;
	private on_touched;

	@Input()
	disabled = false;

	@Input()
	required = false;

	ngOnInit() {
		language.load_translations(components_trans);
		document.body.addEventListener("paste", this.on_paste);
	}

	ngOnDestroy() {
		document.body.removeEventListener("paste", this.on_paste);
	}

	is_in_past(date:Date | string) {
		let today = new Date().setHours(0,0,0,0);
		return new Date(date).setHours(0,0,0,0) < today;
	}

	add_inventory(do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }

		if (do_events && this.on_changed) {
			this.on_changed(this.picked_inventory);
		}
	}


	delete_inventory(inv: Task.KAM_Inventory, do_events=true) {
		if (do_events && this.on_touched) { this.on_touched(); }

		let index = this.picked_inventory.indexOf(inv);
		if (index != -1) {
			this.picked_inventory.splice(index, 1);

			if (do_events && this.on_changed) {
				this.on_changed(this.picked_inventory);
			}
		}
	}

	// picked_inventory: Task.KAM_Inventory = null;
	search_inventory = async (search: string) => {
		if (!this.location_cin) {
			return [];
		}

		let res = (await TaskRoutes.kam_data.api_kam_inventory_search({
			location_cin: this.location_cin,
			inventory_name: search || null,
			inventory_no: search ||  null,
			inventory_serial_no: search || null
		}, {page_no: 1, page_size: 50})) as TaskRoutes.Payload<Task.KAM_Inventory[]>;

		let out_data = [];
		for (let d of res.data) {
			if (!this.picked_inventory.some(i => i.inventory_id == d.inventory_id)) {
				out_data.push(d);

				if (out_data.length == 50) {
					break;
				}
			}
		}

		return out_data;
	}

	async load_inv_numbers(inv_numbers: string[]) {

		if (!Array.isArray(this.picked_inventory)) return;

		let promises = inv_numbers.map(no =>
			TaskRoutes.kam_data.api_kam_inventory_search(
				{
					location_cin: this.location_cin,
					inventory_name: no || null,
					inventory_no: no ||  null,
					inventory_serial_no: no || null
				}, {
					page_no: 1,
					page_size: 50
				}
			) as Promise<TaskRoutes.Payload<Task.KAM_Inventory[]>>
		);

		let res = (await progress.listen('paste_inv_numbers', Promise.all(promises))).filter(r=>r.data.length>0).map(r=>r.data[0]);

		let not_found = inv_numbers.filter(no=> {
			for (let inv of res) {
				if (inv.inventory_no == no) return false;
			}
			return true;
		});


		if (not_found.length > 0) {
			notification.show({
				title: language.translate('ADD_INVENTORY.TITLE'),
				message: not_found.length < 5 ?
					language.translate('ADD_INVENTORY.MESSAGE_FEW', not_found) :
					language.translate('ADD_INVENTORY.MESSAGE_PLENTY', not_found),
				color: color.Variable.warn,
				timeout: 0
			});
		};

		for (let inv of res) {
			let found = false;
			for (let picked of this.picked_inventory) {
				if (picked.inventory_no == inv.inventory_no) {
					found = true;
					break;
				}
			}

			if (!found) {
				this.picked_inventory.push(inv);
			}
		};


		if (!popover.host) return;
		if (!popover.host.popoverOpen) return;
		if (!popover.host.currentPopover) return;
		if (!(popover.host.popoverPosition instanceof HTMLElement)) return;
		if (!this.invpicker_el.nativeElement.contains(popover.host.popoverPosition)) return;
		this.invpicker.open_menu();
	}

	on_paste = (e: ClipboardEvent) => {
		if (progress.is_in_progress('paste_inv_numbers')) {
			e.preventDefault();
			return;
		};

		if (!e.clipboardData.types.includes("text/html")) return;
		if (!(e.currentTarget instanceof Element)) return;

		if (!this.invpicker_el.nativeElement.contains(e.currentTarget)) {
			if (!popover.host) return;
			if (!popover.host.popoverOpen) return;
			if (!popover.host.currentPopover) return;
			if (!(popover.host.popoverPosition instanceof HTMLElement)) return;
			if (!this.invpicker_el.nativeElement.contains(popover.host.popoverPosition)) return;
		}

		let html = e.clipboardData.getData("text/html");
		if (!html) return;

		let el = document.createElement("div");
		el.innerHTML = html;

		let rows = Array.from(el.querySelectorAll("tr"));
		if (!rows || rows.length == 0) return;

		let inv_numbers = new Set<string>();
		for (let row of rows) {
			let tds = row.querySelectorAll("td");
			if (tds == null) continue;
			if (tds.length !== 1) continue;
			let text = tds[0].textContent.trim();
			if (text.length == 0) continue;
			inv_numbers.add(text);
		};

		if (inv_numbers.size == 0) return;

		this.load_inv_numbers([...inv_numbers]);
		e.stopPropagation();
		e.preventDefault();
		return false;
	};


	writeValue(val: Task.KAM_Inventory[]) {
		val = val || [];
		this.picked_inventory = [...val];
		this.add_inventory(false);
	}

	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

	setDisabledState(state: boolean) {
		this.disabled = state;
	}
}

export function format_kam_name(inv: Task.KAM_Inventory) {
	if (!inv) { return ""; }
	let name = inv.inventory_name || "";

	if (inv.inventory_no) {
		name = "#" + inv.inventory_no + " " + name;
	}

	if (inv.inventory_serial_no) {
		name = name + "(" + inv.inventory_serial_no + ")"
	}

	return name;
}
