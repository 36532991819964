
import { Component, ViewEncapsulation, Input } from '@angular/core';
import { noOp, date as DATE, popover } from 'curvy';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerDialog } from './date-picker-dialog.component';


@Component({
    selector: 'task-date-picker-input',
    template: `
		<label colored-input
			class="input {{classes}}"
			[attr.title]="label | trans"
			[attr.color]="color"
			(click)="openDatePicker()">

			<input [placeholder]="'DATE' | trans"
				[disabled]="disabled"
				[value]="getLabel(selected_value)"
				style="pointer-events: none; user-select: none;"
				[required]="required"
				(focus)="openDatePicker()"
				inputmode="none"
			/>
			<i icon="calendar_today" style="font-size: .7rem;"></i>
		</label>
	`,
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: DatePickerInput,
            multi: true
        }],
    styleUrls: ['date-time-picker.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class DatePickerInput implements ControlValueAccessor {
	private on_changed;
	private on_touched;


    @Input()
	classes: string = "outlined flex-dynamic";
	@Input()
    label: string = "DATE";
    @Input()
	color: string = "";
    @Input()
	required = false;
	@Input()
	disabled = false;
	@Input()
	format = "short_date";

	@Input('max-date')
	max:Date|string = null;

	@Input('min-date')
	min:Date|string = null;

	@Input()
	highlight = 'primary';

	@Input()
	title = "ENTER_DATE";

	selected_value: Date | null = null;

	getLabel(date: Date | null): string {
		try {
            return this.write_date_value(date);
		} catch(err) {
			console.groupCollapsed("Error in to_label of knw-auto-search element", this);
			console.error("Thrown error:", err);
			console.log("For value:", date);
			console.log("Defaulting to empty string.");
			console.groupEnd();
		}
		return "";
	}

	write_date_value(date: Date | null) {
        if (!date) {
            return '';
        } else {
			if (DATE.format_exists(this.format)) {
				return DATE.get_formatted_date(date, this.format, false);
			} else {
				return DATE.format(this.format, date, false);
			}
        }
    }

	openDatePicker() {
        popover.open(
            DatePickerDialog,
            (data)=>{ this.change(data) },
            {date: this.selected_value, highlight: this.highlight, title: this.title, max: this.max, min: this.min},
            noOp,
            "under-mouse", "auto", "date-time-popover-wrapper")
    }

	protected change(val: Date | null, perform_events = true) {
		if (this.disabled) return;
		this.selected_value = val;

		if (this.on_changed && perform_events) {
			this.on_changed(this.selected_value);
		}
	}

	writeValue(val: string | Date | null) {

		if (typeof val === 'string') this.selected_value = new Date(val);
		else this.selected_value = val;
	}

	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

	setDisabledState(state: boolean) {
		this.disabled = state;
	}
}

