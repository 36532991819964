import { Component, Input, ViewEncapsulation, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent, Frame, PopoverMenuComponent, async, color, language, modal, noOp, notification, popover, utils, progress, FormCollectorDirective } from 'curvy';
import { files, Structs, Modal } from '@task-utils/utils';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { components_trans } from '@task-components/components.trans';
import { arrivals_translations } from '@task-modules/arrivals/arrivals.trans';
import { AssignArrivalDialog, FinancialDetailsComponent, Order, RevertItemsDialog, orders_translations } from '@task-modules/orders';
import { tickets_translations } from '@task-modules/tickets';
import { User } from '@task-modules/users/users';
import { PlanArrivalDialog } from '@task-modules/arrivals';
import { InputDialog } from '@task-components/input-dialog.component';
import { SupplierPickerDialog } from '@task-components/supplier-picker-dialog.component';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';


type FileType = (files.IFile | Task.DMSFile);

function is_dms_file(file: FileType): file is Task.DMSFile {
    return file.hasOwnProperty('dms_document_id');
}

function is_responsible(user: Task.User, order: Task.Order) {
    return order.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

function can_confirm(user: Task.User, order: Task.Order): boolean {

    switch (order.status_id) {
        case Task.STATUS.ON_SUPPLIER_REVIEW:
            if (user.company_id === order.supplier_id || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            break;
        case Task.STATUS.ON_COMPANY_REVIEW:
            if (user.user_type_id === Task.USER_TYPE.ADMIN || user.user_type_id === Task.USER_TYPE.SYSTEM_ADMIN) return true;
            else if ((user.user_type_id === Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER || user.user_type_id === Task.USER_TYPE.SAFETY_MANAGER) && is_responsible(user, order)) return true;
            break;
        case Task.STATUS.EXECUTED:
        case Task.STATUS.INVOICED:
        default:
            return false;
    }
}

export interface FinancialItemExtended extends Task.ArrivalDetail {
	is_edited: boolean;
	is_deleted: boolean;
    is_approved: boolean;
    is_item_new: boolean;
    show_comment: boolean;
    change_comment: string;
    el_new_price: string;
    el_old_price: string;
    price_changed: boolean;
    el_new_lump_sum: boolean;
    el_old_lump_sum: boolean;
    lump_sum_changed: boolean;
    el_new_quantity: string;
    el_old_quantity: string;
    quantity_changed: boolean;
    show_details: boolean;
}

@Component({
    selector: 'task-order-single',
    templateUrl: './order-single.component.html',
    styleUrls: ['./order-single.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class OrderSingleComponent implements OnInit, OnChanges {
    @ViewChild('service_file') service_file
    @ViewChild('financial_file') financial_file;
    @ViewChild('fin_details') fin_details: FinancialDetailsComponent;
    @ViewChild('financeForm', { static: false })
    finance_form: FormCollectorDirective;

    @Input()
    order: Task.Order;

    icon: string;
    color: string;
    status_name: string;

    tickets: Task.Ticket[] = [];
    arrivals: Task.Arrival[] = [];
    arrival_details: Task.ArrivalDetail[] = [];
    has_errors = false;


    entity = Task.ENTITY.ORDER;
    itype = Task.Investment_Type;
    investment_type:number|null = null;
    order_flags = {
        insurance: false,
        lease: false,
        preinvoice: false,
        complaint: false,
        inspection: false
    }

    currency_sums: Record<string, number> = {};

    can_edit_financial_details = false;
    can_plan_arrival = false;
    can_get_pdf = false;
    can_get_pdf_without_prices: boolean = false;
    can_see_tickets = false;
    can_invoice = false;
    can_edit = false;
    can_edit_images = false;
    can_edit_documents = false;
    can_see_financial_documents = false;
    can_edit_financial_documents = false;
    can_reassign = false;
    can_reject = false;
    can_delete = false;
    allow_confirmation = false;

    can_see_order_history = false;
    can_revert_review = false;
    can_revert_execution = false;
    can_revert_executed = false;
    can_revert_items = false;
    can_edit_admin = false;


    has_completed_arrivals = false;

    // Admin delete is different from regular delete
    // because admin delete also deletes all arrivals
    // & scheduled_orders.
    can_admin_delete = false;

    // These only apply to internal orders / company workers
    can_see_internal_approval_status = false;
    can_send_approval_email = false;


    can_assign_inventory_items = false;

    is_rejected = false;
    is_deleted = false;

    show_currency = false;

    edited = false;
    data_before_edit: Task.Order = null;

    fin_details_edited = false;


    images: files.IImage[] = [];
    financial_files: FileType[] = [];
    service_files: FileType[] = [];
    additional_inventory: Task.KAM_Inventory[] = [];

    get_order_status = Structs.get_order_status;

    inventory_details: Task.OrderDetail[] = [];
    description_details: Task.OrderDetail[] = [];
    can_see_individual_prices: boolean  = false;

    ICON_ORDER_HISTORY = Structs.icon("ballot", Task.STATUS_COLORS.GREEN);
    ICON_REVIEW = Task.get_status_icon(Task.STATUS.ON_SUPPLIER_REVIEW);
    ICON_EXECUTION = Task.get_status_icon(Task.STATUS.IN_EXECUTION);
    ICON_EXECUTED = Task.get_status_icon(Task.STATUS.EXECUTED);


    can_see_admin_actions() {
        return (
            this.can_see_order_history ||
            this.can_revert_review ||
            this.can_revert_execution ||
            this.can_revert_executed ||
            this.can_revert_items ||
            this.can_admin_delete
        );
    }
    constructor(private ar: ActivatedRoute, private cdr: ChangeDetectorRef) {
        language.load_translations(orders_translations);
        language.load_translations(tickets_translations);
        language.load_translations(arrivals_translations);
        language.load_translations(components_trans);
	}

    detectErrors(form: FormCollectorDirective) {
      this.cdr.detectChanges();
      this.has_errors = form.containsErrors();
   }


    ngOnInit() {
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        let order_changed = !!changes["order"];

        if (order_changed) {
            this.update_values();

        }
    }

    async initialize() {
		if (this.order == null) {
            const order_id = this.ar.snapshot.paramMap.get("id");

             Frame.set({
                title: language.translate("NAVROUTE.ORDER", order_id),
                visible: true,
                layout: "top-middle",
                size: "scroll"
            });

            try {
                const res = await Order.get(+order_id)
                this.order = res
                this.update_values();
            } catch(error) {
                console.error(error.message)
                utils.router.navigateByUrl('/orders');
            }

        } else {
            this.update_values();
        }
	}

    async update_values() {
        this.data_before_edit = JSON.parse(JSON.stringify(this.order));
        this.additional_inventory = [];
        this.investment_type = this.order.investment_type;

        for (let key of Object.keys(this.order_flags)) {
            this.order_flags[key] = this.order[key]
        }

        let arrival_details = await TaskRoutes.orders.api_order_financial_details_get(this.order.order_id);
        this.arrival_details = arrival_details.data;

        let isArrivalDetails = this.arrival_details.some(d => d.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT);
        let isOrderTypeInventory = this.getInventoryItems().length > 0 ? true : false;

        let user = await User.currentUserPromise;

        this.can_assign_inventory_items = isArrivalDetails && isOrderTypeInventory && await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER
        )


        let is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN);

        let has_admin_actions = await User.currentTypeIs(
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER
        );

         if (is_admin ) {
            this.can_edit_admin = true;
        }


        if (has_admin_actions) {
            this.can_see_order_history = true;
            this.can_revert_review = [
                Task.STATUS.ON_COMPANY_REVIEW,
                Task.STATUS.EXECUTED,
                Task.STATUS.INVOICED
            ].includes(this.order.status_id);

            this.can_revert_execution = [
                Task.STATUS.EXECUTED,
                Task.STATUS.INVOICED,
                Task.STATUS.ON_COMPANY_REVIEW,
                 Task.STATUS.ON_SUPPLIER_REVIEW
            ].includes(this.order.status_id);

            this.can_revert_executed = this.order.status_id == Task.STATUS.INVOICED;

            this.can_revert_items = [
                Task.STATUS.ON_COMPANY_REVIEW,
                Task.STATUS.ON_SUPPLIER_REVIEW
            ].includes(this.order.status_id) && this.arrival_details.some(d => d.arrival_detail_type_id === Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT);


            this.can_admin_delete = this.order.status_id != Task.STATUS.DELETED && (
                await User.currentTypeIs(
                    Task.USER_TYPE.SYSTEM_ADMIN,
                    Task.USER_TYPE.ADMIN
                )
            );
        }

        this.can_see_tickets = !(await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER));

        this.is_rejected = this.order.status_id === Task.STATUS.REJECTED;
        this.is_deleted = this.order.status_id === Task.STATUS.DELETED;

        this.can_get_pdf_without_prices = (this.order.status_id === Task.STATUS.INVOICED) && !(await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.SUPPLIER_WORKER))

        this.can_see_individual_prices = !(await User.currentTypeIs(Task.USER_TYPE.MANAGER_2))

        this.can_plan_arrival = (
            this.order.status_id === Task.STATUS.CREATED ||
            this.order.status_id === Task.STATUS.IN_EXECUTION) &&
            await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.SUPPLIER_ADMIN,
                Task.USER_TYPE.COMPANY_WORKER,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER);

        this.can_edit_financial_details = (
           (this.order.status_id === Task.STATUS.ON_COMPANY_REVIEW ||
            this.order.status_id === Task.STATUS.ON_SUPPLIER_REVIEW) &&
            (user.company_id === this.order.company_id ||
            user.company_id === this.order.supplier_id) && await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SUPPLIER_ADMIN,
                Task.USER_TYPE.SAFETY_MANAGER,
                Task.USER_TYPE.MANAGER_1
            )
        ) || (
            (this.order.status_id === Task.STATUS.EXECUTED ||
            this.order.status_id === Task.STATUS.INVOICED) &&
            await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SUPPLIER_ADMIN,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER,
                Task.USER_TYPE.MANAGER_1
            )
        ) || await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN);

        this.can_get_pdf = (
            this.order.status_id === Task.STATUS.INVOICED &&
            await User.currentTypeIs(
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SUPPLIER_ADMIN,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER,
                Task.USER_TYPE.MANAGER_1
            )
        )

        this.can_invoice = (
            this.order.status_id === Task.STATUS.EXECUTED &&
            await User.currentTypeIs(
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SUPPLIER_ADMIN
            )
        );

        this.can_edit = (
            this.order.status_id === Task.STATUS.CREATED &&
            await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.SUPER_USER,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER
            )
        );

        this.can_see_financial_documents = await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER,
            Task.USER_TYPE.SUPPLIER_ADMIN,
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.MANAGER_1
        );

        let can_company_user_edit_financial_docs = (
            this.order.status_id == Task.STATUS.CREATED ||
            this.order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
            this.order.status_id == Task.STATUS.ON_COMPANY_REVIEW ||
            this.order.status_id == Task.STATUS.IN_EXECUTION
        ) && await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER,
            Task.USER_TYPE.SYSTEM_ADMIN,
        );


        let can_company_user_edit_docs = (
            this.order.status_id == Task.STATUS.CREATED ||
            this.order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
            this.order.status_id == Task.STATUS.ON_COMPANY_REVIEW ||
            this.order.status_id == Task.STATUS.IN_EXECUTION
        ) && await User.currentTypeIs(
            Task.USER_TYPE.ADMIN,
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER,
            Task.USER_TYPE.SYSTEM_ADMIN,
            Task.USER_TYPE.REGIONAL_RETAIL_MANAGER,
        );

        let can_supplier_user_edit_financial_docs = (
            this.order.status_id == Task.STATUS.CREATED ||
            this.order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
            this.order.status_id == Task.STATUS.IN_EXECUTION
        ) && await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)

        let can_supplier_user_edit_docs = (
            this.order.status_id == Task.STATUS.CREATED ||
            this.order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW ||
            this.order.status_id == Task.STATUS.IN_EXECUTION
        ) && await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)


        this.can_edit_financial_documents = can_company_user_edit_financial_docs || can_supplier_user_edit_financial_docs;
        this.can_edit_documents =  can_company_user_edit_docs || can_supplier_user_edit_docs;

        let created_order_has_arrivals = this.order.status_id === Task.STATUS.CREATED && this.order.active_arrivals > 0;
        let created_order_has_no_arrivals = this.order.status_id === Task.STATUS.CREATED && this.order.active_arrivals == 0;

        this.can_edit_images = created_order_has_no_arrivals && await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER);

        this.can_reject = (
            this.order.status_id === Task.STATUS.CREATED &&
            await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN, Task.USER_TYPE.COMPANY_WORKER)
        );

        let allowDeletion = async () => {
            let is_creator = (await User.currentTypeIs(Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER, Task.USER_TYPE.SAFETY_MANAGER) && is_responsible(user, this.order));

            let user_can_delete = is_admin || is_creator;

            if (!user_can_delete) return false;

            let allowCreated = this.order.status_id === Task.STATUS.CREATED;
            let allowInexecution = this.order.status_id === Task.STATUS.IN_EXECUTION && this.order.active_arrivals == 0;


            return allowCreated || allowInexecution;
        }

        this.can_delete = await allowDeletion();

        this.can_reassign = (
            this.is_rejected &&
            await User.currentTypeIs(
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.SUPER_USER,
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER,
            )
        );

        if (
            this.order.company_id==this.order.supplier_id &&
            this.order.status_id==Task.STATUS.ON_COMPANY_REVIEW &&
            await User.currentTypeIs(
                Task.USER_TYPE.COMPANY_WORKER,
                Task.USER_TYPE.SYSTEM_ADMIN,
                Task.USER_TYPE.ADMIN
            )
        ) {
            this.can_see_internal_approval_status = true;
            this.can_send_approval_email = !this.order.internal_approval_mail_sent;
        }

        if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
            this.can_edit = true;
            this.can_edit_financial_documents = true;
            this.can_edit_images = true;
            this.can_reject = true;
            this.can_reassign = true;
        }

        if (await User.currentTypeIs(Task.USER_TYPE.ADMIN)) {
            this.can_edit_financial_documents = true;
            this.can_edit_images = true;
        }


        let icon = () => {
            let icon = {} as Structs.Icon;

            if (created_order_has_arrivals) {
                icon = Task.HAS_ARRIVAL_ICON;
            } else {
                icon = Task.get_status_icon(this.order.status_id);
            }

            return icon;
        }
        this.icon = icon().icon;
        this.color = icon().color;

        this.status_name = created_order_has_arrivals ? language.translate("STATUS.HAS_ARRIVAL") : language.translate("STATUS."+Task.Status[this.order.status_id]);
        this.tickets = await Order.get_tickets(this.order.order_id);

        this.arrivals = (await TaskRoutes.orders.api_order_arrivals_get_all(this.order.order_id, { page_no: 1, page_size: 1000 }, true)).data;
        this.currency_sums = {};
        this.show_currency = false;

        for (let arrival of this.arrivals) {
            arrival.order = this.order;
            arrival['icon_name'] =  Task.StatusIcons[arrival.status_id].icon;
            arrival['icon_color'] =  Task.StatusIcons[arrival.status_id].color;
            arrival['status_name'] = language.translate("STATUS."+ Task.Status[arrival.status_id])

            for (let detail of arrival.arrival_details) {
                if (detail.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT) {
                    if(detail.arrival_status_id == Task.STATUS.DELETED) continue;
                    if(!detail.element_currency) continue;
                    this.show_currency = true;
                    this.currency_sums[detail.element_currency] = this.currency_sums[detail.element_currency] || 0;
                    if(!detail.lump_sum) {
                        let element_price = Math.round(detail.element_price*100)/100;
                        this.currency_sums[detail.element_currency] += Math.round(element_price * detail.element_quantity*100)/100;
                    }
                }
            }
        }

        this.has_completed_arrivals = this.arrivals.some(a=>a.status_id === Task.STATUS.EXECUTED);
        let image_requests: Promise<files.IImage>[] = [];

        this.inventory_details = [];
        this.description_details = [];

        for (let detail of this.order.order_details) {
            if (detail.order_detail_type_id == Task.ORDER_DETAIL_TYPE.IMAGE) {
                image_requests.push(
                    files.url_to_iimage(detail.picture_url)
                );
            }

            if (detail.order_detail_type_id === Task.ORDER_DETAIL_TYPE.INVENTORY) {
                this.inventory_details.push(detail);
                if (detail?.inventory_data?.udas) {
                    detail.inventory_data.udas.sort((u1, u2) => {
                        return u1.uda_name.localeCompare(u2.uda_name);
                    });
                }
            }

            if (detail.order_detail_type_id == Task.ORDER_DETAIL_TYPE.DESCRIPTION) {
                this.description_details.push(detail);
            }

            if (detail.order_detail_type_id === Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT) {
                this.financial_files.push(detail)
            }

            if (detail.order_detail_type_id === Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT) {
                this.service_files.push(detail)
            }

        }

        this.allow_confirmation = can_confirm(user, this.order);


        this.images = await Promise.all(image_requests);
        this.financial_files = [...this.financial_files];
        this.service_files = [...this.service_files];
    }

    async refetch_order(prom?: Promise<TaskRoutes.Payload<Task.Order>>) {
        if (!prom) {
            prom = TaskRoutes.orders.api_order_get_single(this.order.order_id, true);
        }
        this.order = (await prom).data;
        this.update_values();
    }

    async upload_file(order_id: number, file: FileType, order_detail_type_id: number): Promise<Task.OrderDetail|null> {
        if (!is_dms_file(file)) {
            try {
                let uploaded = await TaskRoutes.orders.api_upload_document(order_id, file.file, true, order_detail_type_id);
                notification.show({
                    icon: "check",
                    color: color.Variable.secondary,
                    title: language.translate('SUCCESS'),
                    message: language.translate('DOCUMENT.UPLOADED_TEXT')
                });
                return uploaded.data;
            } catch(error) {
                let msg;
                if (error.internal_error_code == 4107) {
                    msg = language.translate('DOCUMENT.UNSAFE_FAIL_TEXT');
                } else {
                    msg = language.translate('DOCUMENT.UPLOADED_FAIL_TEXT');
                }

                notification.show({
                    icon: 'error',
                    color: color.Variable.warn,
                    title: language.translate('ERROR'),
                    message: msg
                })
            }
        }
        return null;
    }

    async download_file(file: FileType) {
        if (is_dms_file(file)) {
            await TaskRoutes.orders.api_get_document_by_order_detail_id(file.order_detail_id, true);
        }

    }

    async download_pricelist() {
        await TaskRoutes.pricelists.api_pricelist_xlsx_generate(this.order.supplier_id, true);
    }

    async download_by_store_approval() {
        await TaskRoutes.orders.api_arrivals_work_order_snapshot_get(this.order.order_id, true);
    }

    async download_all() {
        await TaskRoutes.orders.api_arrivals_work_order__get(this.order.order_id, true);
    }

    async create_arrival() {
        let workers:Task.ArrivalWorker[] = [];
        if (this.order.arrival_worker_id) {
			let internal_worker = (await TaskRoutes.users.api_user_get_single(this.order.arrival_worker_id)).data as unknown as Task.ArrivalWorker;

			workers.push(internal_worker)
		}
        modal.open(PlanArrivalDialog, (res)=>{
            if (res) {
                utils.router.navigateByUrl("/arrivals/" + res.arrival_id);
            }
        }, {
            arrival: {
                order_id: this.order.order_id,
                planned_start_time: null,
                arrival_workers: workers
            },
            start_time_picker: true,

        });
    }

    handle_result = async (assingedMap: Map<Task.OrderDetail, Set<Task.ArrivalDetail>>) => {
        if (!assingedMap) { return; }

        let response = [];
        for (let [key, value] of assingedMap.entries()) {
            response.push({
                inventory_id: key.order_detail_id,
                arrival_details: [...value].map(a=>a.arrival_detail_id)
            })
        }

        await TaskRoutes.orders.api_order_detail_inventory_set(this.order.order_id, { detail_inventory: response });
    }

    getInventoryItems() {
        return this.order.order_details.filter(d => d.order_detail_type_id === Task.ORDER_DETAIL_TYPE.INVENTORY)
    }

    arrivalHasNoDetails(arrival) {
        return !arrival.arrival_details.some(d=>d.arrival_detail_type_id == 3)
    }

    async assign_arrival_details(){
        let inventory = this.getInventoryItems();

        let res = await TaskRoutes.orders.api_order_detail_inventory_get(this.order.order_id);
        let data: { inventory_id: number, arrival_details: number[] }[] = res.data;
        let inventory_detail_map: Map<Task.OrderDetail, Set<Task.ArrivalDetail>> = new Map();

        for (let i of inventory) {
            inventory_detail_map.set(i, new Set())
        }

        for (let item of data) {
            let inv = inventory.find(i => i.order_detail_id === item.inventory_id);
            let detail_set = inventory_detail_map.get(inv);

            for (let detail_id of item.arrival_details) {
                let detail = this.arrival_details.find(d => d.arrival_detail_id === detail_id);
                if (detail) {
                    detail_set.add(detail);
                }
            }
        }

        modal.open(AssignArrivalDialog, this.handle_result, {
            inventory: inventory,
            arrival_details: this.arrival_details,
            inventory_detail_map: inventory_detail_map
        });
    }

    invoice() {
        modal.open(
            InputDialog,
            async (invoice_no) => {
                if (invoice_no || invoice_no == '') {
                    this.refetch_order(
                        TaskRoutes.orders.api_order_invoiced(
                            this.order.order_id, {invoice_no}, true
                        )
                    );
                }
            },
            {
                title: "FINANCIAL.INVOICE.TITLE",
                label: "FINANCIAL.INVOICE.LABEL",
                check_required: true
            }
        );
    }

    show_invoice_proposal(pricetag = true) {
        if (pricetag) {
            TaskRoutes.orders.api_order_invoice_proposal_get(this.order.order_id, true);
        } else {
            TaskRoutes.orders.api_order_invoice_proposal_without_cost_get(this.order.order_id, true)
        }
    }

    show_financial_details() {
        utils.router.navigateByUrl("/orders/" + this.order.order_id + "/financial-details");
    }

    async upload_image(img: files.IImage) {
        if (!img.is_uploaded) {
            let res = await TaskRoutes.upload.api_upload_file(img.file);
            img.src = TaskRoutes.upload.uploaded_file_get(res.data);
            img.is_uploaded = true;
        }
        return img;
    }

    create_image_detail(image: files.IImage) {
        let detail: Partial<Task.OrderDetail> = {
            order_detail_type_id: Task.ORDER_DETAIL_TYPE.IMAGE,
            picture_url: image.src,
            description: image.src,
            order_id: this.order.order_id
        };

        return detail as Task.OrderDetail;
    }

    create_inventory_detail(answer: Task.KAM_Inventory) {
        let empty = true;

        for (let key in answer) {
            let val = answer[key]
            if (val !== undefined && val !== null && val !== "") {
                empty = false;
                break;
            }
        }

        if (empty) {
            return null;
        }

        let detail: Partial<Task.OrderDetail> = {
            order_id: this.order.order_id,
            order_detail_type_id: Task.ORDER_DETAIL_TYPE.INVENTORY,
            description: answer.inventory_name,
            inventory_id: answer.inventory_id,
            inventory_no: answer.inventory_no,
            inventory_serial_no: answer.inventory_serial_no,
            inventory_name: answer.inventory_name,
            inventory_data: answer
        };

        return detail as Task.OrderDetail;
    }

    async save() {
        if (!this.edited) {
            return;
        }

        this.edited = false;
        this.can_edit = false;

        let old_image_details = this.order.order_details.filter(
            d => d.order_detail_type_id === Task.ORDER_DETAIL_TYPE.IMAGE &&
                this.images.some(i => i.src === d.picture_url)
        );

       let old_financial_file_details = this.order.order_details.filter(
            d => d.order_detail_type_id == Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT &&
                this.financial_files.some(f => "dms_document_id" in f && f.dms_document_id == d.dms_document_id)
        );

        let old_service_file_details = this.order.order_details.filter(
            d => d.order_detail_type_id == Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT &&
                this.service_files.some(f => "dms_document_id" in f && f.dms_document_id == d.dms_document_id)
        );

        let new_image_requests: Promise<Task.OrderDetail>[] = [];
        for (let image of this.images) {
            if (!image.is_uploaded) {
                new_image_requests.push(
                    this.upload_image(image).then(res => {
                        return this.create_image_detail(res);
                    })
                );
            }
        }

        let new_financial_file_requests: Promise<Task.OrderDetail>[] = [];
        for (let file of this.financial_files) {
            if (!is_dms_file(file)) {
                new_financial_file_requests.push(
                    this.upload_file(this.order.order_id, file, Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT)
                );
            }
        }

        let new_service_file_requests: Promise<Task.OrderDetail>[] = [];
        for (let file of this.service_files) {
            if (!is_dms_file(file)) {
                new_service_file_requests.push(
                    this.upload_file(this.order.order_id, file, Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT)
                );
            }
        }

        let new_image_details = [
            ...old_image_details,
            ...await Promise.all(new_image_requests)
        ];

        let new_financial_file_details = [
            ...old_financial_file_details,
            ...(await Promise.all(new_financial_file_requests)).filter(d => d !== null)
        ];

        let new_service_file_details = [
            ...old_service_file_details,
            ...(await Promise.all(new_service_file_requests)).filter(d => d !== null)
        ];

        this.order.order_details = this.order.order_details.filter(
            d => d.order_detail_type_id !== Task.ORDER_DETAIL_TYPE.IMAGE
                && d.order_detail_type_id !== Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT
                && d.order_detail_type_id !== Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT
        );

        this.order.order_details.push(...new_image_details);
        this.order.order_details.push(...new_financial_file_details);
        this.order.order_details.push(...new_service_file_details);

        for (let i of this.additional_inventory) {
            let detail = this.create_inventory_detail(i);
            if (detail) {
                this.order.order_details.push(detail);
            }
        }

        this.order.investment_type = this.investment_type;

        for (let key of Object.keys(this.order_flags)) {
            this.order[key] = this.order_flags[key]
        }


        await TaskRoutes.orders.api_order_modify(this.order.order_id, this.order, true);
        await this.refetch_order();


        this.images = [];
        this.financial_files = [];
        this.service_files = [];
    }

    cancel_edit() {
        if (this.edited && this.data_before_edit) {
            this.order = this.data_before_edit;
            this.edited = false;
            this.financial_files = [];
            this.service_files = [];
            this.update_values();
        }
    }

    async reject_order() {
        modal.open(
            InputDialog,
            async (res) => {
                if (res) {
                    await TaskRoutes.orders.api_order_reject(
                        this.order.order_id, { comment: res }, false
                    );
                    utils.router.navigateByUrl("/orders");
                }
            },
            {
                title: "ORDER.REJECT.TITLE",
                label: "ORDER.REJECT.LABEL",
                required: true
            }
        );
    }

    async delete_order() {
        Order.delete_order_dialog(this.order, () => {
            utils.router.navigateByUrl("/orders");
        });
    }
    async reassign_supplier() {
        modal.open(
            SupplierPickerDialog,
            async ({supplier, arrival_worker}) => {
                if (supplier) {
                    let order = await TaskRoutes.orders.api_order_supplier_reasign(
                        this.order.order_id, {...supplier, arrival_worker:arrival_worker}, true);
                    this.order = order.data;
                    this.update_values();
                }
            },
            this.order
        );
    }

    revert_dialog(title: string, message: string, status_id: Task.STATUS) {
        modal.open(
            TextBoxDialog,
            async (reason) => {
                if (!reason) return;
                let order_id = this.order.order_id;
                let payload = { status_id, reason };
                await TaskRoutes.orders.api_order_admin_revert(order_id, payload);
                this.refetch_order();
            }, {
                title: language.translate(title, this.order.order_id),
                message: {
                    message: language.translate(message),
                    icon: "ballot",
                    iconColor: color.variable(color.Variable.warn)
                },
                label: "ORDER.REVERT.REASON",
                placeholder: "ORDER.REVERT.ENTER_REASON",
                required: true,
                buttonColor: color.variable(color.Variable.warn),
            }
        );
    }

    revert_review() {
        this.revert_dialog(
            "ORDER.REVERT_REVIEW.TITLE",
            "ORDER.REVERT_REVIEW.MSG",
            Task.STATUS.ON_SUPPLIER_REVIEW
        );
    }

    revert_execution() {
        this.revert_dialog(
            "ORDER.REVERT_EXECUTION.TITLE",
            "ORDER.REVERT_EXECUTION.MSG",
            Task.STATUS.IN_EXECUTION
        );
    }

    revert_executed() {
        this.revert_dialog(
            "ORDER.REVERT_EXECUTED.TITLE",
            "ORDER.REVERT_EXECUTED.MSG",
            Task.STATUS.EXECUTED
        );
    }

    revert_items() {
        modal.open(
            RevertItemsDialog,
            async (res) => {
                if (!res || res.size == 0) return;

                await async.sleep(300);

                modal.open(
                    ConfirmDialogComponent,
                    async (ok) => {
                        if (!ok) return;
                        await TaskRoutes.orders.api_order_details_admin_revert(
                            this.order.order_id, {
                                arrival_detail_ids: [...res].map(d => d.arrival_detail_id)
                            }
                        );
                        this.refetch_order();
                    }, {
                        title: language.translate("ORDER.REVERT_ITEMS.TITLE", this.order.order_id),
                        message: language.translate("ORDER.REVERT_ITEMS.MSG"),
                        icon: "ballot",
                        iconColor: color.variable(color.Variable.warn)
                    }
                );
            }, {
                financial_items: this.arrival_details.filter(
                    d => d.arrival_detail_type_id == Task.ARRIVAL_DETAIL_TYPE.WORK_ELEMENT
                ),
            }
        );
    }

    async admin_delete() {
        let res = await Modal.open(ConfirmDialogComponent, {
            title: language.translate("ORDER.ADMIN_DELETE.TITLE", this.order.order_id),
            message: language.translate("ORDER.ADMIN_DELETE.MSG"),
            icon: "delete",
            iconColor: color.variable(color.Variable.warn)
        });
        if ("closed" in res) return;
        if (!res.data) return;

        let reason = await Modal.open(TextBoxDialog, {
            title: language.translate("ORDER.DELETE.TITLE", this.order.order_id),
            label: "ORDER.DELETE.REASON",
            message: {
                message:
                    this.order.status_id === Task.STATUS.IN_EXECUTION ?
                        language.translate("ORDER.DELETE.IN_EXECUTION") :
                        language.translate("ORDER.DELETE.MSG"),
                icon: "delete",
                iconColor: color.variable(color.Variable.warn)
            },
            placeholder: "ORDER.DELETE.ENTER_REASON",
            cancelText: "CANCEL",
            confirmText: "DELETE",
            buttonColor: "warn"
        });
        if ("closed" in reason) return;
        if (!reason.data) return;

        await TaskRoutes.orders.api_order_admin_delete(this.order.order_id, {reason: reason.data});
        utils.router.navigateByUrl("/orders");
    }

    get_order_history_report() {
        progress.listen(
            "order_admin_action",
            TaskRoutes.orders.api_order_history(this.order.order_id, true)
        );
    }

    async send_internal_approval_email() {
        let res = await Modal.open(ConfirmDialogComponent, {
            title: language.translate("ORDER.INTERNAL_APPROVAL.TITLE", this.order.order_id),
            message: language.translate("ORDER.INTERNAL_APPROVAL.MSG"),
            icon: "mark_email_read",
            iconColor: color.variable(color.Variable.secondaryDark)
        });
        if ("closed" in res) return;
        if (!res.data) return;

        await TaskRoutes.orders.api_send_order_internal_approval_mail(this.order.order_id);
        await this.refetch_order();
    }

    get_order_title = () => {
        return `#${this.order.order_id} ${this.order.title}`
    }

    get_location = () =>{
		return `#${this.order.location_cin} ${this.order.location_name}`
	}

    assigned_to = () => {
        if(this.order.created_by_id) {
            const authorized = this.order.authorized_maintenance_managers.length > 0
            if(authorized) {
                let authorized_person = ''
                for(let i = 0; i < this.order.authorized_maintenance_managers.length; i++) {
                    let index = this.order.authorized_maintenance_managers[i]
                    authorized_person += `${index.first_name} ${index.last_name}`
                    if(i !== this.order.authorized_maintenance_managers.length -1) {
                        authorized_person += ', '
                    }
                }
                return authorized_person
            } else {
                return null
            }
        }
        return null
    }

    order_created_by = () => {
        return `${this.order.created_by_first_name} ${this.order.created_by_last_name}`;
    }

    approved_by = () => {
        if(this.order.approved_by_id) {
            return `${this.order.approved_by_first_name} ${this.order.approved_by_last_name}`
        }
    }

    order_comment = () => {
        if ((this.is_rejected || this.is_deleted) && this.order.comment) {
            return this.order.comment;
        }
    }

    order_revert_reason = () => {
        if (this.order.comment &&
            this.order.ping_pong_count > 0 &&
            this.order.status_id === Task.STATUS.ON_SUPPLIER_REVIEW
        ) { return this.order.comment }
    }

    worker_company = () => {
        if(this.order.arrival_worker_id) {
            return `${this.order.arrival_worker_first_name} ${this.order.arrival_worker_last_name}`
        }
    }

    order_investment_type = () => {
        if (this.order.investment_type !== undefined && this.order.investment_type !== null) {
            return `${language.translate('ORDER.INVESTMENT_TYPE.' + this.itype[this.order.investment_type])} `
        }
    }

    cost_estimate = () => {
        if(this.order.cost_estimate) {
            return `${this.order.cost_estimate} ${this.order.cost_estimate_currency}`
        }
    }

    order_invoice = () => {
        if(this.order.invoice_no) {
            return `${this.order.invoice_no}`
        }
    }

    order_code = () => {
        let order_code = "";
		let order_codes = [];

		if (this.order.insurance || this.order.preinvoice || this.order.lease || this.order.inspection) {
			if (this.order.insurance) {
				order_codes.push('OS');
			}
			if (this.order.preinvoice) {
				order_codes.push('PR');
			}
			if (this.order.lease)  {
				order_codes.push('NA');
			}
			if (this.order.inspection) {
				order_codes.push('IN');
			}

			order_code = order_codes.join(', ');

		} else {
			order_code = undefined;
		}

		return order_code;
    }

    get_order_tiles = (order: Task.Order) => {
		let tiles:{title: string, color: string}[] = [];

		if (order.complaint) {
			tiles.push({title: 'ORDER.RECLAMATION', color: 'var(--yellow-dark)'});
		}

		if (order.insurance) {
			tiles.push({title: 'ORDER.INSURANCE', color: 'var(--orange)'});
		}

        if (order.preinvoice) {
            tiles.push({title: 'ORDER.PREINVOICE', color: 'var(--orange)'});
        }

        if (order.lease) {
            tiles.push({title: 'ORDER.LEASE', color: 'var(--orange)'});
        }

        if (order.inspection) {
            tiles.push({title: 'ORDER.INSPECTION', color: 'var(--orange)'});
        }

		return tiles;
	}


    open_files_text_menu = () => {
        let menu = [
            {
                label: language.translate('ORDER.FINANCIAL.DOCUMENTATION'),
                icon: 'edit',
                data_1: Task.ORDER_DETAIL_TYPE.FINANCIAL_DOCUMENT,
                onClick: () => this.financial_file.get_files_for_upload(),
            },
            {
                label: language.translate('ORDER.SERVICE.TRACKED.DOCUMENTATION'),
                icon: 'edit',
                data_1: Task.ORDER_DETAIL_TYPE.SERVICE_AND_SUPPORT_DOCUMENT,
                onClick: () => this.service_file.get_files_for_upload()
            }
        ]
        if (!this.can_edit_financial_documents) menu = menu.filter((item, index)=>index != 0);
        if (!this.can_edit_documents) menu = menu.filter((item, index)=>index != 1);

        if (menu.length == 0) return;

        popover.open(
            PopoverMenuComponent,
            noOp,
            {actions: menu},
            null,
            "mouse",
            "auto"
        );
    }

    total_work_minutes = 0;

    get_total_work_time = () => {
        if (this.arrivals.length) {
            for (let arrival of this.arrivals) {
                if (arrival.status_id === Task.STATUS.DELETED) continue;

                let start = new Date(arrival.start_time).valueOf();
                let end = new Date(arrival.end_time).valueOf();
                let duration = Math.abs(end - start); // ms
                duration = duration / 1000; // sec
                duration = Math.floor(duration / 60); // min
                duration = Math.min(duration, 1440); // 24 hours (1440s) is maximum time

                this.total_work_minutes += duration * arrival.arrival_workers.length;
            }
        }

        return `${Math.floor(this.total_work_minutes / 60)} h : ${Math.floor(this.total_work_minutes % 60)} min`
    }

}
