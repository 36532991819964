import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "ADD_IMAGE": "Add image",
        "DELETE.IMAGE.TEXT":"Please, confirm that you want to delete this image",
        "PRICE": "Price",
        "COMP.WHIER": "Work hierarchy",
        "COMP.INVENTORY": "Inventory item",
        "VIEW_ALL": "View all",
        "INV.NO": "Inv. No.",
        "SER.NO": "Ser. No.",
        "SUPPLIER": "Supplier",
        "BRAND": "Brand",

        "FILES": "Files",
        "FINANCIAL_FILES": "Financial files",
        "REST_FILES": "Supporting and service documentation",


        "ADD_FILE" : "Add supporting and service documentation",
        "ADD_FINANCIAL_FILE" : "Add financial file",

        "FILE_UPLOAD.SUCCESS_MSG" : "File added",
        "DELETE.FILE.TEXT": (file_name) => `Are you sure you want to delete file ${file_name}?`,
        "WARRANTY_EXP": "Warranty exp.date",
        "WARRANTY_INVOICE_DATE": "Inv. date",
        "WARRANTY_INVOICE_NO": "Inv. No.",
        "ADD_INVENTORY.TITLE": "Inventory not found",
        "ADD_INVENTORY.MESSAGE_FEW": (not_found)=>`The following inventory numbers were not found: \n${not_found.join(', ')}`,
        "ADD_INVENTORY.MESSAGE_PLENTY": (not_found)=>`${not_found.length} inventory items were not found`,

        "ADD_LOCATIONS.TITLE": "Locations not found",
        "ADD_LOCATIONS.MESSAGE_FEW": (not_found)=>`The following location MT codes were not found :\n${not_found.join(', ')}`,
        "ADD_LOCATIONS.MESSAGE_PLENTY": (not_found)=>`${not_found.length} locations were not found`,
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "ADD_IMAGE": "Dodaj sliku",
        "DELETE.IMAGE.TEXT":"Da li želite obrisati sliku?",
        "PRICE": "Cijena",
        "COMP.WHIER": "Radna hijerarhija",
        "COMP.INVENTORY": "Dio inventara",
        "VIEW_ALL": "Vidi sve",
        "INV.NO": "Inv. br.",
        "SER.NO": "Serij. br.",
        "SUPPLIER": "Dobavljač",
        "BRAND": "Proizvođač",

        "FILES": "Dokumenti",
        "FINANCIAL_FILES": "Financijski dokumenti",
        "REST_FILES": "Prateća i ostala servisna dokumentacija",

        "ADD_FILE" : "Dodaj prateću i ostalu servisnu dokumentaciju",
        "ADD_FINANCIAL_FILE" : "Dodaj financijski dokument (ponuda)",

        "FILE_UPLOAD.SUCCESS_MSG" : "Dokument dodan",
        "DELETE.FILE.TEXT": (file_name) => `Želite li obrisati dokument ${file_name}?`,
        "WARRANTY_EXP": "Garancija ističe",
        "WARRANTY_INVOICE_DATE": "Datum fakture",
        "WARRANTY_INVOICE_NO": "Broj fakture",
        "ADD_INVENTORY.TITLE": "Inventar nije pronađen",
        "ADD_INVENTORY.MESSAGE_FEW": (not_found)=>`Pri uvozu nisu pronađeni inventari sa inventurnim brojem: \n${not_found.join(', ')}`,
        "ADD_INVENTORY.MESSAGE_PLENTY": (not_found)=>`Pri uvozu nije pronađeno ${not_found.length} komada inventara`,

        "ADD_LOCATIONS.TITLE": "Lokacije nisu pronađene",
        "ADD_LOCATIONS.MESSAGE_FEW": (not_found)=>`Pri uvozu nisu pronađene lokacije sa šifrom:\n${not_found.join(', ')}`,
        "ADD_LOCATIONS.MESSAGE_PLENTY": (not_found)=>`Pri uvozu nije pronađeno ${not_found.length} lokacija`,

    }
};

export const components_trans = [english, croatian];
