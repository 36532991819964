import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { language } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '../orders.trans';
import { ScheduledOrderType } from '../orders.types';
import { Order } from '../orders';
import { User } from '@task-modules/users/users';


@Component({
	selector: 'task-scheduled-order-approved-form',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'scheduled-order-approved-form.component.html',
	styles: [`


		.special-box label,
		label.special-box {
			width: 100%;
			box-sizing: border-box;
			margin-left: 0!important;
			margin-right: 0!important;
		}


		@media only screen and (min-width: 600px) {


			task-scheduled-order-approved-form .field-grid {
				grid-gap: 8px 10px;
				width: 450px;
			}
		}

		task-scheduled-order-approved-form .field-grid {
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: 1fr;
			grid-gap: 4px 10px;
		}

		task-scheduled-order-approved-form knw-search-picker {
			display: contents;
		}

		@media only screen and (max-width: 600px) {
			task-scheduled-order-approved-form .field-grid {
				grid-template-columns: 1fr;
			}
		}
	`]
})
export class scheduledOrderApprovedFormComponent implements OnInit {

	@Input()
	order: ScheduledOrderType;

	inventory: Task.KAM_Inventory[] = [];
	descriptions: ScheduledOrderType["scheduled_order_details"] = [{
		order_detail_type_id: Task.ORDER_DETAIL_TYPE.DESCRIPTION,
		description: ""
	}];

	picked_supplier: Task.Supplier = null;
	is_internal_supplier = false;
	picked_worker: Task.User = null;
	is_supplier = false;
	is_edit_form = false;
	show_info = false;
	max_date_allowed: Date = null;

	update_details() {
		if (!this.order.location_id) {
			this.inventory = [];
			this.order.scheduled_order_details = [...this.descriptions];

		} else {
			this.order.scheduled_order_details =[...this.descriptions, ...this.inventory.map(
				inv=>({
					order_detail_type_id: Task.ORDER_DETAIL_TYPE.INVENTORY,
					description: "-", // Description is mandatory with order details
					inventory_id: inv.inventory_id,
					inventory_name: inv.inventory_name,
					inventory_no: inv.inventory_no,
					inventory_serial_no: inv.inventory_serial_no,
					inventory_data: inv
				})
			)];
		}
	}


	ngOnInit() {
		language.load_translations(orders_translations);
		this.init();
	}

	async init() {
		this.is_supplier = await Order.is_supplier_admin();
		this.is_edit_form = this.order["scheduled_orders_template_id"] ? true : false;

		// @TODO(borna): ovdje smo dohvatili podatke o supplieru
		// sa backenda iako bi si ih sami mogli proslijediti iz neke
		// parent componente (create-scheduled-order) ili nešto
		if (this.order.supplier_id) {
			let res = await TaskRoutes.suppliers.api_supplier_get_single(this.order.supplier_id);
			this.picked_supplier = res.data;

			if (this.picked_supplier.supplier_id === (await User.currentUserPromise).company_id) {
				this.is_internal_supplier = true;
			}

			if (this.order.arrival_worker_id) {
				this.picked_worker = this.order.arrival_worker;
				this.is_internal_supplier = true;
			}
		}

		this.descriptions[0].description = this.order.event_details.filter(d=>d.order_detail_type_id === Task.ORDER_DETAIL_TYPE.DESCRIPTION)?.[0].description ?? "";

		this.inventory = this.order.scheduled_order_details.filter(d=>d.order_detail_type_id === Task.ORDER_DETAIL_TYPE.INVENTORY).map(d=>d.inventory_data);
	}


	handleLocations(locations: Task.Location) {
		this.inventory = [];
		this.order.location_id = locations.location_id;
  	}


}

