import { Component, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { DataTableFilter, date, language, utils, async, modal } from 'curvy';
import { srfilter_translations } from './single-row-filter.trans';
import { FilterDialogComponent } from './filter-dialog.component';
import type { PopoverWidth } from 'curvy/lib/components/modal-popover/popover/popover.component';

type Resolvable<T> = async.Resolvable<T>;
const unwrap_resolvable = async.unwrap_resolvable;

function is_undef(val: any): val is undefined {
	return val === undefined;
}

@Component({
    selector: 'task-single-row-filter',
    templateUrl: 'single-row-filter.component.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`
		task-single-row-filter label.input {
			--input-color: var(--frame-bg-text);
			--input-color-highlight: var(--frame-hl);
			--input-background: var(--frame-bg-text);
		}

		task-single-row-filter button {
			--button-color: var(--frame-hl);
			--button-color-contrast: var(--frame-hl-text);
		}

		task-single-row-filter .filters-button {
			display: none;
		}

		@media (max-width: 600px) {
			task-single-row-filter>.header>.flex-row>:not(:nth-child(-n+2)) {
				display: none;
			}
			task-single-row-filter .trigger-button {
				display: none;
			}
			task-single-row-filter .filters-button {
				display: block;
			}
		}
	`],
    standalone: false
})
export class TaskFilterComponent extends DataTableFilter<FilterInput, FilterOutput> {
	constructor(public cdr: ChangeDetectorRef) {
		super();
		language.load_translations(srfilter_translations);
	}

	buttonFilter(filter: ButtonFilterInput, button: HTMLButtonElement) {
		filter.filter_function(button).then(res => {
			filter.current_value = res;
			this.triggerFilter();
		});
	}

	async loadFilterFromStoredData(data?: FilterOutput) {
		data = data || {};

		for (const filter of this.filterInput) {
			if (data[filter.field_name]) {
				filter.current_value = data[filter.field_name];
			} else if (!is_undef(filter.default_value)) {
				let val = await unwrap_resolvable(filter.default_value);
				if (is_undef(val)) { val = null; }
				filter.current_value = val;
			} else {
				filter.current_value = null;
			}

			if (filter.current_value && filter.field_type == "date-range") {
				filter.start_date = filter.current_value.start;
				filter.end_date = filter.current_value.end;
			}
		}
	}

	// Removes all special characters from the name, replaces them with a single
	// "-" character for each series of special characters, and removes duplicate
	// substrings.
	// For example, changes the following string:
	//    "#?:user.name+ +user.surname+ +company.company_id"
	// into:
	//    "user_name_surname_company_id"
	normalize_popover_class(name: string) {
		let words = name.split(/[^\w\d]/).filter(s=>s.length);
		words = Array.from(new Set(words));
		return words.join("-");
	}

	get_popover_class = (filter: FilterInput[number]) => {
		let normal_name = this.normalize_popover_class(filter.field_name);
		let normal_table_name = this.normalize_popover_class(this.tableName);
		return `table-search table-search-field-${normal_name} table-search-${normal_table_name}`;
	};

	async setFilterData(value: Resolvable<FilterInput>) {
		this.filterInput = await unwrap_resolvable(value);

		for (const filter of this.filterInput) {
			filter.current_value = null;

			if (filter.field_type === 'dropdown' && filter.select_options instanceof Promise) {
				const promise = filter.select_options;
				filter.select_options = [];
				promise.then(res => {
					filter.select_options = res;
				});
			}
		}
	}

	showFilters() {
		modal.open(FilterDialogComponent, (trigger) => {
			if (trigger) {
				this.triggerFilter();
			}
		}, this);
	}

	triggerFilter() {
		const old_filter_output = this.filterOutput;
		this.filterOutput = {};

		for (const filter of this.filterInput) {
			if (filter.field_type === 'date-range') {
				if (filter.start_date != null && filter.end_date != null) {
					filter.current_value = date.range(filter.start_date, filter.end_date);
				} else {
					filter.current_value = null;
				}
			}

			if (filter.current_value !== null && filter.current_value !== '') {
				this.filterOutput[filter.field_name] = filter.current_value;
			}
		}

		if (!utils.deepEquals(old_filter_output, this.filterOutput)) {
			this.filterEvent.next(this.filterOutput);
		}
	}

	clearFilter() {
		this.filterOutput = {};

		for (const filter of this.filterInput) {
			filter.current_value = null;

			if (filter.field_type === 'date-range') {
				filter.start_date = null;
				filter.end_date = null;
			}
		}

		this.filterEvent.next(this.filterOutput);
		this.cdr.detectChanges();
	}

	emptyTableFilter() {
		return [];
	}

	log(...args) {
		console.log(...args);
	}
}

export type FilterInput = (
	| ButtonFilterInput
	| TextFilterInput
	| SearchFilterInput
	| NumberFilterInput
	| DateFilterInput
	| DateRangeFilterInput
	| ToggleFilterInput
	| DropdownFilterInput)[];

export interface FilterOutput {
	[field_name: string]: any;
}

export type FieldType = 'text' | 'search' | 'number' | 'date' | 'date-range' | 'dropdown' | 'toggle' | 'button';

export interface BaseFilterInput {
	field_label: string;
	field_name: string;
	field_type: FieldType;
	default_value?: any;
}

export interface ButtonFilterInput extends BaseFilterInput {
	field_type: 'button',
	field_icon: string,
	filter_function: (button: HTMLButtonElement) => Promise<any>
	current_value?: any;
	default_value?: any;
}

export interface TextFilterInput extends BaseFilterInput {
	field_type: 'text';
	current_value?: string;
	default_value?: string;
}

export interface SearchFilterInput<SearchType=any> extends BaseFilterInput {
	field_type: 'search';
	search_function: (search_query: string) => Promise<SearchType[]>;
	label_function: (item: SearchType) => string;
	allow_null?: boolean;
	current_value?: SearchType;
	default_value?: SearchType;
	popover_width?: PopoverWidth;
	multi?: boolean;
	no_search?: boolean;
}

export interface NumberFilterInput extends BaseFilterInput {
	field_type: 'number';
	current_value?: number;
	default_value?: number;
}

export interface DateFilterInput extends BaseFilterInput {
	field_type: 'date';
	current_value?: Date;
	default_value?: Date;
}

export interface DateRangeFilterInput extends BaseFilterInput {
	field_type: 'date-range';
	current_value?: date.Range;
	default_value?: date.Range;
	field_end_label: string;
	start_date?: Date;
	end_date?: Date;
}

export interface ToggleFilterInput extends BaseFilterInput {
	field_type: 'toggle';
	current_value?: boolean;
	default_value?: boolean;
}

export interface DropdownFilterInput extends BaseFilterInput {
	field_type: 'dropdown';
	select_options: { label: string, value: any }[] | Promise<{ label: string, value: any }[]>;
	current_value?: any;
	default_value?: any;
}

// @TODO: Time filter

export function buttonFilterInput(field_label: string, field_icon: string, field_name: string, filter_function: () => Promise<any>): ButtonFilterInput {
	return {
		field_label: language.translate(field_label),
		field_icon: field_icon,
		field_name: field_name,
		field_type: 'button',
		filter_function: filter_function,
	};
}

export function textFilterInput(
	field_label: string,
	field_name: string,
	def_value?: string
): TextFilterInput {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		field_type: 'text',
		default_value: def_value
	};
}

export function searchFilterInput<SearchType>(
	field_label: string,
	field_name: string,
	search_function: (search_query: string) => Promise<SearchType[]>,
	label_function: (obj: SearchType) => string = String,
	allow_null: boolean = false,
	def_value?: SearchType,
	popover_width?: PopoverWidth,
	multi = false,
	no_search = false
): SearchFilterInput<SearchType> {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		field_type: 'search',
		search_function: search_function,
		label_function: label_function,
		allow_null: allow_null,
		default_value: def_value,
		popover_width: popover_width,
		multi: multi,
		no_search: no_search
	};
}

export function numberFilterInput(
	field_label: string,
	field_name: string,
	def_value?: number
): NumberFilterInput {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		field_type: 'number',
		default_value: def_value
	};
}

export function dateFilterInput(
	field_label: string,
	field_name: string,
	def_value?: Date
): DateFilterInput {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		field_type: 'date',
		default_value: def_value
	};
}

export function dateRangeFilterInput(
	field_start_label: string,
	field_end_label: string,
	field_name: string,
	def_value?: date.Range
): DateRangeFilterInput {
	return {
		field_label: language.translate(field_start_label),
		field_end_label: language.translate(field_end_label),
		field_name: field_name,
		field_type: 'date-range',
		default_value: def_value
	};
}

export function toggleFilterInput(
	field_label: string,
	field_name: string,
	def_value?: boolean
): ToggleFilterInput {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		field_type: 'toggle',
		default_value: def_value
	};
}

export function dropdownFilterInput(
	field_label: string,
	field_name: string,
	options: DropdownFilterInput["select_options"],
	def_value?: any
): DropdownFilterInput {
	return {
		field_label: language.translate(field_label),
		field_name: field_name,
		select_options: options,
		field_type: 'dropdown',
		default_value: def_value
	};
}

export function applyFilter<T>(elements: T[], filters: FilterOutput): T[] {
	return elements.filter((el) => {
		for (const filter_name in filters) {
			const filter_value = filters[filter_name];
			let object_value = el[filter_name];

			if (filter_value === null) {
				continue;
			}

			switch (typeof filter_value) {
				case 'string': {
					object_value = object_value || "";

					if (object_value.toLowerCase() != filter_value.toLowerCase()) {
						return false;
					}
				} break;

				case 'boolean':
				case 'number': {
					if (object_value !== filter_value) {
						return false;
					}
				} break;
				default: break;
			}
		}

		return true;
	});
}

export const GenericFilter = {
	button: buttonFilterInput,
	text: textFilterInput,
	number: numberFilterInput,
	date_filter: dateFilterInput,
	date_range: dateRangeFilterInput,
	toggle: toggleFilterInput,
	dropdown: dropdownFilterInput,
	search: searchFilterInput,
	apply: applyFilter
}
